const routesPath = {
  REMEMBERUSER: 'rememberUser',
  TOKEN: 'token',
  LOGIN: '/',
  LOGOUT: '/logout',
  SIGNUP: '/signup',
  PASSWORDRESET: '/password/reset',
  DASHBOARD: '/dashboard',
  TRANSACTIONINFORMATION: '/transactioninformation',
  KYC: '/kyc',
  SUPPORT: '/support',
  SETTLEMENTS: '/settlements',
  RECONCILIATION: '/reconciliation',
  RECONCILIATIONUSERDETAILS: '/reconciliation/',
  USERS: '/users',
  ACCOUNTMANAGEMENT: '/account-management',
  SETTINGS: '/settings',
  KYCDOC: '/kyc/',
  // I think I have to delete this UserDetails Route permanently, but I am leaving it here for now.
  //  Route has been changed to CUSTOMERDETAILS
  // USERDETAILS: '/user/profile/',
  TRANSACTIONS: '/transactions',
  PAYMENTS: '/payments',
  NEWAPPNOTIFICATION: '/settings/newappnotification',
  APPNOTIFICATIONUPDATE: '/settings/appnotificationupdate/',
  EMAILNOTIFICATION: '/settings/emailnotification',
  EMAILNOTIFICATIONUPDATE: '/settings/emailnotificationupdate/',
  NEWARTICLE: '/settings/newarticle',
  ARTICLEUPDATE: '/settings/articleupdate/',
  NEWFAQ: '/settings/newfaq',
  FAQUPDATE: '/settings/faq/',
  USERROLES: '/users/roles/',
  CREATEUSERROLES: '/users/roles/new',
  CASHREQUESTINFORMATION: '/cash-request',
  EVENTCASHREQUESTINFORMATION: '/event-cash-request',
  EVENTCASHREQUESTSETTINGS: '/event-cash-request-settings',
  KYCCUSTOMERINFORMATION: '/kyc-information/',
  REPORT: '/report',
  GENERATEDREPORT: '/generatedreport',
  VOLUMEREPORT: '/volumereport',
  USERREPORT: '/userreport',
  INCOMEREPORT: '/incomereport',
  TRANSACTIONREPORT: '/transactionreport',
  DAILYREPORT: '/generalreport/dailyreport',
  WEEKLYREPORT: '/generalreport/weeklyreport',
  MONTHLYREPORT: '/generalreport/monthlyreport',
  RECONCILIATIONREPORT: '/reconciliationreport',
  SETTLEMENTREPORT: '/generalreport/settlementreport',
  USERREGREPORT: '/userregistrationreport',
  USERACTTIVITYREPORT: '/useractivitesreport',
  FINANCEREPORT: '/financereport',
  AGENTPROFILE: '/agent-profile/',
  CUSTOMERS: '/customers',
  CUSTOMERDETAILS: '/customers/profile/',
};

export default routesPath;
