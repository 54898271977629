import { useState, useEffect, useRef, SetStateAction } from 'react';
import { ReactComponent as DownloadIcon } from '../../assets/svg/downloadBtn.svg';
import {
  Pagination,
  TransactionTable,
  FilterCard,
  ExportLabel,
  ZojaCheckbox,
  FilterLabel,
  FilterColapsible,
  CustomDatePicker,
  DownloadSuccessModal,
} from '../../components';
import { AppContainer, TransactionDetailsModal } from '../../atoms';
import {
  dateFormat,
  spacing,
  capitalizeFirstLetter,
  timeFormat,
  replaceStringWithBackslach,
  yearDateFormat,
  icons,
  handleSelectedOption,
  getSelectedOptionValue,
  formatStartAndEndDateWithDelimeter,
  useSortTable,
  useWindowResize,
} from '../../utils';

import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';

import { H2 } from '../../styles';

import { Dictionary } from '../../types';

import { HeaderContainer, HeaderContent } from './style';
import {
  getAllTransactionsRequest,
  getTransactionByIdRequest,
  downloadTransactionByIdRequest,
  downloadTransactionsRequest,
  downloadTransactionRequest,
} from '../../redux/slice';
import { ZojaInput, ZojaSelect } from '../../components/tailwind';
import { Oval } from 'react-loader-spinner';

const transactionDateOptions = [
  {
    id: 1,
    value: 'today',
    title: 'Today',
    isChecked: false,
  },
  {
    id: 2,
    value: 'yesterday',
    title: 'Yesterday',
    isChecked: false,
  },
  {
    id: 3,
    value: 'one_week',
    title: 'One week',
    isChecked: false,
  },
  {
    id: 4,
    value: 'thirty_days',
    title: '30 Days',
    isChecked: false,
  },
  {
    id: 5,
    value: 'six_months',
    title: '6 Months',
    isChecked: false,
  },
  {
    id: 6,
    value: 'custom',
    title: 'Custom',
    isChecked: false,
  },
];

// filter options constants
const sortByOtions = {
  aphabetically: [
    {
      id: 1,
      value: 'az',
      title: 'A-Z',
      isChecked: false,
    },
    {
      id: 2,
      value: 'za',
      title: 'Z-A',
      isChecked: false,
    },
    {
      id: 3,
      value: 'transaction_type',
      title: 'Transaction Type',
      isChecked: false,
    },
  ],
  orderBy: [
    {
      id: 1,
      value: 'newest',
      title: 'Newest',
      isChecked: false,
    },
    {
      id: 2,
      value: 'oldest',
      title: 'Oldest',
      isChecked: false,
    },
    {
      id: 3,
      value: 'largest',
      title: 'Largest',
      isChecked: false,
    },
    {
      id: 4,
      value: 'smallest',
      title: 'Smallest',
      isChecked: false,
    },
  ],
};

function Transactions() {
  const dispatch = useAppDispatch();
  const [, setSelectedTransaction] = useState({});

  //   redux state
  const allTransactionsState = useAppSelector(state => state.getAllTransactions);
  const { status: allTransactionsStatus } = allTransactionsState;

  const transactionByIdState = useAppSelector(state => state.getTransactionById);
  const { status: transactionByIdStatus } = transactionByIdState;

  const downloadTransactionByIdState = useAppSelector(state => state.downloadTransactionById);
  const { status: downloadTransactionByIdStatus } = downloadTransactionByIdState;

  const downloadTransactionsState = useAppSelector(state => state.downloadTransactions);
  const { status: downloadTransactionsStatus } = downloadTransactionsState;

  const { status: downloadTransactionStatus } = useAppSelector(state => state.downloadTransaction);

  //   downloadTransactionByIdSliceReducer
  const initialDate = '2022-01-01';
  const currentDate = new Date().toDateString();
  // const tTypesOption: CustomSelectOptionsIProps[] = [
  //   { key: `App,Models,CashTransfer`, value: 'CashTransfer' },
  //   { key: `App,Models,CashRequest`, value: 'CashRequest' },
  //   { key: `App,Models,WalletCredit`, value: 'WalletCredit' },
  //   { key: `App,Models,WalletDebit`, value: 'WalletDebit' },
  //   { key: `App,Models,Bill`, value: 'Bill' },
  // ];

  // const tStatusOption: CustomSelectOptionsIProps[] = [
  //   { key: 'success', value: 'Successful' },
  //   { key: 'failed', value: 'Failed' },
  //   { key: 'pending', value: 'Pending' },
  // ];

  const [tTypes] = useState(['Transaction Type', 'Transaction Type']);
  const [tStatus] = useState(['Transaction Status', 'Transaction Status']);
  const [startDisplayRecordDate] = useState(initialDate);
  const [endDisplayRecordDate] = useState(currentDate);
  const [transactionDetailsModalVisible, setTransactionDetailsModalVisible] = useState(false);
  const [transactionData, setTransactionData] = useState<any[]>([]);
  const [transactionByIdData, setTransactionByIdData] = useState<Dictionary>({});
  const [singleTransId, setSingleTransId] = useState('');
  const [showTransactionDate, setShowTransactionDate] = useState(false);
  const [transactionDate, setTransactionDate] = useState(transactionDateOptions);
  const [transactionDateRange, setTransactionDateRange] = useState({ start_date: '', end_date: '' });
  const [showSortBy, setShowSortBy] = useState(false);
  const [sortBy, setSortBy] = useState(sortByOtions.aphabetically);
  const [orderBy, setOrderBy] = useState(sortByOtions.orderBy);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const pageSize = 10;
  const [totalPages, setTotalPages] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [showAmountRange, setShowAmountRange] = useState(true);
  const [showExportOptions, setShowExportOptions] = useState(false);
  const [showSuccessDownloadModal, setShowSuccessDownloadModal] = useState(false);

  const [order, setOrder] = useState('ASC');

  const initialValues: Dictionary = {
    model_type: tTypes[0] !== 'Transaction Type' ? replaceStringWithBackslach(tTypes[0]) : '',
    status: tStatus[0] !== 'Transaction Status' ? tStatus[0] : '',
    per_page: pageSize,
    page: currentPage,
    transaction_date: '',
    reference: '',
    type: '',
    min_amount: '',
    max_amount: '',
    sort_by: '',
    order_by: '',
  };

  const [filterOptions, setFilterOptions] = useState(initialValues);
  const [perPage, setPerPage] = useState<any>('10');

  const successModalRef = useRef<any>(null);

  useEffect(() => {
    dispatch(getAllTransactionsRequest(filterOptions));
  }, []);

  useEffect(() => {
    if (allTransactionsStatus === 'succeeded' && allTransactionsState?.data?.transactions?.data?.length > 0) {
      const updatedList: any[] = [];

      allTransactionsState?.data?.transactions?.data.forEach((item: Dictionary, index: number) => {
        updatedList.push({
          id: index + 1,
          name: item?.user.name,
          tid: item?.transfer_purpose,
          amount: parseFloat(item?.amount),
          type: item?.transaction_reference,
          status: item.status,
          icon: false,
          time: item?.created_at,
          currency: item?.currency,
          phoneNumber: item?.user?.telephone,
          transId: item?.id,
          email: item?.user?.email,
        });
      });

      const {
        meta: { last_page },
      } = allTransactionsState?.data?.transactions;

      setTotalPages(last_page);
      setTransactionData(updatedList);
    }
  }, [allTransactionsState]);

  // console.log("transa÷\ctionData",transactionData)
  useEffect(() => {
    if (transactionByIdStatus === 'succeeded') {
      const {
        amount,
        status,
        currency,
        transfer_purpose,
        charge,
        channel,
        created_at,
        external_account_name,
        source,
        user: { name, email, telephone },
      } = transactionByIdState.data.transaction;
      const result = {
        amount,
        status,
        currency,
        data: [
          {
            id: 1,
            text: capitalizeFirstLetter(transfer_purpose),
            helper: 'Transaction Type',
          },
          {
            id: 2,
            text: name,
            helper: 'Wallet Name',
          },
          {
            id: 3,
            text: email,
            helper: 'Email',
          },
          {
            id: 4,
            text: telephone,
            helper: 'Phone Number',
          },
          {
            id: 5,
            text: `N${charge}`,
            helper: 'Charges',
          },
          {
            id: 6,
            text: source !== null ? source?.name : external_account_name,
            helper: 'Sender Name',
          },
          {
            id: 7,
            text: channel,
            helper: 'Channel',
          },
          {
            id: 8,
            text: timeFormat(created_at, true),
            helper: 'Time',
          },
          {
            id: 9,
            text: dateFormat(created_at),
            helper: 'Date',
          },
        ],
      };
      setTransactionByIdData(result);
    }
  }, [transactionByIdState]);

  const transactionDataHeader = [
    {
      id: 1,
      title: '',
      hasSortIcon: false,
    },
    {
      id: 2,
      title: 'Customer Name',
      value: 'name',
      hasSortIcon: true,
    },
    {
      id: 3,
      title: 'Transaction ID',
      value: 'tid',
      hasSortIcon: true,
    },
    {
      id: 4,
      title: 'Transaction Type',
      value: 'type',
      hasSortIcon: true,
    },
    {
      id: 5,
      title: 'Amount',
      value: 'amount',
      hasSortIcon: true,
    },

    {
      id: 6,
      title: 'Status',
      value: 'status',
      hasSortIcon: true,
    },
    {
      id: 7,
      title: 'Date-Time',
      value: 'time',
      hasSortIcon: true,
    },
    {
      id: 8,
      title: '',
      value: '',
      hasSortIcon: false,
    },
  ];

  // Input changer handler
  const handleInputChange = ({ target: { name, value } }: Dictionary) => {
    setFilterOptions({ ...filterOptions, [name]: value });
  };

  // Fetch transactions when the button is clicked
  const handleFetchTransactions = () => {
    dispatch(getAllTransactionsRequest(filterOptions));
  };

  // close filter on state change
  useEffect(() => {
    setShowFilterOptions(false);
    setShowExportOptions(false);
    setShowSortBy(false);
  }, [allTransactionsStatus]);

  useEffect(() => {
    if (downloadTransactionByIdStatus === 'succeeded') {
      //  do something
    }
  }, [downloadTransactionByIdState]);

  useEffect(() => {
    if (downloadTransactionsStatus === 'succeeded') {
      //  do something
    }
  }, [downloadTransactionsState]);

  // Update filter options when checkboxes or dropdowns change
  useEffect(() => {
    const getTransactionDateValue = getSelectedOptionValue(transactionDate);
    const getSelectedSortBy = getSelectedOptionValue(sortBy);
    const getSelectedOrderBy = getSelectedOptionValue(orderBy);

    setFilterOptions({
      ...filterOptions,
      transaction_date: getTransactionDateValue,
      sort_by: getSelectedSortBy,
      order_by: getSelectedOrderBy,
    });
  }, [orderBy, sortBy, transactionDate]);

  // Handle click on a transaction item
  const handleOnClick = (item: Dictionary) => {
    setTransactionDetailsModalVisible(true);
    setSingleTransId(item?.transId);
    dispatch(getTransactionByIdRequest({ transId: item?.transId }));
  };

  const handleOnClickDownloadSingleTransaction = () => {
    dispatch(downloadTransactionByIdRequest({ transId: singleTransId }));
  };

  const handleOnClickDownloadIcon = () => {
    dispatch(
      downloadTransactionsRequest({
        model_type: tTypes[0] !== 'Transaction Type' ? replaceStringWithBackslach(tTypes[0]) : '',
        status: tStatus[0] !== 'Transaction Status' ? tStatus[0] : '',
        start_date: yearDateFormat(startDisplayRecordDate),
        end_date: yearDateFormat(endDisplayRecordDate),
      }),
    );
  };

  useEffect(() => {
    if (downloadTransactionStatus === 'succeeded') {
      setShowSuccessDownloadModal(true);
    }
  }, [downloadTransactionStatus]);

  // filter and export methods
  const handleExportFile = (type: string) => {
    const { transaction_date: transactionDateValue, ...restPayload } = filterOptions;
    const transaction_date_range = formatStartAndEndDateWithDelimeter(
      transactionDateRange.start_date,
      transactionDateRange.end_date,
    );

    const payload =
      transactionDateValue === 'custom'
        ? { ...restPayload, transaction_date: transaction_date_range }
        : { ...restPayload, transaction_date: transactionDateValue };

    dispatch(downloadTransactionRequest({ ...payload, exp: type }));
    setShowExportOptions(false);
  };

  // filter table handler
  const handleSubmitFilter = () => {
    const { transaction_date: transactionDateValue, ...restPayload } = filterOptions;
    const transaction_date_range = formatStartAndEndDateWithDelimeter(
      transactionDateRange.start_date,
      transactionDateRange.end_date,
    );

    const payload =
      transactionDateValue === 'custom'
        ? { ...restPayload, transaction_date: transaction_date_range }
        : { ...restPayload, transaction_date: transactionDateValue };

    dispatch(getAllTransactionsRequest(payload));
  };

  // FUNCTION TO CHANGE PAGE
  const onChangePagination = (selectedPage: SetStateAction<number>) => {
    // FIRST SET THE DATE IS THE CHOSEN ONE IS CUSTOM BECAUSE IT GETS IT VALUE FROM transaction_date.
    const { transaction_date: transactionDateValue, ...restPayload } = filterOptions;
    const transaction_date_range = formatStartAndEndDateWithDelimeter(
      transactionDateRange.start_date,
      transactionDateRange.end_date,
    );

    // SET CURRENT PAGE TO STATE
    setCurrentPage(selectedPage);
    let currentPage = selectedPage;

    // ADD TO PAYLOAD
    const payload =
      transactionDateValue === 'custom'
        ? { ...restPayload, page: currentPage, transaction_date: transaction_date_range }
        : { ...restPayload, page: currentPage, transaction_date: transactionDateValue };

    // DISPATCH FUNCTION
    dispatch(getAllTransactionsRequest(payload));
  };

  const { handleSortItems } = useSortTable({
    order: order,
    setOrder,
    data: transactionData,
    setData: setTransactionData,
  });

  const { width } = useWindowResize();

  // handle page number change
  const handlePageNumberChange = (pageNumber: any) => {
    setFilterOptions({ ...filterOptions, per_page: pageNumber });
    setPerPage(pageNumber);

    dispatch(
      getAllTransactionsRequest({
        ...filterOptions,
        per_page: pageNumber,
      }),
    );
  };

  return (
    <AppContainer navTitle="TRANSACTIONS">
      <div style={{ marginTop: spacing.small, paddingBottom: spacing.medium }}>
        {(showFilterOptions || showSortBy || showExportOptions) && (
          <div
            className="tw-absolute tw-bg-black/0 tw-cursor-pointer tw-z-30 tw-w-full tw-h-screen
           tw-top-0 tw-left-0"
            onClick={() => {
              setShowFilterOptions(false);
              setShowSortBy(false);
              setShowExportOptions(false);
            }}></div>
        )}

        <DownloadSuccessModal
          show={showSuccessDownloadModal}
          handleClose={() => setShowSuccessDownloadModal(false)}
          closeOnClickOutside={false}
          contentRef={successModalRef}
          title="Item Downloaded"
          content="Your document has been downloaded to your device"
        />
        <HeaderContainer>
          <H2 semiBold left>
            All Transactions
          </H2>
          <HeaderContent>
            <div className="tw-flex tw-flex-col tw-gap-2 tw-items-end lg:tw-flex-row lg:tw-gap-x-3 md:tw-items-center">
              <div className="tw-relative tw-w-full md:tw-mr-4">
                <input
                  type="text"
                  name="reference"
                  id="reference"
                  value={filterOptions.reference}
                  onChange={handleInputChange}
                  className="tw-w-full tw-pl-1 tw-bg-none tw-border-b tw-text-sm tw-text-isPrimary placeholder:tw-text-xs placeholder:tw-text-[#162082] placeholder:tw-font-thin focus:tw-outline-none md:tw-w-72"
                  placeholder="Search by reference"
                  style={{ backgroundColor: 'transparent' }}
                />
                <span
                  className="tw-text-xs tw-absolute tw-right-0 tw-top-1 tw-text-isPrimary tw-cursor-pointer"
                  onClick={handleFetchTransactions}>
                  <icons.FaSearch />
                </span>
              </div>
              <div className="tw-flex tw-items-center tw-gap-x-2 md:tw-gap-x-4">
                <FilterLabel
                  title="Filter Options"
                  icon={<icons.BsFilter />}
                  onClick={() => setShowFilterOptions(true)}>
                  <FilterCard
                    showFilter={showFilterOptions}
                    title="Filter Options"
                    onClick={() => handleSubmitFilter()}
                    extraClass="tw-w-[13rem]">
                    <div className="tw-mt-4 tw-h-[14rem] tw-overflow-y-scroll tw-overflow-hidden tw-z-[1]">
                      <ZojaSelect
                        options={[
                          { value: '', label: 'Transaction Type' },
                          { value: 'credit', label: 'Credit' },
                          { value: 'debit', label: 'Debit' },
                        ]}
                        name="transactionType"
                        showArrown={true}
                        setSelectedOption={selected => setFilterOptions({ ...filterOptions, type: selected })}
                        extraClass="tw-text-xs tw-p-3.5 tw-bg-white tw-border-0 tw-shadow-zojaShadowFive tw-rounded-md tw-font-normal tw-text-[#222B88] tw-mb-2"
                        arrowDownClass="tw-right-lg tw-top-3.5"
                      />
                      <ZojaSelect
                        options={[
                          { value: '', label: 'Transaction Status' },
                          { value: 'success', label: 'Success' },
                          { value: 'pending', label: 'Pending' },
                          { value: 'failed', label: 'Failed' },
                        ]}
                        name="transactionStatus"
                        showArrown={true}
                        setSelectedOption={selected => setFilterOptions({ ...filterOptions, status: selected })}
                        extraClass="tw-text-xs tw-p-3.5 tw-bg-white tw-border-0 tw-shadow-zojaShadowFive tw-rounded-md tw-font-normal tw-text-[#222B88]"
                        arrowDownClass="tw-right-lg tw-top-3.5"
                      />
                      <FilterColapsible
                        title="Amount"
                        show={showAmountRange}
                        onClick={() => setShowAmountRange(!showAmountRange)}>
                        <>
                          <ZojaInput
                            label="Min Amount"
                            id="min_amount"
                            type="number"
                            extraClass="tw-text-xs tw-p-2 tw-mb-2"
                            name="min_amount"
                            value={filterOptions.min_amount}
                            onChange={handleInputChange}
                          />
                          <ZojaInput
                            label="Max Amount"
                            id="max_amount"
                            name="max_amount"
                            type="number"
                            extraClass="tw-text-xs tw-p-2 tw-mb-2"
                            value={filterOptions.max_amount}
                            onChange={handleInputChange}
                          />
                        </>
                      </FilterColapsible>
                      <FilterColapsible
                        title="Transaction Date"
                        show={showTransactionDate}
                        onClick={() => setShowTransactionDate(!showTransactionDate)}
                        extraClass="tw-shadow-none">
                        <>
                          <div className="">
                            {transactionDate.map((item: any) => (
                              <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                                <span className="tw-text-[13px] tw-mt-4 tw-text-[#84919A]">{item.title}</span>
                                <ZojaCheckbox
                                  isChecked={item.isChecked}
                                  onClick={() => handleSelectedOption(item, transactionDate, setTransactionDate)}
                                />
                              </div>
                            ))}
                          </div>

                          <div
                            className={`tw-mt-2 tw-overflow-hidden tw-max-h-0 ${
                              filterOptions.transaction_date === 'custom' && 'tw-max-h-fit'
                            }`}>
                            <div className="tw-mb-2">
                              <CustomDatePicker
                                label="State Date"
                                value={new Date()}
                                setValue={value =>
                                  setTransactionDateRange({ ...transactionDateRange, start_date: value })
                                }
                              />
                            </div>
                            <div className="tw-mb-2">
                              <CustomDatePicker
                                label="End Date"
                                value={new Date()}
                                setValue={value =>
                                  setTransactionDateRange({ ...transactionDateRange, end_date: value })
                                }
                              />
                            </div>
                          </div>
                        </>
                      </FilterColapsible>
                    </div>
                  </FilterCard>
                </FilterLabel>

                <FilterLabel
                  title="Sort By"
                  icon={<icons.TbArrowsSort color="#5E6366" />}
                  onClick={() => setShowSortBy(true)}>
                  <FilterCard
                    showFilter={showSortBy}
                    title="Sort by"
                    onClick={() => handleSubmitFilter()}
                    submitButtonText="Apply"
                    extraClass="tw-w-[10rem] -tw-left-28">
                    <>
                      <div className="tw-pt-2">
                        {sortBy.map((item: any) => (
                          <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                            <span className="tw-mt-4 tw-text-[13px] tw-text-[#84919A]">{item.title}</span>
                            <ZojaCheckbox
                              isChecked={item.isChecked}
                              onClick={() => handleSelectedOption(item, sortBy, setSortBy)}
                            />
                          </div>
                        ))}
                      </div>
                      <div className="tw-pt-2 tw-border-">
                        {orderBy.map((item: any) => (
                          <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                            <span className="tw-mt-4 tw-text-[13px] tw-text-[#84919A]">{item.title}</span>
                            <ZojaCheckbox
                              isChecked={item.isChecked}
                              onClick={() => handleSelectedOption(item, orderBy, setOrderBy)}
                            />
                          </div>
                        ))}
                      </div>
                    </>
                  </FilterCard>
                </FilterLabel>

                <ExportLabel
                  title="Export"
                  onClick={() => setShowExportOptions(true)}
                  loading={downloadTransactionStatus === 'loading' ? true : false}>
                  <FilterCard
                    showFilter={showExportOptions}
                    title="Export as"
                    onClick={() => null}
                    showSubmitButton={false}
                    extraClass="tw-w-[10rem] -tw-left-28">
                    <div className="tw-flex tw-flex-col tw-mt-4">
                      <span
                        className="tw-text-xs tw-cursor-pointer tw-text-isGrey"
                        onClick={() => handleExportFile('csv')}>
                        CSV
                      </span>
                    </div>
                  </FilterCard>
                </ExportLabel>
              </div>
            </div>
          </HeaderContent>
        </HeaderContainer>
        {allTransactionsStatus === 'loading' ? (
          <div className="tw-h-[50vh] tw-mx-auto tw-flex tw-justify-center tw-items-center">
            <Oval
              height="80"
              width="80"
              color="#222b88cf"
              ariaLabel="tail-spin-loading"
              secondaryColor="#222b882b"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <TransactionTable
            type="mainTransactions"
            header={true}
            data={transactionData}
            setSelectedItem={setSelectedTransaction}
            onClick={(item: any) => handleOnClick(item)}
            tableColumns={transactionDataHeader}
            sorting={handleSortItems}
            order={order}
          />
        )}

        {startDisplayRecordDate !== initialDate && (
          <DownloadIcon
            style={{ position: 'fixed', right: 30, top: '60vh', cursor: 'pointer' }}
            onClick={handleOnClickDownloadIcon}
          />
        )}

        <div className="tw-pb-2 tw-flex tw-flex-col tw-items-center tw-gap-3 md:tw-pb-8 md:tw-flex-row">
          <Pagination
            isLoading={allTransactionsStatus === 'loading' ? true : false}
            currentPage={currentPage}
            totalPages={totalPages}
            maxWidth={width < 500 ? 350 : 500}
            onPageChange={selectedPage => {
              onChangePagination(selectedPage);
            }}
          />
          {allTransactionsStatus !== 'loading' ? (
            <div className="-tw-mt-12 tw-flex tw-items-center tw-h-fit">
              <span className="tw-text-xs tw-w-16">Per page</span>
              <ZojaSelect
                options={[
                  { label: '10', value: '10' },
                  { label: '20', value: '20' },
                  { label: '30', value: '30' },
                ]}
                value={perPage}
                // value={customerAccountStatement?.data?.transactions?.per_page}
                setSelectedOption={selected => handlePageNumberChange(selected)}
                extraClass="tw-rounded-none tw-text-xs tw-py-2 tw-w-10"
              />
            </div>
          ) : (
            <></>
          )}
        </div>

        <TransactionDetailsModal
          status={transactionByIdData?.status}
          amount={transactionByIdData?.amount}
          currency={transactionByIdData?.currency}
          isModalVisible={transactionDetailsModalVisible}
          closeModal={() => setTransactionDetailsModalVisible(false)}
          onClickExportBtn={handleOnClickDownloadSingleTransaction}
          exportBtnDisabled={downloadTransactionByIdStatus === 'loading'}
          data={transactionByIdData?.data}
          isLoading={transactionByIdStatus === 'loading' || transactionDetailsModalVisible === false}
          actionBtnText="Download"
        />
        {/* <LoaderModal
          isModalVisible={allTransactionsStatus === 'loading' || downloadTransactionsStatus === 'loading'}
          text="Loading please wait..."
          closeModal={() => {}}
        /> */}
      </div>
    </AppContainer>
  );
}

export default Transactions;
