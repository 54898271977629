import { Key, useState } from 'react';
import {
  BigFilterCard,
  ExportLabel,
  FilterCard,
  FilterColapsibleSingle,
  FilterLabel,
  FilterLabelVariant2,
  ManageColumn,
  ReusableFilter,
  SimplePagination,
  ZojaCheckbox,
  ZojaReportTable,
} from '../../components';
import { filterType } from '../../pages/report/filterOptions';
import {
  handleCheckboxChange,
  icons,
  setOneOptionTrue,
  toggleColumns,
  toggleItemChecked,
  toggleItemCheckedOneItem,
} from '../../utils';
import { ZojaSelect } from '../../components/tailwind';
import { Dictionary } from '../../types';
import { TR, TD } from '../../components/tailwind/reportTable/tableElements';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { BiColumns } from 'react-icons/bi';
import { Oval } from 'react-loader-spinner';
import { useAppSelector } from '../../redux/redux-hooks';
import { userReportColumn } from '../../pages/report/dummyData';

interface ReportFilterOptions {
  id: number;
  title: string;
  value: string;
}

const UserGeneratedReport = ({
  onSubmitFilter,
  filterOptions,
  column,
  tableData,
  onExport,
  onApplyFilter,
  manageColumnOptions,
  filterOption,
  setFilterOption,
  showOptions,
  setShowOptions,
  setSearch,
  searchedUsers,
  handleInputChange,
  setSelectedPageOption,
  itemsPerPage,
  totalItems,
  currentPage,
  locations,
  setCurrentPage,
  userState,
  visibleColumns,
  setVisibleColumns,
  downloadStatus,
  handleClientSideSearch,
  userFilterOptions,
}: any) => {
  const navigate = useNavigate();

  const searchedUserAccountState = useAppSelector(state => state.searchUserAccount);

  const [manageColumn, setManageColumn] = useState(manageColumnOptions.user);
  const [pendingChanges, setPendingChanges] = useState<number[]>(userReportColumn.map(column => column.id));

  const midScreen = window.innerWidth < 768;

  const handleManageClick = (e: { stopPropagation: () => void } | undefined) => {
    e?.stopPropagation();
    setShowOptions({ ...showOptions, manageColumn: false });
    setVisibleColumns(pendingChanges);
  };

  const handleManageCancel = (e: { stopPropagation: () => void } | undefined) => {
    e?.stopPropagation();
    setPendingChanges(userReportColumn.map(column => column.id));
    setVisibleColumns(userReportColumn.map(column => column.id));
    setShowOptions({ ...showOptions, manageColumn: false });
  };

  return (
    <div className="flex tw-flex-col md:tw-flex md:tw-flex-row tw-gap-x-4">
      {(showOptions.exportOptions || showOptions.sortBy || showOptions.manageColumn) && (
        <div
          className="tw-absolute tw-bg-black/0 tw-cursor-pointer tw-z-30 tw-w-full tw-h-[1000vh] md:tw-h-sreen tw-top-0 tw-left-0 "
          onClick={() => {
            setShowOptions({
              ...showOptions,
              exportOptions: false,
              sortBy: false,
              manageColumn: false,
            });
          }}></div>
      )}
      {showOptions.bigFilter ? (
        <div className="tw-bg-white tw-shadow-zojaShadowFour tw-top-0 -tw-left-32 tw-rounded-md tw-z-40 tw-p-4">
          <div className=" tw-mb-4">
            <FilterColapsibleSingle
              title={filterOption.reportFilterType.value === '' ? 'Filter Type' : filterOption.reportFilterType.title}
              show={showOptions.reportFilter}
              onClick={() => {
                setShowOptions({
                  ...showOptions,
                  reportFilter: !showOptions.reportFilter,
                });
              }}
              extraClass="tw-shadow-none tw-w-full"
              childClass=" tw-w-full tw-relative">
              <div className="">
                {filterType.map((item: any) => (
                  <div
                    key={item.id}
                    onClick={() => {
                      setFilterOption({
                        ...filterOptions,
                        reportFilterType: item,
                      });
                      setShowOptions({
                        ...showOptions,
                        reportFilter: false,
                      });
                      navigate(item.path);
                    }}
                    className="tw-flex tw-justify-between tw-items-center tw-gap-3 tw-cursor-pointer">
                    <p className="tw-text-[13px] tw-mt-4 tw-text-[#84919A]">{item.title}</p>
                  </div>
                ))}
              </div>
            </FilterColapsibleSingle>
          </div>
          <BigFilterCard title="Applicable Filters" onClick={onApplyFilter} extraClass="w-full md:tw-w-[13rem]">
            <div className="tw-mt-4 tw-z-[1]">
              <ReusableFilter
                setChecked={toggleItemChecked}
                setItem={setFilterOption}
                filterOptions={filterOption}
                title="Age Range"
                show={showOptions.ageRange}
                extraClass="tw-shadow-none"
                type="text"
                label="Age"
                id="age"
                inputType="number"
                name="age"
                onHandleInputChange={handleInputChange}
                setShow={
                  midScreen
                    ? () => setOneOptionTrue('ageRange', setShowOptions)
                    : () => setShowOptions({ ...showOptions, ageRange: !showOptions.ageRange })
                }
                filterParams={userFilterOptions}
              />
              <ReusableFilter
                title="Account info"
                items={filterOption.accountInfo}
                itemName={'accountInfo'}
                show={showOptions.accountInfo}
                // setShow={() => setShowOptions({ ...showOptions, accountInfo: !showOptions.accountInfo })}
                setChecked={toggleItemChecked}
                setItem={setFilterOption}
                filterOptions={filterOption}
                extraClass="tw-shadow-none"
                setSearch={setSearch}
                searchedUsers={searchedUsers}
                type={'inputCheck'}
                searchedUserAccountState={searchedUserAccountState}
                setShow={
                  midScreen
                    ? () => setOneOptionTrue('accountInfo', setShowOptions)
                    : () => setShowOptions({ ...showOptions, accountInfo: !showOptions.accountInfo })
                }
              />
              <ReusableFilter
                setChecked={toggleItemCheckedOneItem}
                setItem={setFilterOption}
                itemName={'lastLogin'}
                filterOptions={filterOption}
                title="Last Login Time"
                show={showOptions.loginTime}
                // setShow={() => setShowOptions({ ...showOptions, loginTime: !showOptions.loginTime })}
                extraClass="tw-shadow-none"
                items={filterOption.lastLogin}
                type={'checkDate'}
                setShow={
                  midScreen
                    ? () => setOneOptionTrue('loginTime', setShowOptions)
                    : () => setShowOptions({ ...showOptions, loginTime: !showOptions.loginTime })
                }
              />
              <ReusableFilter
                setChecked={toggleItemCheckedOneItem}
                setItem={setFilterOption}
                itemName={'dateOnboarded'}
                filterOptions={filterOption}
                title="Date Onboarded"
                show={showOptions.onboarded}
                // setShow={() => setShowOptions({ ...showOptions, onboarded: !showOptions.onboarded })}
                extraClass="tw-shadow-none"
                items={filterOption.dateOnboarded}
                type={'checkDate'}
                setShow={
                  midScreen
                    ? () => setOneOptionTrue('onboarded', setShowOptions)
                    : () => setShowOptions({ ...showOptions, onboarded: !showOptions.onboarded })
                }
              />
              <ReusableFilter
                setChecked={toggleItemChecked}
                setItem={setFilterOption}
                itemName={'kycLevel'}
                filterOptions={filterOption}
                title="KYC Level"
                show={showOptions.kycLevel}
                // setShow={() => setShowOptions({ ...showOptions, kycLevel: !showOptions.kycLevel })}
                extraClass="tw-shadow-none"
                items={filterOption.kycLevel}
                setShow={
                  midScreen
                    ? () => setOneOptionTrue('kycLevel', setShowOptions)
                    : () => setShowOptions({ ...showOptions, kycLevel: !showOptions.kycLevel })
                }
              />
              <ReusableFilter
                setItem={setFilterOption}
                itemName={'Account Balance'}
                filterOptions={filterOption}
                title="Account Balance"
                show={showOptions.accountBalance}
                // setShow={() => setShowOptions({ ...showOptions, accountBalance: !showOptions.accountBalance })}
                extraClass="tw-shadow-none"
                type="text"
                label="Amount"
                id="account_balance"
                inputType="number"
                name="account_balance"
                onHandleInputChange={handleInputChange}
                setShow={
                  midScreen
                    ? () => setOneOptionTrue('accountBalance', setShowOptions)
                    : () => setShowOptions({ ...showOptions, accountBalance: !showOptions.accountBalance })
                }
                filterParams={userFilterOptions}
              />
              <ReusableFilter
                setItem={setFilterOption}
                itemName={'totalTransaction'}
                filterOptions={filterOption}
                title="Total Transaction"
                show={showOptions.totalTransaction}
                // setShow={() => setShowOptions({ ...showOptions, totalTransaction: !showOptions.totalTransaction })}
                extraClass="tw-shadow-none"
                type="text"
                label="Count"
                id="total_transaction"
                inputType="number"
                name="total_transaction"
                onHandleInputChange={handleInputChange}
                setShow={
                  midScreen
                    ? () => setOneOptionTrue('totalTransaction', setShowOptions)
                    : () => setShowOptions({ ...showOptions, totalTransaction: !showOptions.totalTransaction })
                }
                filterParams={userFilterOptions}
              />
              <ReusableFilter
                setChecked={toggleItemChecked}
                setItem={setFilterOption}
                itemName={'location'}
                filterOptions={filterOption}
                title="Location"
                show={showOptions.location}
                // setShow={() => setShowOptions({ ...showOptions, location: !showOptions.location })}
                extraClass="tw-shadow-none"
                items={locations}
                handleClientSideSearch={handleClientSideSearch}
                type={'inputCheck'}
                setShow={
                  midScreen
                    ? () => setOneOptionTrue('location', setShowOptions)
                    : () => setShowOptions({ ...showOptions, location: !showOptions.location })
                }
              />
              <ReusableFilter
                setChecked={toggleItemChecked}
                setItem={setFilterOption}
                itemName={'gender'}
                filterOptions={filterOption}
                title="Gender"
                show={showOptions.gender}
                // setShow={() => setShowOptions({ ...showOptions, gender: !showOptions.gender })}
                extraClass="tw-shadow-none"
                items={filterOption.gender}
                setShow={
                  midScreen
                    ? () => setOneOptionTrue('gender', setShowOptions)
                    : () => setShowOptions({ ...showOptions, gender: !showOptions.gender })
                }
              />
            </div>
          </BigFilterCard>
        </div>
      ) : null}

      <div className="tw-overflow-x-auto tw-w-full">
        {/* TOP TOOLBAR */}
        <div className=" tw-mb-6 tw-flex tw-items-center tw-justify-between tw-mt-5 lg:tw-flex-row tw-flex-col tw-gap-y-3">
          <div className="tw-items-center tw-gap-4 tw-flex tw-justify-between tw-w-full lg:tw-w-max">
            <div
              onClick={() => setShowOptions({ ...showOptions, bigFilter: !showOptions.bigFilter })}
              className="tw-items-center gap-2 tw-flex tw-cursor-pointer">
              <MdOutlineRemoveRedEye size={20} />
              <h3 className="tw-text-blue-900 tw-text-sm tw-font-normal tw-m-0">
                {showOptions.bigFilter ? 'Full View' : 'Exit Full View'}
              </h3>
            </div>
            <div className="tw-items-center tw-gap-4 tw-flex">
              <div className="tw-text-gray-700 tw-text-sm tw-font-medium">Table View</div>

              <ZojaSelect
                options={[
                  {
                    label: '10',
                    value: 10,
                  },
                  {
                    label: '20',
                    value: 20,
                  },
                  {
                    label: '30',
                    value: 30,
                  },
                ]}
                setSelectedOption={setSelectedPageOption}
              />
            </div>
            {/* MANAGE COLUMNS */}
            <FilterLabelVariant2
              icon={<BiColumns />}
              title="Manage Columns"
              onClick={() => setShowOptions({ ...showOptions, manageColumn: true })}>
              <ManageColumn
                showFilter={showOptions.manageColumn}
                title=""
                onClick={(e: { stopPropagation: () => void } | undefined) => handleManageClick(e)}
                submitButtonText="Save"
                extraClass="tw-w-max -tw-left-28 tw-h-[50vh] hide_scrollbar"
                onCancel={e => {
                  handleManageCancel(e);
                }}>
                <>
                  <div className="tw-pt-2">
                    {manageColumn.map((item: any) => (
                      <>
                        <div
                          key={item.id}
                          className="tw-flex tw-justify-between tw-items-center tw-gap-3 tw-border-b tw-pb-2">
                          <span className="tw-mt-4 tw-text-[13px] tw-text-[#84919A]">{item.title}</span>

                          <input
                            className=" tw-cursor-pointer"
                            type="checkbox"
                            onClick={() => {
                              toggleColumns(item.id, pendingChanges, setPendingChanges);
                            }}
                            onChange={() => handleCheckboxChange(item.id, setManageColumn)}
                            checked={pendingChanges.includes(item.id)}
                          />
                        </div>
                      </>
                    ))}
                  </div>
                </>
              </ManageColumn>
            </FilterLabelVariant2>
          </div>
          <div className="tw-items-center tw-gap-4 tw-flex">
            <div className="tw-order-2 md:tw-order-1">
              <FilterLabel
                title="Sort By"
                icon={<icons.TbArrowsSort color="#5E6366" />}
                onClick={() => setShowOptions({ ...showOptions, sortBy: true })}>
                <FilterCard
                  showFilter={showOptions.sortBy}
                  title="Sort by"
                  onClick={() => onSubmitFilter()}
                  submitButtonText="Apply"
                  extraClass="tw-w-[10rem] -tw-left-28">
                  <>
                    <div className="tw-pt-2">
                      {filterOption?.sortBy?.map((item: any) => (
                        <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                          <span className="tw-mt-4 tw-text-[13px] tw-text-[#84919A]">{item.title}</span>
                          <ZojaCheckbox
                            isChecked={item.isChecked}
                            onClick={() => toggleItemCheckedOneItem(item.id, filterOption, 'sortBy', setFilterOption)}
                          />
                        </div>
                      ))}
                    </div>
                    <div className="tw-pt-2 tw-border-">
                      {filterOption.orderBy.map((item: any) => (
                        <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                          <span className="tw-mt-4 tw-text-[13px] tw-text-[#84919A]">{item.title}</span>
                          <ZojaCheckbox
                            isChecked={item.isChecked}
                            onClick={() => toggleItemCheckedOneItem(item.id, filterOption, 'orderBy', setFilterOption)}
                          />
                        </div>
                      ))}
                    </div>
                  </>
                </FilterCard>
              </FilterLabel>
            </div>
            <div className="tw-order-1 md:tw-order-2">
              <ExportLabel
                title="Download Report"
                onClick={() => setShowOptions({ ...showOptions, exportOptions: true })}
                loading={downloadStatus === 'loading' ? true : false}>
                <FilterCard
                  showFilter={showOptions.exportOptions}
                  title="Export as"
                  onClick={() => null}
                  showSubmitButton={false}
                  extraClass="tw-w-[10rem] -tw-left-28">
                  <div className="tw-flex tw-flex-col tw-mt-4">
                    {/* <span
                      className="tw-text-xs tw-cursor-pointer tw-text-isGrey tw-border-b tw-border-b-[#f4f3f3] tw-pb-2 tw-mb-2"
                      onClick={() => onExport('pdf')}>
                      PDF
                    </span> */}
                    <span className="tw-text-xs tw-cursor-pointer tw-text-isGrey" onClick={() => onExport('csv')}>
                      CSV
                    </span>
                  </div>
                </FilterCard>
              </ExportLabel>
            </div>
          </div>
        </div>
        {userState.status === 'loading' ? (
          <div className="tw-h-[50vh] tw-w-full tw-flex tw-justify-center tw-items-center">
            <Oval
              height="80"
              width="80"
              color="#222b88cf"
              ariaLabel="tail-spin-loading"
              secondaryColor="#222b882b"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <div className="tw-rounded-3xl tw-bg-white tw-w-full">
            <ZojaReportTable visibleColumns={visibleColumns} column={column} filteredTable={true}>
              {tableData.map((data: Dictionary, idx: number) => (
                <TR key={data.sn}>
                  {column
                    .filter((column: { id: number }) => visibleColumns.includes(column.id))
                    .map((column: { id: Key | null | undefined; ref: string | number }) => (
                      <TD key={column.id}>{data[column.ref]}</TD>
                    ))}
                </TR>
              ))}
            </ZojaReportTable>
            {tableData.length < 1 ? (
              <div className="tw-w-full tw-py-3 tw-mx-auto">
                <p className="tw-text-center tw-font-semibold">No record found</p>
              </div>
            ) : (
              ''
            )}
            <div className=" tw-px-4">
              <hr className=" tw-h-1 tw-relative tw-top-2 tw-border-none tw-bg-black" />
            </div>
            <div className=" tw-px-8 tw-py-4">
              <SimplePagination
                itemsPerPage={itemsPerPage}
                totalItems={totalItems}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserGeneratedReport;
