import PermissionsCheck from './PermissionsCheck';

const ModulePermissions = ({ permissionDefault, permissions, setPermissions, checkedTitles }: any) => {
  return (
    <div>
      {checkedTitles.length > 0 && (
        <div className="tw-rounded-lg tw-shadow-zojaShadowSeven tw-px-8 tw-py-7 tw-max-h-[430px] tw-overflow-y-auto">
          <div className="tw-grid tw-gap-y-8">
            {checkedTitles.includes('dashboard') && (
              <PermissionsCheck
                select={permissions}
                setSelected={setPermissions}
                permissionList={permissionDefault.dashboard}
                title={'Dashboard'}
                itemName={'dashboard'}
                className="tw-border tw-border-[#E7E7E7]"
              />
            )}
            {checkedTitles.includes('Payments') && (
              <PermissionsCheck
                select={permissions}
                setSelected={setPermissions}
                permissionList={permissionDefault.payments}
                title={'Payments'}
                itemName={'payments'}
                className="tw-border tw-border-[#E7E7E7]"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ModulePermissions;
