import { ReactElement } from 'react';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';

interface TRProps {
  id?: string;
  children?: ReactElement | ReactElement[];
  onClick?: () => void | null;
  extraClass?: string;
}

type THProps = {
  id?: string | number;
  value?: any;
  order?: string;
  hasSortIcon?: boolean;
  headerValue?: any;
  sorting?: (col: string) => void | null;
  extraClass?: string;
};
interface TDProps extends TRProps {
  value?: any;
  extraClass?: string;
}

type TBODYProps = {
  children: ReactElement | ReactElement[];
};

type THEADProps = {
  children: ReactElement | ReactElement[];
};

const TR = ({ id, children, onClick, extraClass }: TRProps) => {
  return (
    <tr
      key={id}
      className={`tw-text-xs tw-capitalize tw-bg-white tw-p-6 tw-shadow-xs tw-cursor-pointer tw-shadow-zojaShadowNine ${extraClass}`}
      onClick={onClick}>
      {' '}
      {children}{' '}
    </tr>
  );
};

const TH = ({ id, value, headerValue, sorting, order, hasSortIcon }: THProps) => {
  return (
    <th key={id} className="tw-p-4 tw-text-sm tw-font-[500] tw-left tw-whitespace-nowrap tw-shadow-zojaShadowNine">
      <div className="tw-flex tw-items-center tw-gap-x-2">
        <div className=" tw-mb-0 tw-relative" onClick={() => (sorting ? sorting(headerValue) : '')}>
          {value}
          {hasSortIcon ? (
            <div className="tw-flex tw-flex-col ">
              <FaCaretUp size={10} color="#c4c4c4" className="tw-absolute tw-top-[2px] -tw-right-3" />
              <FaCaretDown size={10} color="#c4c4c4" className="tw-absolute tw-bottom-[2px] -tw-right-3" />
            </div>
          ) : null}
        </div>
      </div>
    </th>
  );
};

const TD = ({ id, value, onClick, children, extraClass }: TDProps) => {
  return (
    <td
      className={`tw-p-3 tw-text-sm tw-text-[#162082] tw-font-light tw-whitespace-nowrap tw-relative ${extraClass}`}
      onClick={onClick}>
      {value}
      {children}
    </td>
  );
};

const TBODY = ({ children }: TBODYProps) => {
  return <tbody>{children}</tbody>;
};

const THEAD = ({ children }: THEADProps) => {
  return <thead className="tw-bg-gray-50 tw-border-b-2 tw-border-gray-200"> {children} </thead>;
};

export { TH, TD, TR, THEAD, TBODY };
