import { Dictionary } from '../../types';

export const handleSelectedOption = (selected: Dictionary, optionList: any[], setOptionList: any) => {
  const selectedIndex = optionList.findIndex(f => f.value === selected?.value);

  let newState = optionList.map(d => {
    if (d.isChecked === true && selected.isChecked === false) {
      return { ...d, isChecked: false };
    }

    return d;
  });

  newState[selectedIndex].isChecked = !newState[selectedIndex].isChecked;
  setOptionList(newState);
};

export const handleSelectedOptionNestedSetState = (selected: Dictionary, optionListKey: any, setFilterOption: any) => {
  setFilterOption((prevFilterOption: any) => {
    // Create a copy of the previous state to avoid mutating it
    const updatedFilterOption = { ...prevFilterOption };

    // Find the index of the selected item in the optionList
    const selectedIndex = updatedFilterOption[optionListKey].findIndex(
      (item: { value: any }) => item.value === selected.value,
    );

    // Update the isChecked property for the selected item
    updatedFilterOption[optionListKey][selectedIndex].isChecked = !selected.isChecked;

    return updatedFilterOption;
  });
};

export const getSelectedOptionValue = (options: Dictionary[]) => {
  const selectedOption = options.find(option => option.isChecked === true);

  return selectedOption ? selectedOption.value : '';
};

export const getEverySelectedOptionValue = (options: Dictionary[], type?: any) => {
  const selectedOptions = options.filter(option => option.isChecked);

  const selectedValues = selectedOptions.map(option => option.value);

  return type !== 'account' ? selectedValues.toString() : selectedValues;
};

export const formatStartAndEndDateWithDelimeter = (startDate: any, endDate: any, delimiter: string = ':') =>
  `${startDate}${delimiter}${endDate}`;

export const handleCheckboxChange = (id: number, setState: (arg0: (prevColumn: any[]) => any[]) => void) => {
  setState((prevColumn: any[]) =>
    prevColumn.map(item => (item.id === id ? { ...item, isChecked: !item.isChecked } : item)),
  );
};

export const handleCheckboxChange2 = (id: any, setState: (arg0: (prevFilterOption: any) => any) => void) => {
  setState(prevFilterOption => {
    const updatedFilterOption = { ...prevFilterOption };

    // Find the key that matches the item with the specified ID
    for (const key in updatedFilterOption) {
      if (updatedFilterOption?.hasOwnProperty(key) && Array?.isArray(updatedFilterOption[key])) {
        //

        const itemArray = updatedFilterOption[key];

        // Find the index of the item within the specified array
        const itemIndex = itemArray.findIndex((item: { id: any }) => item.id === id);

        if (itemIndex !== -1) {
          // Toggle the isChecked property of the item
          itemArray[itemIndex] = { ...itemArray[itemIndex], isChecked: !itemArray[itemIndex].isChecked };

          break;
        }
      }
    }

    return updatedFilterOption;
  });
};

// Toggle column visibility
export const toggleColumn = (columnId: number, visibleColumns: any, setVisibleColumns: (arg0: any) => void) => {
  if (visibleColumns.includes(columnId)) {
    setVisibleColumns(visibleColumns.filter((id: any) => id !== columnId));
  } else {
    setVisibleColumns([...visibleColumns, columnId]);
  }
};

export const toggleItemChecked = (itemId: number, items: any, itemName: any, setFilterOption: (arg0: any) => void) => {
  const updatedFilterOption = { ...items };

  // Find the item array based on the itemName parameter
  const itemArray = updatedFilterOption[itemName];

  if (itemArray) {
    // Find the index of the item within the specified array
    const itemIndex = itemArray.findIndex((info: { id: number }) => info.id === itemId);

    if (itemIndex !== -1) {
      // Toggle the isChecked property of the item
      itemArray[itemIndex].isChecked = !itemArray[itemIndex].isChecked;

      // Update the state with the modified copy
      setFilterOption(updatedFilterOption);
    }
  }
};

export const toggleItemCheckedOneItem = (
  itemId: number,
  items: any,
  itemName: any,
  setFilterOption: (arg0: any) => void,
) => {
  const updatedFilterOption = { ...items };

  // Find the item array based on the itemName parameter
  const itemArray = updatedFilterOption[itemName];

  if (itemArray) {
    // Find the index of the item within the specified array
    const itemIndex = itemArray.findIndex((info: { id: number }) => info.id === itemId);

    if (itemIndex !== -1) {
      // Toggle the isChecked property of the item
      itemArray[itemIndex].isChecked = !itemArray[itemIndex].isChecked;

      // If the item is checked, uncheck other items in the same array
      if (itemArray[itemIndex].isChecked) {
        itemArray.forEach((item: { isChecked: boolean }, index: any) => {
          if (index !== itemIndex) {
            item.isChecked = false;
          }
        });
      }

      // Update the state with the modified copy
      setFilterOption(updatedFilterOption);
    }
  }
};

export function replaceCommasInQueryParam(queryParamValue: string) {
  const params = queryParamValue?.split('&');

  const replacedParams = params?.map(param => {
    const [key, value] = param?.split('=');
    const decodedValue = value?.replace(/%2C/g, ',');
    return `${key}=${decodedValue}`;
  });

  const reconstructedQueryParamValue = replacedParams?.join('&');

  return reconstructedQueryParamValue;
}

// OPENS ONLY ONE FILTER OPTION AT A TIME
export const setOneOptionTrue = (
  selectedOption: string,
  setShowOptions: (arg0: (prevOptions: any) => { bigFilter: any }) => void,
) => {
  if (selectedOption === 'bigFilter' || selectedOption === 'sortBy' || selectedOption === 'bigFilter') {
    return; // Do nothing if trying to toggle "bigFilter"
  }

  setShowOptions((prevOptions: any) => ({
    ...Object.fromEntries(
      Object.entries(prevOptions).map(([key, value]) => [key, key === selectedOption ? !value : false]),
    ),
    bigFilter: prevOptions.bigFilter,
  }));
};

export const toggleColumns = (columnId: number, pendingChanges: number[], setPendingChanges: (arg0: any[]) => void) => {
  if (pendingChanges.includes(columnId)) {
    setPendingChanges(pendingChanges.filter(id => id !== columnId));
  } else {
    setPendingChanges([...pendingChanges, columnId]);
  }
};

export const getCheckedTitles = (data: any[]) => {
  const checkedTitles: any[] = [];

  data?.forEach(item => {
    if (item.isChecked) {
      checkedTitles.push(item.title);
    }
  });

  return checkedTitles;
};

export const handleSelectedOptionHandler = (
  selected: Dictionary,
  optionList: any[],
  setOptionList: any,
  setCheckedBox: any,
) => {
  const selectedIndex = optionList.findIndex(f => f.value === selected?.value);

  let newState = optionList.map(d => {
    if (d.isChecked === true && selected.isChecked === false) {
      return { ...d, isChecked: false };
    }

    return d;
  });

  newState[selectedIndex].isChecked = !newState[selectedIndex].isChecked;
  setOptionList(newState);

  const isSelected = newState.filter(item => item.isChecked === true);

  setCheckedBox(isSelected);
};