import { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { AiOutlinePlus } from 'react-icons/ai';
import {
  AppContainer,
  MoreIconView,
  CreateInternalUserModal,
  ActivityActionModal,
  ProfileActivationToggleModal,
  LoginHistoryOnlyModal,
} from '../../atoms';
import {
  capitalizeFirstLetter,
  colors,
  dateFormat,
  routesPath,
  arrayToString,
  images,
  getWordFromString,
  lowerCaseFirstLetter,
  timeFormat,
  icons,
  handleSelectedOption,
  getSelectedOptionValue,
  formatStartAndEndDateWithDelimeter,
  useDelayedFunction,
  useSortTable,
} from '../../utils';
import {
  SearchInput,
  Pagination,
  BorderedText,
  InternaUsersTable,
  RolesAndPermissionTable,
  ExportLabel,
  FilterCard,
  FilterLabel,
  ZojaCheckbox,
  FilterColapsible,
  CustomDatePicker,
  DownloadSuccessModal,
  PageTabIndicator,
} from '../../components';
import { InternalUserTop, InternalUsersContainer, TableContainer, UserContainer } from './style';

import { loginHistoryDataHeader, internalUsersDataHeader, rolesAndPermissionDataHeader } from './data';
import { Dictionary } from '../../types';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import {
  getUsersRequest,
  getSuperAgentsRequest,
  getInternalUsersRequest,
  getRolesDropDownRequest,
  createInternalUserRequest,
  createInternalUserReset,
  updateInternalUserRequest,
  updateInternalUserReset,
  resetInternalUserPasswordRequest,
  resetInternalUserPasswordReset,
  updateUserStatusRequest,
  updateUserStatusReset,
  getLoginHistoryRequest,
  getRolesRequest,
  deleteRoleRequest,
  deleteRoleReset,
  downloadUsersRecord,
  downloadInternalUserRecord,
  downloadRolePermission,
  downloadInternalUserRecordReset,
  downloadUsersRecordReset,
  downloadRolePermissionReset,
  updateLoginData,
} from '../../redux/slice';
import { ZojaSelect } from '../../components/tailwind';
import { Oval } from 'react-loader-spinner';

const { CUSTOMERDETAILS, USERROLES, CREATEUSERROLES } = routesPath;

interface Permission {
  id: number;
  name: string;
  description: string | null;
}

interface PermissionsWithChecked {
  [label: string]: Permission[];
}

const activeUser = 'active';
const inActiveUser = 'inactive';
const superAgentUser = 'super_agent';

const namedEdit = 'Edit';
const namedDeactivate = 'Deactivate';
const namedReactivate = 'Reactivate';
const namedResetPassword = 'Reset Password';
const namedViewLoginHistory = 'View Login History';

const roleDetails = 'Role Details';
const roleDeleteRole = 'Delete Role';

const userTypeToFetchByActivity = (data: Dictionary) => {
  let result: string = '';
  if (!data?.hasOwnProperty('id') || data?.id === 1) {
    result = activeUser;
  }

  if (data?.id === 2) {
    result = superAgentUser;
  }

  if (data?.id === 3) {
    result = inActiveUser;
  }

  return result;
};

const UserPageTabs = [
  {
    id: 1,
    title: 'Internal Users',
    url: 'internal_users',
  },
  {
    id: 2,
    title: 'Roles and permission',
    url: 'roles_and_permission',
  },
];

//Table more dropdown
const dropdownList: Dictionary = [
  {
    id: 1,
    title: 'Edit',
    value: 'edit',
  },
  {
    id: 2,
    title: 'Deactivate',
    value: 'deactivate',
  },
  {
    id: 3,
    title: 'Reactivate',
    value: 'reactivate',
  },
  {
    id: 4,
    title: 'Reset Password',
    value: 'resetPassword',
  },
  {
    id: 5,
    title: 'View Login History',
    value: 'viewLoginHistory',
  },
];

// filter options constants
const sortByOptions = {
  aphabetically: [
    {
      id: 1,
      value: 'az',
      title: 'A-Z',
      isChecked: false,
    },
    {
      id: 2,
      value: 'za',
      title: 'Z-A',
      isChecked: false,
    },
    {
      id: 3,
      value: 'kyc_level',
      title: 'Kyc Level',
      isChecked: false,
    },
  ],
  orderBy: [
    {
      id: 1,
      value: 'newest',
      title: 'Newest',
      isChecked: false,
    },
    {
      id: 2,
      value: 'oldest',
      title: 'Oldest',
      isChecked: false,
    },
    {
      id: 3,
      value: 'last_seen',
      title: 'Last Seen',
      isChecked: false,
    },
  ],
};

const memberAndLastSeenOptions = [
  {
    id: 1,
    value: 'one_week',
    title: 'One week',
    isChecked: false,
  },
  {
    id: 2,
    value: 'thirty_days',
    title: '30 Days',
    isChecked: false,
  },
  {
    id: 3,
    value: 'six_months',
    title: '6 Months',
    isChecked: false,
  },
  {
    id: 4,
    value: 'custom',
    title: 'Custom',
    isChecked: false,
  },
];

const LastSeenOptions = [
  {
    id: 1,
    value: 'one_week',
    title: 'One week',
    isChecked: false,
  },
  {
    id: 2,
    value: 'thirty_days',
    title: '30 Days',
    isChecked: false,
  },
  {
    id: 3,
    value: 'six_months',
    title: '6 Months',
    isChecked: false,
  },
  {
    id: 4,
    value: 'custom',
    title: 'Custom',
    isChecked: false,
  },
];

const initialValues: Dictionary = {
  status: '',
  kyc_level: '',
  last_seen: '',
  sort_by: '',
  order_by: '',
  date_onboarded: '',
  deactivated_at: '',
};

function Users() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const successModalRef = useRef<any>(null);

  const [selectedUsersCard, setSelectedUsersCard] = useState<Dictionary>({
    id: 1,
    count: 0,
    title: 'Active Users',
  });

  const [isSearchingUsers, setIsSearchingUsers] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [userCountData, setUserCountData] = useState<any[]>([]);
  const [usersData, setUsersData] = useState<any[]>([]);
  const [usersDataSuperAgent, setUsersDataSuperAgent] = useState<any[]>([]);
  const [selectedInternalUserItem, setSelectedInternalUserItem] = useState<Dictionary>({});
  const [selectedRoleItem, setSelectedRoleItem] = useState<Dictionary>({});
  const [moreIconIsVisible, setMoreIconIsVisible] = useState(false);
  const [roleMoreIconIsVisible, setRoleMoreIconIsVisible] = useState(false);
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [rolePermissionTotalPages, setRolePermissionTotalPages] = useState(5);
  const [internalUserTotalPages, setInternalUserTotalPages] = useState(5);
  const [, setSelectedTransactionActionText] = useState('');
  const [, setSelectedRoleActionText] = useState('');
  const [searchInternalUserValue, setSearchInternalUserValue] = useState('');
  const [searchUserRoleValue, setSearchUserRoleValue] = useState('');
  const [firstMount, setFirstMount] = useState(true);
  const [perPage, setPerPage] = useState<any>('10');
  const [, setInternalUsersData] = useState<any[]>([]);
  const [internalUsersDataList, setInternalUsersDataList] = useState<any[]>([]);
  const [createInternalUserIsModalVisible, setCreateInternalUserIsModalVisible] = useState(false);
  const [editInternalUserIsModalVisible, setEditInternalUserIsModalVisible] = useState(false);
  const [openPermissions, setOpenPermissions] = useState(false);
  const [userRole, setUserRole] = useState();
  const [rolesData, setRolesData] = useState([
    {
      value: 'admin',
      label: 'Admin',
    },
  ]);
  const [rolesPermissionData, setRolesPermissionData] = useState([
    {
      value: '',
      label: '',
      permissionCount: '',
      permissions: [],
    },
  ]);

  const [permissionData, setPermissionData] = useState({});

  const [createUserSuccessModalVisible, setCreateUserSuccessModalVisible] = useState(false);
  const [toggleGetInternalUser, setToggleGetInternalUser] = useState(false);
  const [resetPasswordSuccessModalVisible, setResetPasswordSuccessModalVisible] = useState(false);
  const [profileActivationSuccessIsModalVisible, setProfileActivationSuccessIsModalVisible] = useState(false);
  const [profileActivationIsModalVisible, setProfileActivationIsModalVisible] = useState(false);
  const [userAccountStatus, setUserAccountStatus] = useState('');
  const [deactiveMessage, setDeactiveMessage] = useState('');
  const [loginHistoryIsModalVisible, setLoginHistoryIsModalVisible] = useState(false);
  const [loginHistoryData, setLoginHistoryData] = useState<any[]>([]);
  // const [, setUserRolesData] = useState<any[]>([]);
  const [userRolesDataList, setUserRolesDataList] = useState<any[]>([]);
  const [deleteRoleIsModalVisible, setDeleteRoleIsModalVisible] = useState(false);
  const [deleteRoleSuccessIsModalVisible, setDeleteRoleSuccessIsModalVisible] = useState(false);
  const [order, setOrder] = useState('ASC');

  // export states
  const [showExportOptions, setShowExportOptions] = useState(false);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [showSortBy, setShowSortBy] = useState(false);
  const [showDateOnboarded, setShowDateOnboarded] = useState(false);
  const [showLastSeen, setShowLastSeen] = useState(false);
  const [activePageTab, setActivePageTab] = useState('internal_users');
  const [sortBy, setSortBy] = useState(sortByOptions.aphabetically);
  const [dateOnboarded, setDateOnboarded] = useState(memberAndLastSeenOptions);
  const [lastSeen, setLastSeen] = useState(LastSeenOptions);
  const [orderBy, setOrderBy] = useState(sortByOptions.orderBy);
  const [onboardedDateRange, setOnboardedDateRange] = useState({ state_date: '', end_date: '' });
  const [lastSeenDateRange, setLastSeenDateRange] = useState({ state_date: '', end_date: '' });
  const [filterOptions, setFilterOptions] = useState(initialValues);
  const [showSuccessDownloadModal, setShowSuccessDownloadModal] = useState(false);
  const [currentUserData, setCurrentUserData] = useState<any>([]);

  const decideUserCurrentStatus: string = userAccountStatus === 'active' ? namedDeactivate : namedReactivate;
  //More Icon for Internal Users
  const moreIconOption = [namedEdit, decideUserCurrentStatus, namedResetPassword, namedViewLoginHistory];
  const roleMoreIconOption = [roleDetails, roleDeleteRole];

  const loginState = useAppSelector(state => state.login);

  // redux state
  // const usersState = useAppSelector(state => state.getUsers);
  // const { status: usersStatus } = usersState;

  const superAgentsState = useAppSelector(state => state.getSuperAgents);
  const { status: superAgentsStatus } = superAgentsState;

  const internalUsersState = useAppSelector(state => state.getInternalUsers);
  const { status: internalUsersStatus } = internalUsersState;

  const rolesDropDownState = useAppSelector(state => state.getRolesDropDown);
  const { status: rolesDropDownStatus } = rolesDropDownState;

  const createInternalUserState = useAppSelector(state => state.createInternalUser);
  const { status: createInternalUserStatus } = createInternalUserState;

  const updateInternalUserState = useAppSelector(state => state.updateInternalUser);
  const { status: updateInternalUserStatus } = updateInternalUserState;

  const resetInternalUserPasswordState = useAppSelector(state => state.resetInternalUserPassword);
  const { status: resetInternalUserPasswordStatus } = resetInternalUserPasswordState;

  const updateUserStatusState = useAppSelector(state => state.updateUserStatus);
  const { status: updateUserStatusStatus } = updateUserStatusState;

  const loginHistoryState = useAppSelector(state => state.getLoginHistory);
  const { status: loginHistoryStatus } = loginHistoryState;

  const rolesState = useAppSelector(state => state.getRoles);
  const { status: rolesStatus } = rolesState;

  const deleteRoleState = useAppSelector(state => state.deleteRole);
  const { status: deleteRoleStatus } = deleteRoleState;

  // download records status states
  const { status: downloadStatus } = useAppSelector(state => state.downloadUsersRecord);
  const { status: downloadInternalUserStatus } = useAppSelector(state => state.downloadInternalUserRecord);
  const { status: downloadRolePermissionStatus } = useAppSelector(state => state.downloadRolePermission);

  // get users by status
  const userTypeToFetch = userTypeToFetchByActivity(selectedUsersCard);

  useEffect(() => {
    if (activePageTab === UserPageTabs?.[0]?.url) {
      // removing kyc and adding status to sort by option
      setSortBy((prevState: any) => {
        let newOption = {
          id: prevState.length + 1,
          value: 'status',
          title: 'Status',
          isChecked: false,
        };
        let prevData = sortByOptions.aphabetically.filter((f: any) => f.value !== 'kyc_level');
        prevData = [...prevData, { ...newOption }];
        return prevData;
      });
      dispatch(
        getInternalUsersRequest({
          status: '',
          last_seen: '',
          date_onboarded: '',
          sort_by: '',
          order_by: '',
          search: '',
          page: currentPage,
          per_page: perPage,
        }),
      );
    }
    if (activePageTab === UserPageTabs?.[1]?.url) {
      // removing kyc and status on sort_by option
      setSortBy((prevState: any) => {
        let prevData = sortByOptions.aphabetically.filter((f: any) => f.value !== 'status');
        const findIndex = prevData.findIndex(d => d.value === 'kyc_level');
        if (findIndex) {
          let prevData = sortByOptions.aphabetically.filter((f: any) => f.value !== 'kyc_level');
          return prevData;
        }
        return [...prevData];
      });
      setOrderBy((prevState: any) => {
        let prevData = sortByOptions.orderBy.filter((f: any) => f.value !== 'last_seen');
        return prevData;
      });
      dispatch(
        getInternalUsersRequest({
          status: '',
          last_seen: '',
          date_onboarded: '',
          sort_by: '',
          order_by: '',
          per_page: perPage,
        }),
      );
    }
  }, [activePageTab, toggleGetInternalUser, updateUserStatusState, currentPage]);

  useEffect(() => {
    if (activePageTab === UserPageTabs?.[1]?.url || activePageTab === UserPageTabs?.[0]?.url) {
      dispatch(
        getRolesRequest({
          last_seen: '',
          role_creator: '',
          sort_by: '',
          order_by: '',
          date_range: '',
          term: '',
          per_page: perPage,
        }),
      );
    }
  }, [activePageTab, deleteRoleState]);

  useEffect(() => {
    if (internalUsersStatus === 'succeeded') {
      let updateUsersData: any[] = [];
      internalUsersState?.data?.users?.data?.forEach((item: Dictionary, index: number) => {
        updateUsersData.push({
          id: index + 1,
          name: item?.name,
          email: item?.email,
          role: arrayToString(item?.roles),
          status: item?.status,
          lastSeen: item?.last_login,
          dateEnrolled: item?.created_at,
          userId: item?.id,
          permissions: item?.permissions,
        });
      });

      const {
        meta: { last_page },
      } = internalUsersState?.data?.users;

      setInternalUserTotalPages(last_page);

      setInternalUsersData(updateUsersData);
      setInternalUsersDataList(updateUsersData);
    }
  }, [internalUsersState]);

  useEffect(() => {
    if (rolesDropDownStatus === 'succeeded') {
      let updateRoleDta: any[] = [];
      if (!editInternalUserIsModalVisible) {
        updateRoleDta.push({ label: 'Select Role', value: '' });
      }
      rolesDropDownState?.data?.roles?.data?.forEach((item: Dictionary) => {
        updateRoleDta.push({
          value: item.name,
          label: capitalizeFirstLetter(item.name),
        });
      });
      setRolesData(updateRoleDta);
    }
  }, [rolesDropDownState]);

  useEffect(() => {
    if (rolesStatus === 'succeeded') {
      let updateRolesData: any[] = [];
      rolesState?.data?.roles?.data?.forEach((item: Dictionary) => {
        updateRolesData.push({
          value: item.name,
          label: item.name,
          permissionCount: item.permission_count,
          permissions: item.permissions,
        });
      });
      setRolesPermissionData(updateRolesData);
    }
  }, [rolesState]);

  useEffect(() => {
    let permissionsByLabel: PermissionsWithChecked = {};

    rolesPermissionData.forEach(permissionSet => {
      let label = permissionSet.label;
      permissionsByLabel[label] = permissionSet.permissions.map((permission: any) => {
        return { ...permission, title: permission?.name, value: permission?.name, isChecked: false };
      });
    });

    setPermissionData(permissionsByLabel);
  }, [rolesPermissionData, openPermissions]);

  useEffect(() => {
    let resultLoginHistory: any[] = [];
    if (loginHistoryStatus === 'succeeded') {
      loginHistoryState?.data?.users?.data?.forEach((el: Dictionary, index: number) => {
        resultLoginHistory.push({
          id: index + 1,
          time: `${dateFormat(el?.login_at)} - ${timeFormat(el?.login_at)}`,
          device: el?.data?.userAgent === null ? 'N/A' : el?.data?.userAgent,
        });
      });

      setLoginHistoryData(resultLoginHistory);
    }
  }, [loginHistoryState]);

  // successful deactivate or reactivate user
  useEffect(() => {
    if (updateUserStatusStatus === 'succeeded') {
      setProfileActivationSuccessIsModalVisible(true);
    }
  }, [updateUserStatusState]);

  useEffect(() => {
    if (createInternalUserStatus === 'succeeded') {
      setCreateInternalUserIsModalVisible(false);
      setCreateUserSuccessModalVisible(true);
    }
    if (updateInternalUserStatus === 'succeeded') {
      // console.log(currentUserData);
      let payload = { key: 'permissions', value: currentUserData };
      // dispatch(updateLoginData(payload));
      setEditInternalUserIsModalVisible(false);
      setCreateUserSuccessModalVisible(true);
    }
  }, [createInternalUserState, updateInternalUserState]);

  useEffect(() => {
    if (resetInternalUserPasswordStatus === 'succeeded') {
      setResetPasswordSuccessModalVisible(true);
    }
  }, [resetInternalUserPasswordState]);

  useEffect(() => {
    let resultRoles: any[] = [];
    if (rolesStatus === 'succeeded') {
      rolesState?.data?.roles?.data?.forEach((el: Dictionary, index: number) => {
        resultRoles.push({
          title: el.name,
          permissionCount: el?.permission_count,
          userCount: el?.user_count,
          createdBy: el?.publisher === null ? 'N/A' : el?.publisher,
          roleId: el?.id,
          id: el?.id,
          dateCreated: el?.created_at,
        });
      });

      const last_page = rolesState?.data?.roles?.meta?.last_page;

      setRolePermissionTotalPages(last_page);
      // setUserRolesData(resultRoles);
      setUserRolesDataList(resultRoles);
    }
  }, [rolesState]);

  useEffect(() => {
    if (deleteRoleStatus === 'succeeded') {
      setDeleteRoleSuccessIsModalVisible(true);
    }
  }, [deleteRoleState]);

  // handle different modules
  const handleMoreIconOptions = async (item: any) => {
    setMoreIconIsVisible(false);
    if (item.title === namedEdit) {
      dispatch(getRolesDropDownRequest({}));
      setEditInternalUserIsModalVisible(true);
    }
    if (item.title === namedDeactivate || item.title === namedReactivate) {
      setProfileActivationIsModalVisible(true);
    }
    if (item.title === namedResetPassword) {
      dispatch(resetInternalUserPasswordRequest({ userId: selectedInternalUserItem?.userId }));
    }
    if (item.title === namedViewLoginHistory) {
      setLoginHistoryIsModalVisible(true);
      dispatch(getLoginHistoryRequest({ userId: selectedInternalUserItem?.userId }));
    }
  };

  // getRolesDropDownRequest

  // Function opens more item when the more icon in internal users table is clicked
  const handleItemModalOpen = (item: Dictionary) => {
    console.log(item);
    setUserAccountStatus(item?.status);
    setSelectedInternalUserItem(item);
    setMoreIconIsVisible(true);
  };

  const handleRoleModalOpen = (item: Dictionary) => {
    setSelectedRoleItem(item);
    setRoleMoreIconIsVisible(true);
  };

  const handleRoleMoreIconOptions = (item: any) => {
    setRoleMoreIconIsVisible(false);
    if (item.title === roleDetails) {
      navigate(`${USERROLES}${selectedRoleItem?.id.toString()}`);
    }

    if (item.title === roleDeleteRole) {
      setDeleteRoleIsModalVisible(true);
    }
  };

  const addInternalUserBtn = () => {
    dispatch(getRolesDropDownRequest({}));
    setCreateInternalUserIsModalVisible(true);
  };

  const handleCreateInternalUserModalBtn = (item: Dictionary) => {
    const { email, first_name, last_name, role, permissions } = item;
    const payload = {
      first_name,
      last_name,
      role,
      email,
      permissions,
    };
    dispatch(createInternalUserRequest(payload));
  };

  const handleCloseCreateInternalUserModal = () => {
    setCreateUserSuccessModalVisible(false);
    dispatch(createInternalUserReset());
    dispatch(updateInternalUserReset());
    setToggleGetInternalUser(!toggleGetInternalUser);
  };

  const handleEditInternalUserModalBtn = (item: Dictionary) => {
    const { email, first_name, last_name, role, permissions } = item;

    const payload = {
      first_name,
      last_name,
      role: lowerCaseFirstLetter(role),
      email,
      userId: selectedInternalUserItem?.userId,
      permissions,
    };

    const filteredPermissions = rolesState?.data?.roles?.data?.filter(
      (f: Dictionary) => f.name.toLowerCase() === payload.role.toLowerCase(),
    )[0]?.permissions;

    // Create a Set of ids from the default permission
    const idsSet = new Set(payload?.permissions?.map((item: Dictionary) => item));

    // Iterate over each object in the first Roles and Permission
    const result = filteredPermissions.map((obj: Dictionary) => {
      // If the id exists in the idsSet, set isChecked to true
      if (idsSet.has(obj.name)) {
        return { ...obj, isChecked: true };
      }
      return { ...obj, isChecked: false }; // Otherwise, return the object as it is
    });

    let newData = result?.filter((f: any, idx: number) => f.isChecked === true);

    setCurrentUserData(newData);

    dispatch(updateInternalUserRequest(payload));
  };

  const handleCloseResetPasswordModal = () => {
    setResetPasswordSuccessModalVisible(false);
    dispatch(resetInternalUserPasswordReset());
  };

  // reactivate or deactivate user action
  const handleUserProfileActivity = () => {
    let payload: Dictionary;
    if (userAccountStatus === 'active') {
      payload = {
        userId: selectedInternalUserItem?.userId,
        data: {
          status: 'inactive',
          comment: deactiveMessage,
        },
      };
    } else {
      payload = {
        userId: selectedInternalUserItem?.userId,
        data: {
          status: 'active',
        },
      };
    }

    dispatch(updateUserStatusRequest(payload));
  };

  const handleProfileActivationSuccessClose = () => {
    setProfileActivationSuccessIsModalVisible(false);
    dispatch(updateUserStatusReset());
  };

  const handleOnchangeInternalUser = (value: any) => {
    setSearchInternalUserValue(value);
  };

  const handleOnchangeRole = (value: any) => {
    setSearchUserRoleValue(value);
  };

  const handleDeleteRole = () => {
    setRoleMoreIconIsVisible(false);
    dispatch(deleteRoleRequest({ id: selectedRoleItem?.roleId }));
  };

  const handleSuccessDeleteRole = () => {
    setDeleteRoleSuccessIsModalVisible(false);
    setDeleteRoleIsModalVisible(false);
    dispatch(deleteRoleReset());
  };

  // filter and export methods
  const handleExportInternalUserFile = (type: string) => {
    let exportPayload = { ...filterOptions, export: type };
    dispatch(downloadInternalUserRecord(exportPayload));
    setShowExportOptions(false);
  };
  const handleExportRolePermissionFile = (type: string) => {
    const roleCreator = filterOptions?.role_creator ? filterOptions?.role_creator : '';
    const dateRange = filterOptions?.date_range ? filterOptions?.date_range : '';
    let exportPayload = {
      ...filterOptions,
      export: type,
      role_creator: roleCreator,
      date_range: dateRange,
      term: searchUserRoleValue,
    };
    dispatch(downloadRolePermission(exportPayload));
    setShowExportOptions(false);
  };

  // filter table handler
  const handleSubmitRolePermissionFilter = () => {
    const roleCreator = filterOptions?.roleCreator ? filterOptions?.roleCreator : '';
    const onboarded_date_range =
      onboardedDateRange.state_date && onboardedDateRange.end_date
        ? formatStartAndEndDateWithDelimeter(onboardedDateRange.state_date, onboardedDateRange.end_date)
        : '';
    let payload = { ...filterOptions };
    payload = payload.date_onboarded === 'custom' ? { ...payload } : payload;
    payload = { ...payload, date_range: onboarded_date_range, term: searchUserRoleValue, role_creator: roleCreator };
    dispatch(getRolesRequest({ ...payload, per_page: perPage, page: currentPage }));
  };
  const handleSubmitInternalUserFilter = () => {
    const onboarded_date_range = formatStartAndEndDateWithDelimeter(
      onboardedDateRange.state_date,
      onboardedDateRange.end_date,
    );
    const lastseen_date_range = formatStartAndEndDateWithDelimeter(
      lastSeenDateRange.state_date,
      lastSeenDateRange.end_date,
    );
    let payload = { ...filterOptions };
    payload = payload.date_onboarded === 'custom' ? { ...payload, date_onboarded: onboarded_date_range } : payload;
    payload = payload.last_seen === 'custom' ? { ...payload, last_seen: lastseen_date_range } : payload;
    dispatch(
      getInternalUsersRequest({ ...payload, search: searchInternalUserValue, per_page: perPage, page: currentPage }),
    );
  };

  // on check get onboarded value
  useEffect(() => {
    const getOnboardedvalue = getSelectedOptionValue(dateOnboarded);
    const getLastSeenValue = getSelectedOptionValue(lastSeen);
    const getSelectedSortBy = getSelectedOptionValue(sortBy);
    const getSelectedOrderBy = getSelectedOptionValue(orderBy);

    // userTypeToFetch !== 'inactive'
    let onboarded_data = getOnboardedvalue;
    let deactivated_data = userTypeToFetch === 'inactive' ? '' : getOnboardedvalue;

    setFilterOptions({
      ...filterOptions,
      date_onboarded: onboarded_data,
      deactivated_at: deactivated_data,
      last_seen: getLastSeenValue,
      sort_by: getSelectedSortBy,
      order_by: getSelectedOrderBy,
    });
  }, [dateOnboarded, lastSeen, sortBy, orderBy, selectedUsersCard]);

  // show download success modal
  useEffect(() => {
    if (
      downloadStatus === 'succeeded' ||
      downloadInternalUserStatus === 'succeeded' ||
      downloadRolePermissionStatus === 'succeeded'
    ) {
      setShowSuccessDownloadModal(true);
    }
  }, [downloadStatus, downloadInternalUserStatus, downloadRolePermissionStatus]);

  // fetch role and permission by term on input change
  const delay = 1000;
  const { delayedFunction } = useDelayedFunction(delay);
  useEffect(() => {
    if (searchUserRoleValue.length >= 2 || searchUserRoleValue.length === 0) {
      delayedFunction(handleSubmitRolePermissionFilter);
    }
  }, [searchUserRoleValue]);
  useEffect(() => {
    if (searchInternalUserValue.length >= 2 || searchInternalUserValue.length === 0) {
      delayedFunction(handleSubmitInternalUserFilter);
    }
  }, [searchInternalUserValue]);

  useEffect(() => {
    setShowFilterOptions(false);
    setShowSortBy(false);
    setShowExportOptions(false);
  }, [internalUsersStatus, rolesStatus, superAgentsStatus]);

  // Custom Hooks
  const { handleSortItems } = useSortTable({
    order: order,
    setOrder,
    data: activePageTab === 'internal_users' ? internalUsersDataList : userRolesDataList,
    setData: activePageTab === 'internal_users' ? setInternalUsersDataList : setUserRolesDataList,
  });

  // handle page number change
  const handlePageNumberChange = (pageNumber: any, type: string) => {
    setPerPage(pageNumber);

    const onboarded_date_range = formatStartAndEndDateWithDelimeter(
      onboardedDateRange.state_date,
      onboardedDateRange.end_date,
    );

    const roleCreator = filterOptions?.role_creator ? filterOptions?.role_creator : '';
    const dateRange = filterOptions?.date_range ? filterOptions?.date_range : '';

    let payload = { ...filterOptions };
    payload = payload.date_onboarded === 'custom' ? { ...payload, date_onboarded: onboarded_date_range } : payload;

    if (type === 'role') {
      dispatch(
        getRolesRequest({
          ...payload,
          role_creator: roleCreator,
          date_range: dateRange,
          term: searchUserRoleValue,
          page: currentPage,
          per_page: pageNumber,
        }),
      );
    }
    if (type === 'user') {
      dispatch(
        getInternalUsersRequest({
          ...payload,
          search: searchValue,
          page: currentPage,
          per_page: pageNumber,
        }),
      );
    }
  };

  return (
    <AppContainer navTitle="USER">
      <>
        <DownloadSuccessModal
          show={showSuccessDownloadModal}
          handleClose={() => {
            // dispatch(downloadUsersRecordReset());
            dispatch(downloadInternalUserRecordReset());
            dispatch(downloadRolePermissionReset());
            setShowSuccessDownloadModal(false);
          }}
          closeOnClickOutside={false}
          contentRef={successModalRef}
          title="Item Downloaded"
          content="Your document has been downloaded to your device"
        />
        <UserContainer>
          {(showFilterOptions || showSortBy || showExportOptions) && (
            <div
              className="tw-absolute tw-bg-black/0 tw-cursor-pointer tw-z-30 tw-w-full tw-h-screen
           tw-top-0 tw-left-0"
              onClick={() => {
                setShowFilterOptions(false);
                setShowSortBy(false);
                setShowExportOptions(false);
              }}></div>
          )}
          <PageTabIndicator tabData={UserPageTabs} selected={activePageTab} setSelected={setActivePageTab} />
          {activePageTab === UserPageTabs?.[0]?.url && (
            <InternalUsersContainer>
              <InternalUserTop className="tw-flex tw-flex-col tw-items-center lg:tw-flex-row">
                <BorderedText
                  text="New User"
                  icon={<AiOutlinePlus color={colors.white} size={15} />}
                  backgroundColor={colors.primary}
                  color={colors.white}
                  onClick={addInternalUserBtn}
                />
                <div className="tw-flex tw-items-center tw-justify-end tw-gap-x-4 md:tw-justify-between md:tw-mr-4">
                  <SearchInput
                    backgroundColor={'transparent'}
                    name="searchProfileValue"
                    value={searchInternalUserValue}
                    onChange={(e: any) => handleOnchangeInternalUser(e.target.value)}
                    placeholder="Search by name"
                  />
                  <FilterLabel
                    title="Filter Options"
                    icon={<icons.BsFilter />}
                    onClick={() => setShowFilterOptions(true)}>
                    {/* <span className='tw-absolute tw-hidden'>here</span> */}
                    <FilterCard
                      showFilter={showFilterOptions}
                      title="Filter Options"
                      onClick={() => handleSubmitInternalUserFilter()}
                      extraClass="tw-w-[13rem]">
                      <div className="tw-mt-4 tw-h-[14rem] tw-overflow-y-scroll tw-overflow-hidden">
                        <ZojaSelect
                          options={[
                            { value: '', label: 'Status' },
                            { value: 'active', label: 'active' },
                            { value: 'inactive', label: 'inactive' },
                          ]}
                          name="status"
                          showArrown={true}
                          setSelectedOption={selected => setFilterOptions({ ...filterOptions, status: selected })}
                          extraClass="tw-text-xs tw-p-3.5 tw-bg-white tw-border-0 tw-shadow-zojaShadowFive tw-rounded-md tw-font-normal tw-text-[#222B88] tw-mb-2"
                          arrowDownClass="tw-right-lg tw-top-3.5"
                        />

                        <FilterColapsible
                          title="Date Onboarded"
                          show={showDateOnboarded}
                          onClick={() => setShowDateOnboarded(!showDateOnboarded)}
                          extraClass="tw-shadow-none">
                          <>
                            <div className="">
                              {dateOnboarded.map((item: any) => (
                                <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                                  <span className="tw-text-[13px] tw-mt-4 tw-text-[#84919A]">{item.title}</span>
                                  <ZojaCheckbox
                                    isChecked={item.isChecked}
                                    onClick={() => handleSelectedOption(item, dateOnboarded, setDateOnboarded)}
                                  />
                                </div>
                              ))}
                            </div>
                            <div
                              className={`tw-mt-2 tw-overflow-hidden tw-max-h-0 ${
                                filterOptions.date_onboarded === 'custom' && 'tw-max-h-fit'
                              }`}>
                              <div className="tw-mb-2">
                                <CustomDatePicker
                                  label="State Date"
                                  value={new Date()}
                                  setValue={value =>
                                    setOnboardedDateRange({ ...onboardedDateRange, state_date: value })
                                  }
                                />
                              </div>
                              <div className="tw-mb-2">
                                <CustomDatePicker
                                  label="End Date"
                                  value={new Date()}
                                  setValue={value => setOnboardedDateRange({ ...onboardedDateRange, end_date: value })}
                                />
                              </div>
                            </div>
                          </>
                        </FilterColapsible>

                        <FilterColapsible
                          title="Last Seen"
                          show={showLastSeen}
                          onClick={() => setShowLastSeen(!showLastSeen)}
                          extraClass="tw-shadow-none">
                          <>
                            <div className="">
                              {lastSeen.map((item: any) => (
                                <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                                  <span className="tw-text-[13px] tw-mt-4 tw-text-[#84919A]">{item.title}</span>
                                  <ZojaCheckbox
                                    isChecked={item.isChecked}
                                    onClick={() => handleSelectedOption(item, lastSeen, setLastSeen)}
                                  />
                                </div>
                              ))}
                            </div>

                            <div
                              className={`tw-mt-2 tw-overflow-hidden tw-max-h-0 ${
                                filterOptions.last_seen === 'custom' && 'tw-max-h-fit'
                              }`}>
                              <div className="tw-mb-2">
                                <CustomDatePicker
                                  label="State Date"
                                  value={new Date()}
                                  setValue={value => setLastSeenDateRange({ ...lastSeenDateRange, state_date: value })}
                                />
                              </div>
                              <div className="tw-mb-2">
                                <CustomDatePicker
                                  label="End Date"
                                  value={new Date()}
                                  setValue={value => setLastSeenDateRange({ ...lastSeenDateRange, end_date: value })}
                                />
                              </div>
                            </div>
                          </>
                        </FilterColapsible>
                      </div>
                    </FilterCard>
                  </FilterLabel>

                  <FilterLabel
                    title="Sort By"
                    icon={<icons.TbArrowsSort color="#5E6366" />}
                    onClick={() => setShowSortBy(true)}>
                    <FilterCard
                      showFilter={showSortBy}
                      title="Sort by"
                      onClick={() => handleSubmitInternalUserFilter()}
                      submitButtonText="Apply"
                      extraClass="tw-w-[10rem] -tw-left-28">
                      <>
                        <div className="tw-pt-2">
                          {sortBy.map((item: any) => (
                            <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                              <span className="tw-mt-4 tw-text-[13px] tw-text-[#84919A]">{item.title}</span>
                              <ZojaCheckbox
                                isChecked={item.isChecked}
                                onClick={() => handleSelectedOption(item, sortBy, setSortBy)}
                              />
                            </div>
                          ))}
                        </div>
                        <div className="tw-pt-2 tw-border-">
                          {orderBy.map((item: any) => (
                            <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                              <span className="tw-mt-4 tw-text-[13px] tw-text-[#84919A]">{item.title}</span>
                              <ZojaCheckbox
                                isChecked={item.isChecked}
                                onClick={() => handleSelectedOption(item, orderBy, setOrderBy)}
                              />
                            </div>
                          ))}
                        </div>
                      </>
                    </FilterCard>
                  </FilterLabel>

                  <ExportLabel
                    title="Export"
                    onClick={() => setShowExportOptions(true)}
                    loading={downloadInternalUserStatus === 'loading' ? true : false}>
                    <FilterCard
                      showFilter={showExportOptions}
                      title="Export as"
                      onClick={() => null}
                      showSubmitButton={false}
                      extraClass="tw-w-[10rem] -tw-left-28">
                      <div className="tw-flex tw-flex-col tw-mt-4">
                        <span
                          className="tw-text-xs tw-cursor-pointer tw-text-isGrey tw-border-b tw-border-b-[#f4f3f3] tw-pb-2 tw-mb-2"
                          onClick={() => handleExportInternalUserFile('pdf')}>
                          PDF
                        </span>
                        <span
                          className="tw-text-xs tw-cursor-pointer tw-text-isGrey"
                          onClick={() => handleExportInternalUserFile('csv')}>
                          CSV
                        </span>
                      </div>
                    </FilterCard>
                  </ExportLabel>
                </div>
              </InternalUserTop>
              {internalUsersStatus === 'loading' ? (
                <div className="tw-h-[50vh] tw-mx-auto tw-flex tw-justify-center tw-items-center">
                  <Oval
                    height="80"
                    width="80"
                    color="#222b88cf"
                    ariaLabel="tail-spin-loading"
                    secondaryColor="#222b882b"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              ) : (
                <TableContainer>
                  <InternaUsersTable
                    data={internalUsersDataList}
                    setSelectedItem={setSelectedInternalUserItem}
                    selectedItem={selectedInternalUserItem}
                    tableColumns={internalUsersDataHeader}
                    dropdownList={dropdownList}
                    sorting={handleSortItems}
                    setUserAccountStatus={setUserAccountStatus}
                    order={order}
                    handleMoreIconOptions={handleMoreIconOptions}
                  />
                  {internalUsersDataList.length >= 1 && (
                    <div className="tw-pb-2 tw-flex tw-flex-col tw-items-center tw-gap-3 md:tw-pb-8 md:tw-flex-row">
                      <Pagination
                        currentPage={currentPage}
                        totalPages={internalUserTotalPages}
                        onPageChange={selectedPage => {
                          setCurrentPage(selectedPage);
                        }}
                      />
                      <div className="-tw-mt-12 tw-flex tw-items-center tw-h-fit">
                        <span className="tw-text-xs tw-w-16">Per page</span>
                        <ZojaSelect
                          options={[
                            { label: '10', value: '10' },
                            { label: '20', value: '20' },
                            { label: '30', value: '30' },
                          ]}
                          value={internalUsersState?.data?.users?.meta?.per_page}
                          setSelectedOption={selected => handlePageNumberChange(selected, 'user')}
                          extraClass="tw-rounded-none tw-text-xs tw-py-2 tw-w-10"
                        />
                      </div>
                    </div>
                  )}
                </TableContainer>
              )}
            </InternalUsersContainer>
          )}
          {activePageTab === UserPageTabs?.[1]?.url && (
            <InternalUsersContainer>
              <InternalUserTop className="tw-flex tw-flex-col tw-items-center lg:tw-flex-row">
                <BorderedText
                  text="New Role"
                  icon={<AiOutlinePlus color={colors.white} size={15} />}
                  backgroundColor={colors.primary}
                  color={colors.white}
                  onClick={() => navigate(CREATEUSERROLES)}
                />
                <div className="tw-flex tw-items-center tw-justify-end tw-gap-x-4 md:tw-justify-between md:tw-mr-4">
                  <SearchInput
                    backgroundColor={'transparent'}
                    name="searchRoleValue"
                    value={searchUserRoleValue}
                    onChange={(e: any) => handleOnchangeRole(e.target.value)}
                    placeholder="Search record"
                  />
                  <FilterLabel
                    title="Filter Options"
                    icon={<icons.BsFilter />}
                    onClick={() => setShowFilterOptions(true)}>
                    {/* <span className='tw-absolute tw-hidden'>here</span> */}
                    <FilterCard
                      showFilter={showFilterOptions}
                      title="Filter Options"
                      onClick={() => handleSubmitRolePermissionFilter()}
                      extraClass="tw-w-[13rem]">
                      <div className="tw-mt-4 tw-h-[14rem] tw-overflow-y-scroll tw-overflow-hidden">
                        <ZojaSelect
                          options={[
                            { value: '', label: 'Role Creator' },
                            { value: 'super_admin_1', label: 'Super Admin 1' },
                            { value: 'manager_1', label: 'Manager 1' },
                            { value: 'manager_2', label: 'Manager 2' },
                          ]}
                          name="status"
                          showArrown={true}
                          setSelectedOption={selected => setFilterOptions({ ...filterOptions, role_creator: selected })}
                          extraClass="tw-text-xs tw-p-3.5 tw-bg-white tw-border-0 tw-shadow-zojaShadowFive tw-rounded-md tw-font-normal tw-text-[#222B88] tw-mb-2"
                          arrowDownClass="tw-right-lg tw-top-3.5"
                        />

                        <FilterColapsible
                          title="Date Range"
                          show={showDateOnboarded}
                          onClick={() => setShowDateOnboarded(!showDateOnboarded)}
                          extraClass="tw-shadow-none">
                          <>
                            <div className="">
                              {dateOnboarded.map((item: any) => (
                                <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                                  <span className="tw-text-[13px] tw-mt-4 tw-text-[#84919A]">{item.title}</span>
                                  <ZojaCheckbox
                                    isChecked={item.isChecked}
                                    onClick={() => handleSelectedOption(item, dateOnboarded, setDateOnboarded)}
                                  />
                                </div>
                              ))}
                            </div>
                            <div
                              className={`tw-mt-2 tw-overflow-hidden tw-max-h-0 ${
                                filterOptions.date_onboarded === 'custom' && 'tw-max-h-fit'
                              }`}>
                              <div className="tw-mb-2">
                                <CustomDatePicker
                                  label="State Date"
                                  value={new Date()}
                                  setValue={value =>
                                    setOnboardedDateRange({ ...onboardedDateRange, state_date: value })
                                  }
                                />
                              </div>
                              <div className="tw-mb-2">
                                <CustomDatePicker
                                  label="End Date"
                                  value={new Date()}
                                  setValue={value => setOnboardedDateRange({ ...onboardedDateRange, end_date: value })}
                                />
                              </div>
                            </div>
                          </>
                        </FilterColapsible>
                      </div>
                    </FilterCard>
                  </FilterLabel>

                  <FilterLabel
                    title="Sort By"
                    icon={<icons.TbArrowsSort color="#5E6366" />}
                    onClick={() => setShowSortBy(true)}>
                    <FilterCard
                      showFilter={showSortBy}
                      title="Sort by"
                      onClick={() => handleSubmitRolePermissionFilter()}
                      submitButtonText="Apply"
                      extraClass="tw-w-[10rem] -tw-left-28">
                      <>
                        <div className="tw-pt-2">
                          {sortBy.map((item: any) => (
                            <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                              <span className="tw-mt-4 tw-text-[13px] tw-text-[#84919A]">{item.title}</span>
                              <ZojaCheckbox
                                isChecked={item.isChecked}
                                onClick={() => handleSelectedOption(item, sortBy, setSortBy)}
                              />
                            </div>
                          ))}
                        </div>
                        <div className="tw-pt-2 tw-border-">
                          {orderBy.map((item: any) => (
                            <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                              <span className="tw-mt-4 tw-text-[13px] tw-text-[#84919A]">{item.title}</span>
                              <ZojaCheckbox
                                isChecked={item.isChecked}
                                onClick={() => handleSelectedOption(item, orderBy, setOrderBy)}
                              />
                            </div>
                          ))}
                        </div>
                      </>
                    </FilterCard>
                  </FilterLabel>

                  <ExportLabel
                    title="Export"
                    onClick={() => setShowExportOptions(true)}
                    loading={downloadRolePermissionStatus === 'loading' ? true : false}>
                    <FilterCard
                      showFilter={showExportOptions}
                      title="Export as"
                      onClick={() => null}
                      showSubmitButton={false}
                      extraClass="tw-w-[10rem] -tw-left-28">
                      <div className="tw-flex tw-flex-col tw-mt-4">
                        <span
                          className="tw-text-xs tw-cursor-pointer tw-text-isGrey tw-border-b tw-border-b-[#f4f3f3] tw-pb-2 tw-mb-2"
                          onClick={() => handleExportRolePermissionFile('pdf')}>
                          PDF
                        </span>
                        <span
                          className="tw-text-xs tw-cursor-pointer tw-text-isGrey"
                          onClick={() => handleExportRolePermissionFile('csv')}>
                          CSV
                        </span>
                      </div>
                    </FilterCard>
                  </ExportLabel>
                </div>
              </InternalUserTop>
              {rolesStatus === 'loading' ? (
                <div className="tw-h-[50vh] tw-mx-auto tw-flex tw-justify-center tw-items-center">
                  <Oval
                    height="80"
                    width="80"
                    color="#222b88cf"
                    ariaLabel="tail-spin-loading"
                    secondaryColor="#222b882b"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              ) : (
                <>
                  <RolesAndPermissionTable
                    data={userRolesDataList}
                    onClick={(item: Dictionary) => handleRoleModalOpen(item)}
                    setSelectedItem={setSelectedRoleItem}
                    selectedItem={selectedInternalUserItem}
                    tableColumns={rolesAndPermissionDataHeader}
                    dropdownList={dropdownList}
                    sorting={handleSortItems}
                    order={order}
                    handleMoreIconOptions={handleRoleMoreIconOptions}
                  />
                  {userRolesDataList.length >= 1 && (
                    <div className="tw-pb-2 tw-flex tw-flex-col tw-items-center tw-gap-3 md:tw-pb-8 md:tw-flex-row">
                      <Pagination
                        currentPage={currentPage}
                        totalPages={rolePermissionTotalPages}
                        onPageChange={selectedPage => {
                          setCurrentPage(selectedPage);
                        }}
                      />
                      <div className="-tw-mt-12 tw-flex tw-items-center tw-h-fit">
                        <span className="tw-text-xs tw-w-16">Per page</span>
                        <ZojaSelect
                          options={[
                            { label: '10', value: '10' },
                            { label: '20', value: '20' },
                            { label: '30', value: '30' },
                          ]}
                          value={rolesState?.data?.roles?.meta?.per_page}
                          setSelectedOption={selected => handlePageNumberChange(selected, 'role')}
                          extraClass="tw-rounded-none tw-text-xs tw-py-2 tw-w-10"
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </InternalUsersContainer>
          )}

          <MoreIconView
            setSelectedText={setSelectedTransactionActionText}
            isModalVisible={moreIconIsVisible}
            closeModal={() => setMoreIconIsVisible(false)}
            options={moreIconOption}
            onClick={item => handleMoreIconOptions(item)}
          />
          <MoreIconView
            setSelectedText={setSelectedRoleActionText}
            isModalVisible={roleMoreIconIsVisible}
            closeModal={() => setRoleMoreIconIsVisible(false)}
            options={roleMoreIconOption}
            onClick={item => handleRoleMoreIconOptions(item)}
          />

          <CreateInternalUserModal
            isModalVisible={createInternalUserIsModalVisible}
            editing={false}
            closeModal={() => {
              setCreateInternalUserIsModalVisible(false);
              setOpenPermissions(false);
            }}
            title="Add New User"
            setUserRole={setUserRole}
            openPermissions={openPermissions}
            setOpenPermissions={setOpenPermissions}
            permissionDefault={permissionData}
            permissions={permissionData}
            setPermissions={setPermissionData}
            setCurrentUserData={setCurrentUserData}
            isLoading={rolesDropDownStatus === 'loading'}
            isSubmittingInternalUser={createInternalUserStatus === 'loading'}
            isSuccessInternaluser={createInternalUserStatus === 'succeeded'}
            onSubmit={(item: Dictionary) => handleCreateInternalUserModalBtn(item)}
            roleOption={rolesData}
            actionBtnText={'Create User'}
            defaultValues={{ defaultEmail: '', defaultFirstName: '', defaultLastName: '', defaultRole: '' }}
          />

          {/* edit internal user modal */}
          <CreateInternalUserModal
            isModalVisible={editInternalUserIsModalVisible}
            editing={editInternalUserIsModalVisible}
            closeModal={() => {
              setEditInternalUserIsModalVisible(false);
              setOpenPermissions(false);
            }}
            title="Edit User Details"
            setUserRole={setUserRole}
            permissionDefault={permissionData}
            permissions={permissionData}
            setPermissions={setPermissionData}
            setCurrentUserData={setCurrentUserData}
            openPermissions={openPermissions}
            rolesAndPermissions={rolesState?.data?.roles?.data}
            setOpenPermissions={setOpenPermissions}
            isSuccessInternaluser={updateInternalUserStatus === 'succeeded'}
            isLoading={rolesDropDownStatus === 'loading'}
            isSubmittingInternalUser={updateInternalUserStatus === 'loading'}
            onSubmit={(item: Dictionary) => handleEditInternalUserModalBtn(item)}
            roleOption={rolesData}
            actionBtnText={'Update'}
            defaultValues={{
              defaultEmail: selectedInternalUserItem.hasOwnProperty('email') ? selectedInternalUserItem.email : '',
              defaultFirstName: selectedInternalUserItem.hasOwnProperty('name')
                ? getWordFromString(selectedInternalUserItem.name, 1)
                : '',
              defaultLastName: selectedInternalUserItem.hasOwnProperty('name')
                ? getWordFromString(selectedInternalUserItem.name, 2)
                : '',
              defaultRole: selectedInternalUserItem.hasOwnProperty('role') ? selectedInternalUserItem.role : '',
              defaultPermissions: selectedInternalUserItem.hasOwnProperty('permissions')
                ? selectedInternalUserItem.permissions
                : '',
            }}
          />

          <ActivityActionModal
            isModalVisible={createUserSuccessModalVisible}
            closeModal={handleCloseCreateInternalUserModal}
            title={
              updateInternalUserStatus === 'succeeded'
                ? 'User Details Successfully Updated'
                : 'User Successfully Created'
            }
            text={updateInternalUserStatus === 'succeeded' ? '' : 'An Onboarding mail has been sent to the user'}
            actionText="Close"
            image={updateInternalUserStatus === 'succeeded' ? images.check : images.sent}
            actionClick={handleCloseCreateInternalUserModal}
            isLoading={false}
          />

          {/* reset password success modal */}
          <ActivityActionModal
            isModalVisible={resetPasswordSuccessModalVisible}
            closeModal={handleCloseResetPasswordModal}
            title={''}
            text={'Password Reset link has been sent to the User'}
            actionText="Close"
            image={images.sent}
            actionClick={handleCloseResetPasswordModal}
            isLoading={false}
          />

          {/* activate or deactive user */}
          <ProfileActivationToggleModal
            isModalVisible={profileActivationIsModalVisible}
            activityStatus={userAccountStatus}
            actionClicked={handleUserProfileActivity}
            closeModal={() => setProfileActivationIsModalVisible(false)}
            setDeactiveMessage={setDeactiveMessage}
          />

          {/* this modal shows when admin successfully activate or deactivate a user */}
          <ActivityActionModal
            isModalVisible={profileActivationSuccessIsModalVisible}
            closeModal={handleProfileActivationSuccessClose}
            actionClick={handleProfileActivationSuccessClose}
            image={images.check}
            isLoading={false}
            actionText="Close"
            title=""
            text={
              userAccountStatus === 'active'
                ? 'Profile has been successfuly deactivated'
                : 'Profile has been successfuly reactivated'
            }
          />

          {/* this modal shows login history */}
          <LoginHistoryOnlyModal
            actionClick={() => {}}
            closeModal={() => setLoginHistoryIsModalVisible(false)}
            isModalVisible={loginHistoryIsModalVisible}
            title="Login History"
            data={loginHistoryData}
            headerData1={loginHistoryDataHeader}
            isLoading={loginHistoryStatus === 'loading'}
          />

          {/* this modal is to delete user */}

          <ActivityActionModal
            actionClick={handleDeleteRole}
            closeModal={() => {
              setDeleteRoleIsModalVisible(false);
              dispatch(deleteRoleReset());
            }}
            actionText="Delete"
            secondaryActionText="Cancel"
            isModalVisible={deleteRoleIsModalVisible}
            text={'Are you sure you want to delete this role?'}
            image={images.reactivateUser}
            isLoading={deleteRoleStatus === 'loading'}
            actionBtnBackgroundColor={colors.red}
          />

          <ActivityActionModal
            actionClick={handleSuccessDeleteRole}
            closeModal={handleSuccessDeleteRole}
            actionText="Close"
            isModalVisible={deleteRoleSuccessIsModalVisible}
            text={'You have successfully deleted the Role'}
            image={images.check}
            isLoading={false}
          />
        </UserContainer>
      </>
    </AppContainer>
  );
}

export default Users;
