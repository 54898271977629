import { TableTag, TableContainer } from './style';
import { TD, TR } from '../../tailwind/table/tableElements';
import { MoreIcon, ZojaTable } from '../..';
import { capitalizeFirstLetter } from '../../../utils';
import { ReactComponent as EmptySearchIcon } from '../../../assets/svg/emptySearch.svg';
import { Dictionary } from '../../../types';
import { TDMORE } from '../../tailwind/table/tableElementMoreView';
import moment from 'moment';

export interface RolesAndPermissionTableIPropsIProps {
  id?: number;
  title: string;
  permissionCount: string | number;
  userCount: string | number;
  createdBy: string;
  roleId?: string;
}

export interface RolesAndPermissionTableIProps {
  data: RolesAndPermissionTableIPropsIProps[];
  setSelectedItem?: any;
  backgroundColor?: string;
  header?: boolean;
  onClick: (item: Dictionary) => any;
  sorting?: any;
  order?: string;
  tableColumns?: any;
  dropdownList?: any;
  setSelectedTableItem?: any;
  handleMoreIconOptions: (item: any) => void;
  selectedItem: any;
}

const dropdownList = [
  {
    id: 1,
    title: 'Role Details',
    value: 'roleDetails',
  },
  {
    id: 2,
    title: 'Delete Role',
    value: 'deleteRole',
  },
];

function RolesAndPermissionTable({
  data,
  setSelectedItem,
  onClick,
  sorting,
  order,
  tableColumns,
  handleMoreIconOptions,
  selectedItem,
}: RolesAndPermissionTableIProps) {
  const handleOnSelect = (item: any) => {
    setSelectedItem(item);
  };

  return (
    <div>
      {/* {data?.length >= 1 ? (
        <TableContainer>
          <TableTag>
            <thead>
              <tr>
                <TH></TH>
                <TH>{headerData.title}</TH>
                <TH>{headerData.permissionCount}</TH>
                <TH>{headerData.userCount}</TH>
                <TH>{headerData.createdBy}</TH>
              </tr>
            </thead>

            <tbody>
              {data?.map((item: Dictionary, index: number) => (
                <TR key={index}>
                  <TD>{}</TD>
                  <TD>{capitalizeFirstLetter(item.title)}</TD>
                  <TD>{item.permissionCount}</TD>
                  <TD>{item.userCount}</TD>
                  <TD>{item.createdBy}</TD>
                  <TD>
                    <MoreIcon onClick={() => onClick(item)} />
                  </TD>
                </TR>
              ))}
            </tbody>
          </TableTag>
        </TableContainer>
      ) : (
        <div style={emptyListCenterStyle}>
          <EmptySearchIcon />
        </div>
      )} */}

      <ZojaTable column={tableColumns} sorting={sorting} order={order}>
        {data?.map((item: Dictionary, idx: number) => (
          <TR key={item.id}>
            <TD value={idx + 1} />
            <TD value={capitalizeFirstLetter(item.title)} />
            <TD value={item.permissionCount} />
            <TD value={item.userCount} />
            <TD value={item.createdBy} />
            <TD value={moment(item.dateCreated).format('DD/MM/YYYY - HH:mm a')} />
            <TD>
              <div onClick={() => handleOnSelect(item)}>
                <TDMORE
                  dropdownList={dropdownList}
                  onActionClick={item => handleMoreIconOptions(item)}
                  setSelectedItem={setSelectedItem}
                />
              </div>
            </TD>
          </TR>
        ))}
      </ZojaTable>
    </div>
  );
}

export default RolesAndPermissionTable;
