import React from 'react';
import ZojaCheckbox from '../checkbox';
import { handleSelectedOption, toggleItemChecked } from '../../utils';

interface PermissionsCheckProps {
  select: any;
  setSelected: any;
  permissionList: any[];
  title?: string;
  className?: string;
  hasTitleBorder?: boolean;
  itemName?: string;
}

const PermissionsCheck = ({
  select,
  setSelected,
  permissionList,
  hasTitleBorder = false,
  className,
  itemName,
  title,
}: PermissionsCheckProps) => {
  // console.log(permissionList, select);
  return (
    <div>
      <div className={`${hasTitleBorder && 'tw-shadow'} tw-py-3 tw-px-5 tw-rounded ${className}`}>
        <h4 className={`text-[#263238] tw-text-sm tw-font-normal ${hasTitleBorder ? 'tw-mb-0' : 'tw-mb-6'}`}>
          {title}
        </h4>
        {hasTitleBorder && <hr />}
        <div className="tw-flex tw-flex-wrap tw-gap-x-4 tw-gap-y-6">
          {permissionList?.map((item: any) => (
            <div className="tw-flex tw-items-center tw-gap-x-3" key={item.id}>
              <ZojaCheckbox
                isChecked={item.isChecked}
                className="tw-mb-4"
                onClick={() => toggleItemChecked(item.id, select, itemName, setSelected)}
              />
              <p className=" tw-m-0 tw-text-[#758089]">{item.title}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PermissionsCheck;
