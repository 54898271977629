export const userDataHeader = {
  id: '',
  name: 'Name',
  userId: 'User ID',
  walletNo: 'Account No',
  phone: 'Phone No',
  lastSeen: 'Last Seen',
  subAgents: 'Sub Agents',
  email: 'Email',
};
