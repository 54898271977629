import { TableTag, TH, TableContainer } from './style';
import { TD, TR } from '../../tailwind/table/tableElements';

import { MoreIcon, ZojaTable } from '../..';
import { colors, currencyFormat, dateFormat, capitalizeFirstLetter } from '../../../utils';
import { ReactComponent as EmptySearchIcon } from '../../../assets/svg/emptySearch.svg';
import { Dictionary } from '../../../types';
import { TDMORE } from '../../tailwind/table/tableElementMoreView';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';

export interface InternalUsersTableIPropsIProps {
  id: number;
  name: string;
  email: string;
  role: string;
  status: boolean;
  lastSeen: string;
  dateEnrolled: string;
}

export interface InternalUsersTableIProps {
  data: InternalUsersTableIPropsIProps[];
  setSelectedItem?: any;
  backgroundColor?: string;
  header?: boolean;
  headerData?: any;
  onClick: (item: Dictionary) => any;
}

const dropdownList = [
  {
    id: 1,
    title: 'Edit',
    value: 'edit',
  },
  {
    id: 2,
    title: 'Deactivate',
    value: 'deactivate',
  },
  {
    id: 3,
    title: 'Reactivate',
    value: 'reactivate',
  },
  {
    id: 4,
    title: 'Reset Password',
    value: 'resetPassword',
  },
  {
    id: 5,
    title: 'View Login History',
    value: 'viewLoginHistory',
  },
];

function InternaUsersTable({
  data,
  setSelectedItem,
  sorting,
  order,
  tableColumns,
  setOpenMore,
  handleMoreIconOptions,
  selectedItem,
}: any) {
  const [currentDropdownList, setCurrentDropdownList] = useState<any>([]);

  const handleOnSelect = useCallback(
    (item: any) => {
      setSelectedItem(item);
    },
    [setSelectedItem],
  );

  useEffect(() => {
    if (selectedItem) {
      let newState = [...dropdownList];
      if (selectedItem?.status === 'active') {
        const filteredList = newState.filter(f => f.id !== 3);
        setCurrentDropdownList(filteredList);
      }
      if (selectedItem?.status === 'inactive') {
        const filteredList = newState.filter(f => f.id !== 2);
        setCurrentDropdownList(filteredList);
      }
    }
  }, [selectedItem, handleOnSelect]);

  return (
    <div>
      <ZojaTable column={tableColumns} sorting={sorting} order={order}>
        {data?.map((item: Dictionary, idx: number) => (
          <TR key={item.id}>
            <TD value={idx + 1} />
            <TD>{item.name}</TD>
            <TD>{item.email}</TD>
            <TD> {item.role}</TD>
            <TD
              extraClass={`${item.status === 'active' ? 'tw-text-green-500' : 'tw-text-red-500'}`}
              value={item.status === 'active' ? 'Active' : 'Inactive'}
            />
            <TD value={moment(item.lastSeen).format('DD/MM/YYYY - HH:mm a')} />
            <TD value={moment(item.dateEnrolled).format('DD/MM/YYYY - HH:mm a')} />
            <TD>
              <div onClick={() => handleOnSelect(item)}>
                <TDMORE
                  handleClose={() => setOpenMore(false)}
                  dropdownList={currentDropdownList}
                  onActionClick={item => handleMoreIconOptions(item)}
                  setSelectedItem={setSelectedItem}
                />
              </div>
            </TD>
          </TR>
        ))}
      </ZojaTable>
    </div>
  );
}

export default InternaUsersTable;
