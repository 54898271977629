import React, { ReactElement } from 'react'

interface InputProps {
    id?: string,
    icon?: ReactElement,
    label?: string,
    value?: string,
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    placeholder?: string,
    type?: string,
    extraClass?: string,
    labelExtraClass?: string
    style?: React.CSSProperties,
    name?: string,
    marginTop?: string,
    onBlur?: (e: any) => void,
    error?: any,
    touched?: any,
    iconPosition?: 'left' | 'right',
    iconColor?: string
}

const ZojaInput = ({ id, name = '', icon, marginTop, label, value, onChange, onBlur, error, touched = {}, placeholder, type = 'text', extraClass, iconPosition, iconColor, labelExtraClass }: InputProps) => {
    return (
        <div className={`${marginTop}`}>
            {label && <label htmlFor={name} className={`${labelExtraClass} tw-text-[12px] tw-mb-1 tw-block`}>{label}</label>}
            <div className='relative'>
                <input
                    id={id}
                    name={name}
                    value={value}
                    onChange={onChange}
                    type={type}
                    onBlur={onBlur}
                    className={`${extraClass} tw-border tw-border-isGreyVariantTwo tw-w-full tw-block tw-bg-[#FFFFFF08] tw-p-1.5 tw-px-5 tw-rounded-[4px] tw-placeholder:text-xs tw-focus:outline-none tw-placeholder:text-[#FFFFFF40] tw-appearance-none ${touched[name] && error ? 'tw-border-red-400' : ''}`} placeholder={placeholder} />
                {touched[name] && error && <span className='tw-text-xs tw-text-red-400'>{error}</span>}
                {icon && <span className={`tw-text-${iconColor} tw-absolute tw-top-3 ${iconPosition === 'left' ? 'tw-left-2.5': 'tw-right-2.5'}`}> {icon} </span>}
            </div>
        </div>
    )
}

export default ZojaInput