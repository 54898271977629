import { ChevronLeftSquare, ChevronRight, MoveLeft } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { AppContainer } from "../../atoms";
import { cn } from "../../lib/utils";
import { useAppSelector } from "../../redux/redux-hooks";
import { routesPath } from "../../utils";
import useClickOutside from "../../utils/hooks/useClickOutside";
import BulkCreditCustomer from "./menu/bulkCustomerPayment";

const MENU_ITEMS = [
    {
        id: 1,
        title: "Credit Customer",
        selected: false
    },
    {
        id: 2,
        title: "Debit Customer",
        selected: false
    },
    {
        id: 3,
        title: "Bulk Credit Customer",
        selected: true
    },
    {
        id: 4,
        title: "Bulk Debit Customer",
        selected: false
    },
]

type ACTIVE_MENU_TYPE = 'Credit Customer' | 'Debit Customer' | 'Bulk Credit Customer' | 'Bulk Debit Customer'


export default function PaymentPage() {
    const [showOptions, setShowOptions] = useState(false)
    const [menuItem, setMenuItem] = useState(MENU_ITEMS)
    const [activeMmenuItem, setActiveMenuItem] = useState<ACTIVE_MENU_TYPE>('Bulk Credit Customer')
    const { data: dashboard_data, status } = useAppSelector(state => state.dashboard);
    const {
        data: { name },
    } = useAppSelector(state => state.auth);
    const optionToggleRef = useRef(null)

    const { DASHBOARD } = routesPath
    const { isClickOutside } = useClickOutside(optionToggleRef)

    const handleMenuChange = (id: number) => {
        setMenuItem(prevItems => prevItems.map(item => ({
            ...item,
            selected: item.id === id
        })));
    }
    

    useEffect(() => {
        if (isClickOutside && showOptions) {
            setShowOptions(false)
        }
    }, [isClickOutside])

    return (
        <>
            <AppContainer
                navTitle={
                    <div className="tw-flex tw-gap- tw-items-center">
                        <div className="tw-flex tw-gap-2 tw-items-center">
                            <Link to={DASHBOARD} className="tw-flex tw-gap-1 tw-text-[#5E6366] tw-no-underline tw-text-[1rem] tw-font-normal hover:tw-text-gray-500">
                                <span className="tw-hidden md:tw-block"> <MoveLeft /> </span>
                                <span className="tw-text-[#5E6366] tw-no-underline tw-text-[1rem] tw-font-normal">DASHBOARD</span>
                            </Link>
                            <span className="tw-border-gray-600 tw-font-bold tw-block tw-border tw-h-6"></span>
                        </div>
                        <span className="tw-ml-3 tw-text-[#5E6366] tw-text-[1rem] tw-font-medium">PAYMENTS</span>
                    </div>
                }>
                <>
                    <section className={cn(
                        " tw-py-2 tw-items-center tw-relative lg:tw-flex lg:tw-items-start",
                        showOptions && ""
                    )}>
                        <div className={cn(
                            "tw-fixed -tw-top-2 -tw-left-2 tw-h-screen tw-w-[12rem] md:-tw-left-8 lg:-tw-left-6 lg:tw-w-[16rem] lg:tw-border-r tw-bg-white tw-shadow-zojaShadowOn tw-z-10 tw-transform -tw-translate-x-[130%] tw-ease-in-out tw-duration-300 lg:tw-translate-x-0 lg:tw-relative lg:tw-pt-6",
                            showOptions && "tw-translate-x-0 tw-relative"
                        )}>
                            <span className="tw-font-normal tw-border-b tw-border-gray-100 tw-py-4 tw-px-4 tw-block tw-text-sm lg:tw-pt-0 lg:tw-text-[1rem]">Payment Options</span>
                            {showOptions && <span className="tw-absolute tw-top-2 -tw-right-2 tw-cursor-pointer tw-text-gray-500 lg:tw-hidden"
                                onClick={() => setShowOptions(false)}
                            ><ChevronLeftSquare /></span>}
                            <div className="tw-p-4 tw-px-6 tw-flex tw-flex-col tw-gap-4">
                                {
                                    menuItem.map(({ id, title, selected }: any) => (
                                        <div key={id} className={cn(
                                            "tw-border-b tw-pb-4 tw-cursor-pointer tw-flex tw-gap-2 tw-items-center",
                                            selected && "tw-text-isPrimary tw-font-normal"
                                        )}
                                        // onClick={() => {
                                        //     handleMenuChange(id)
                                        //     setActiveMenuItem(title)
                                        // }}
                                        >
                                            <span className="tw-text-xs lg:tw-text-[.8rem]">{title}</span>
                                            <ChevronRight width={15} height={15} />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <span className="tw-block tw-w-fit tw-p-[.1rem] tw-mt-2 tw-cursor-pointer lg:tw-hidden"
                            onClick={() => setShowOptions(!showOptions)}
                        // ref={optionToggleRef}
                        > <ChevronLeftSquare className="tw-text-gray-500" /></span>
                        <div className="tw-absolute tw-top-8 tw-py-4 tw-p-4 lg:tw-p-0 lg:tw-px-4 tw-w-full tw-h-screen tw-overflow-auto lg:tw-relative lg:tw-top-0">
                            {
                                activeMmenuItem === 'Bulk Credit Customer' && 
                                <BulkCreditCustomer/>
                            }
                        </div>
                    </section>
                </>
            </AppContainer>
        </>
    )
}
