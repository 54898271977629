
interface IProps {
    title: string,
    value: string,
    valueClass?: string
}

const LabelOne = ({ title, value, valueClass }: IProps) => {

    return (
            <div className="tw-flex tw-gap-x-3 tw-text-sm">
                <span>{title}</span>
                <span className={`tw-font-normal tw-text-left ${valueClass}`}>{value}</span>
            </div>
    )
}

export default LabelOne