import React, { useEffect, useState } from 'react';
import { AppContainer, InfoLabel } from '../../atoms';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { dateFormat, images, routesPath } from '../../utils';
import { CompareDocumentModal, PreviewImageModal, VerificationDocumentCard, ZojalActionModal } from '../../components';
import { getKycCustomerRequest, kycVerificationRequest, kycVerificationReset } from '../../redux/slice';
import { Dictionary } from '../../types';
import { Oval } from 'react-loader-spinner';
import { FaCheck } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { ZojaButton, ZojaSelect } from '../../components/tailwind';

const TEST_IMAGE_1 =
  'https://images.pexels.com/photos/17718086/pexels-photo-17718086/free-photo-of-a-man-sitting-on-a-chair-with-a-sunflower.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2';
const TEST_IMAGE_2 =
  'https://images.pexels.com/photos/17719366/pexels-photo-17719366/free-photo-of-portrait-of-woman-on-street.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2';

const verifiedUsers: string = 'approved';
const pendingUsers: string = 'pending';

const rejectionReason = [
  {
    value: '',
    label: 'Select...',
  },
  {
    value: 'Blurry Image',
    label: 'Blurry Image',
  },
  {
    value: 'Selfie is different from BVN Image',
    label: 'Selfie is different from BVN Image',
  },
  {
    value: 'Poor Background lightening',
    label: 'Poor Background lightening',
  },
  {
    value: 'Image not fully captured',
    label: 'Image not fully captured',
  },
  {
    value: 'Invalid Image',
    label: 'Invalid Image',
  },
];

const KycCustomerInformation = () => {
  const [mediaData, setMediaData] = useState<Dictionary>({});
  const [verificationData, setVerificationData] = useState<Dictionary>({
    bvn_selfie_verification: {},
    identity_card_verification: {},
    cac_document_verification: {},
    address_verification: {},
  });
  const [selectedVerificationType, setSelectedVerificationType] = useState('');
  const [userVerificationId, setUserVerificationId] = useState('');
  const [customerData, setCustomerData] = useState<Dictionary>({});
  const [approveRejectModal, setApproveRejectModal] = useState({ show: false, type: '' });
  const [showRejectionReasonModal, setShowRejectionReasonModal] = useState(false);
  const [selectedRejectionReason, setSelectedRejectionReason] = useState('');
  const [showPreviewImageModal, setShowPreviewImageModal] = useState(false);
  const [showCompareDocumentModal, setShowCompareDocumentModal] = useState(false);
  const [compareDocumentData, setCompareDocumentData] = useState({
    basic_info: {},
    left_doc_url: '',
    right_doc_url: '',
  });
  const [isSuccessModalVisible, setIsSuccesssModalVisible] = useState(false);
  const [imageToPreview, setImageToPreview] = useState('');
  const [actionType, setActionType] = useState('');

  // redux state
  const kycCustomerState = useAppSelector(state => state.getKycCustomer);
  const { status: kycCustomerStatus } = kycCustomerState;

  const kycVerificationState = useAppSelector(state => state.kycVerification);
  const { status: kycVerificationStatus } = kycVerificationState;

  const kycLevelOneStatic: string = 'LEVEL 1';
  const kycLevelTwoStatic: string = 'LEVEL 2';
  const kycLevelThreeStatic: string = 'LEVEL 3';
  const kycLevelAgencyStatic: string = 'Agency';

  const kycVerificationbvn: string = 'bvn-selfie-verification';
  const kycVerificationidentityCard: string = 'identity-card-verification';
  const kycVerificationCACDocumentVerification: string = 'cac document verification';
  const kycVerificationBusinessAddressVerification: string = 'address-verification';

  const getKycVerificationIdFromVerificationList = (list: any[], kycLevelState: string) => {
    let toFilterBy =
      kycLevelState === kycLevelOneStatic
        ? kycVerificationbvn
        : kycLevelState === kycLevelTwoStatic
        ? kycVerificationidentityCard
        : kycLevelState === kycLevelThreeStatic
        ? kycVerificationBusinessAddressVerification
        : kycVerificationCACDocumentVerification;
    const result = list.filter(el => el?.verification_type === toFilterBy);

    return result.length >= 1 ? result[0].id : '';
  };

  const { KYC } = routesPath;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let { id } = useParams();

  let {
    state: { kycLvl, verificationType },
  } = useLocation();

  useEffect(() => {
    dispatch(
      getKycCustomerRequest({
        id: id,
      }),
    );
  }, []);

  useEffect(() => {
    if (kycCustomerStatus === 'succeeded') {
      const userVerificationId = getKycVerificationIdFromVerificationList(
        kycCustomerState?.data?.user?.verifications,
        kycLvl,
      );

      setUserVerificationId(userVerificationId);

      setCustomerData(kycCustomerState?.data?.user);
      let result: any;
      if (kycLvl === kycLevelOneStatic && verificationType === pendingUsers) {
        // result = {
        //     verifyingImageTitle: 'BVN Photo',
        //     approvedMedia: [],
        //     approvedMediaId: [],
        //     verifyingMedia: [
        //         {
        //             id: 1,
        //             img: kycCustomerState?.data?.media?.bvn_photo,
        //             label: 'BVN Picture',
        //             imgAlt: 'BVN Picture',
        //         },

        //         {
        //             id: 2,
        //             img: kycCustomerState?.data?.media?.selfie_photos.bvn,
        //             label: 'Uploaded Selfie',
        //             imgAlt: 'Uploaded Selfie',
        //         },
        //     ],
        // };
        result = {
          bvn_img_url: kycCustomerState?.data?.media?.bvn_photo,
          selfie_img_url: kycCustomerState?.data?.media?.selfie_photos?.bvn,
          idCard_img_url: kycCustomerState?.data?.media?.identity_card,
          utilityBill_img_url: kycCustomerState?.data?.media?.utility_bill_document,
          cac_img_url: kycCustomerState?.data?.media?.cac_document,
        };
      }

      if (kycLvl === kycLevelTwoStatic && verificationType === pendingUsers) {
        // result = {
        //     verifyingImageTitle: 'Identity Card',
        //     approvedMedia: [
        //         {
        //             id: 1,
        //             img: kycCustomerState?.data?.media?.bvn_photo,
        //             label: 'BVN Picture',
        //             imgAlt: 'BVN Picture',
        //             approved: true,
        //         },

        //         {
        //             id: 2,
        //             img: kycCustomerState?.data?.media?.selfie_photos?.bvn,
        //             label: 'Uploaded Selfie',
        //             imgAlt: 'Uploaded Selfie',
        //             approved: true,
        //         },
        //     ],
        //     approvedMediaId: [],
        //     verifyingMedia: [
        //         {
        //             id: 1,
        //             img:
        //                 kycCustomerState?.data?.media?.identity_card !== null
        //                     ? kycCustomerState?.data?.media?.identity_card
        //                     : images.user,
        //             label: 'Identity Card',
        //             imgAlt: 'Identity Card',
        //         },
        //     ],
        // };
        result = {
          bvn_img_url: kycCustomerState?.data?.media?.bvn_photo,
          selfie_img_url: kycCustomerState?.data?.media?.selfie_photos?.bvn,
          idCard_img_url: kycCustomerState?.data?.media?.identity_card,
          utilityBill_img_url: kycCustomerState?.data?.media?.utility_bill_document,
          cac_img_url: kycCustomerState?.data?.media?.cac_document,
        };
      }

      if (kycLvl === kycLevelThreeStatic && verificationType === pendingUsers) {
        // result = {
        //     verifyingImageTitle: 'CAC Document',
        //     approvedMedia: [
        //         {
        //             id: 1,
        //             img: kycCustomerState?.data?.media?.bvn_photo,
        //             label: 'BVN Picture',
        //             imgAlt: 'BVN Picture',
        //             approved: true,
        //         },

        //         {
        //             id: 2,
        //             img: kycCustomerState?.data?.media?.selfie_photos?.bvn,
        //             label: 'Uploaded Selfie',
        //             imgAlt: 'Uploaded Selfie',
        //             approved: true,
        //         },
        //     ],
        //     approvedMediaId: [
        //         {
        //             id: 1,
        //             img: kycCustomerState?.data?.media?.identity_card,
        //             label: 'Identity Card',
        //             imgAlt: 'Identity Card',
        //             approved: true,
        //         },
        //     ],
        //     verifyingMedia: [
        //         {
        //             id: 1,
        //             img:
        //                 kycCustomerState?.data?.media?.cac_document !== null
        //                     ? kycCustomerState?.data?.media?.cac_document
        //                     : images.user,
        //             label: 'CAC Document',
        //             imgAlt: 'CAC Document',
        //         },
        //     ],
        // };
        result = {
          bvn_img_url: kycCustomerState?.data?.media?.bvn_photo,
          selfie_img_url: kycCustomerState?.data?.media?.selfie_photos?.bvn,
          idCard_img_url: kycCustomerState?.data?.media?.identity_card,
          utilityBill_img_url: kycCustomerState?.data?.media?.utility_bill_document,
          cac_img_url: kycCustomerState?.data?.media?.cac_document,
        };
      }
      if (kycLvl === kycLevelAgencyStatic && verificationType === pendingUsers) {
        result = {
          bvn_img_url: kycCustomerState?.data?.media?.bvn_photo,
          selfie_img_url: kycCustomerState?.data?.media?.selfie_photos?.bvn,
          idCard_img_url: kycCustomerState?.data?.media?.identity_card,
          utilityBill_img_url: kycCustomerState?.data?.media?.utility_bill_document,
          cac_img_url: kycCustomerState?.data?.media?.cac_document,
        };
      }

      if (kycLvl === kycLevelOneStatic && verificationType === verifiedUsers) {
        result = {
          bvn_img_url: kycCustomerState?.data?.media?.bvn_photo,
          selfie_img_url: kycCustomerState?.data?.media?.selfie_photos?.bvn,
          idCard_img_url: kycCustomerState?.data?.media?.identity_card,
          utilityBill_img_url: kycCustomerState?.data?.media?.utility_bill_document,
          cac_img_url: kycCustomerState?.data?.media?.cac_document,
        };
      }

      if (kycLvl === kycLevelTwoStatic && verificationType === verifiedUsers) {
        result = {
          bvn_img_url: kycCustomerState?.data?.media?.bvn_photo,
          selfie_img_url: kycCustomerState?.data?.media?.selfie_photos?.bvn,
          idCard_img_url: kycCustomerState?.data?.media?.identity_card,
          utilityBill_img_url: kycCustomerState?.data?.media?.utility_bill_document,
          cac_img_url: kycCustomerState?.data?.media?.cac_document,
        };
      }

      if (kycLvl === kycLevelThreeStatic && verificationType === verifiedUsers) {
        result = {
          bvn_img_url: kycCustomerState?.data?.media?.bvn_photo,
          selfie_img_url: kycCustomerState?.data?.media?.selfie_photos?.bvn,
          idCard_img_url: kycCustomerState?.data?.media?.identity_card,
          utilityBill_img_url: kycCustomerState?.data?.media?.utility_bill_document,
          cac_img_url: kycCustomerState?.data?.media?.cac_document,
        };
      }
      if (kycLvl === kycLevelAgencyStatic && verificationType === verifiedUsers) {
        result = {
          bvn_img_url: kycCustomerState?.data?.media?.bvn_photo,
          selfie_img_url: kycCustomerState?.data?.media?.selfie_photos?.bvn,
          idCard_img_url: kycCustomerState?.data?.media?.identity_card,
          utilityBill_img_url: kycCustomerState?.data?.media?.utility_bill_document,
          cac_img_url: kycCustomerState?.data?.media?.cac_document,
        };
      }

      let verification_data = {
        bvn_selfie_verification: {},
        identity_card_verification: {},
        cac_document_verification: {},
        address_verification: {},
      };
      const verification = kycCustomerState?.data?.user?.verifications?.map((data: Dictionary) => {
        if (data.verification_type === kycVerificationbvn) {
          verification_data.bvn_selfie_verification = {
            id: data.id,
            verification_type: data.verification_type,
            status: data.status,
          };
        }
        if (data.verification_type === kycVerificationidentityCard) {
          verification_data.identity_card_verification = {
            id: data.id,
            verification_type: data.verification_type,
            status: data.status,
          };
        }
        if (data.verification_type === kycVerificationCACDocumentVerification) {
          verification_data.cac_document_verification = {
            id: data.id,
            verification_type: data.verification_type,
            status: data.status,
            address: data.address,
            lga: data.lga,
            state: data.state,
            business_registration_number: data.business_registration_number,
            business_name: data.business_name,
            business_address: data.business_address,
          };
        }
        if (data.verification_type === kycVerificationBusinessAddressVerification) {
          verification_data.address_verification = {
            id: data.id,
            verification_type: data.verification_type,
            status: data.status,
            address: data.address,
            lga: data.lga,
            state: data.state,
          };
        }
        return verification_data;
      });
      setVerificationData(verification_data);

      setMediaData(result);
    }
  }, [kycCustomerState]);

  // console.log(verificationData)

  const userDetails: any = {
    fullname: `${customerData?.bvn?.first_name} ${customerData?.bvn?.last_name}`,
    email: customerData?.email,
    phone_number: customerData?.telephone,
    bvn: customerData?.bvn !== null ? customerData?.bvn?.bvn_number : 'N/A',
    residential_address: customerData?.bvn?.residential_address,
    date_of_birth: customerData?.bvn?.date_of_birth !== null ? dateFormat(customerData?.bvn?.date_of_birth) : 'N/A',
    id_number: customerData?.kyc?.card_number !== null ? customerData?.kyc?.card_number : 'N/A',
  };

  useEffect(() => {
    dispatch(kycVerificationReset());
  }, []);

  useEffect(() => {
    if (kycVerificationStatus === 'succeeded') {
      setIsSuccesssModalVisible(true);
      setShowRejectionReasonModal(false);
      setApproveRejectModal({ ...approveRejectModal, show: false, type: '' });
      setSelectedVerificationType('');
    }
  }, [kycVerificationState]);

  // set document to compare data
  const handleCompareClick = () => {
    // basic_info: '', left_doc_url: '', right_doc_url: ''
    let LEFT_SIDE_DOC = '';
    let RIGHT_SIDE_DOC = '';
    let BASIC_INFO: Dictionary = { address: '', state: '', lga: '' };
    if (kycLvl === 'LEVEL 2') {
      LEFT_SIDE_DOC = mediaData?.idCard_img_url;
      RIGHT_SIDE_DOC = mediaData?.selfie_img_url;
    }
    if (kycLvl === 'LEVEL 3') {
      BASIC_INFO = {
        address: verificationData?.address_verification?.address,
        state: verificationData?.address_verification?.state,
        lga: verificationData?.address_verification?.lga,
      };
      RIGHT_SIDE_DOC = mediaData?.utilityBill_img_url;
    }
    if (kycLvl === 'Agency') {
      BASIC_INFO = {
        address: verificationData?.address_verification?.address,
        state: verificationData?.address_verification?.state,
        lga: verificationData?.address_verification?.lga,
        business_name: verificationData?.cac_document_verification?.business_name,
        business_address: verificationData?.cac_document_verification?.business_address,
        business_registration_number: verificationData?.cac_document_verification?.business_registration_number,
      };
      RIGHT_SIDE_DOC = mediaData?.cac_img_url;
    }
    setCompareDocumentData({
      ...compareDocumentData,
      left_doc_url: LEFT_SIDE_DOC,
      right_doc_url: RIGHT_SIDE_DOC,
      basic_info: { ...BASIC_INFO },
    });
    setShowCompareDocumentModal(true);
  };

  const handleApproveVerification = () => {
    setActionType('approve');
    // Level two verification require bvn_selfie and identity_card verification
    // Check if verification is for level 2
    let PAYLOAD_ID;
    if (selectedVerificationType === kycVerificationidentityCard && kycLvl === 'LEVEL 2') {
      PAYLOAD_ID = verificationData.identity_card_verification.id;
    }
    if (selectedVerificationType === kycVerificationbvn && kycLvl === 'LEVEL 2') {
      PAYLOAD_ID = verificationData.bvn_selfie_verification.id;
    } else {
      PAYLOAD_ID = userVerificationId;
    }
    dispatch(
      kycVerificationRequest({
        verificationId: PAYLOAD_ID,
        status: 'approved',
      }),
    );
  };

  const handleRejectVerification = () => {
    setActionType('reject');
    let PAYLOAD_ID;
    if (selectedVerificationType === kycVerificationidentityCard && kycLvl === 'LEVEL 2') {
      PAYLOAD_ID = verificationData.identity_card_verification.id;
    }
    if (selectedVerificationType === kycVerificationbvn && kycLvl === 'LEVEL 2') {
      PAYLOAD_ID = verificationData.bvn_selfie_verification.id;
    } else {
      PAYLOAD_ID = userVerificationId;
    }
    dispatch(
      kycVerificationRequest({
        verificationId: PAYLOAD_ID,
        status: 'rejected',
        comment: selectedRejectionReason,
      }),
    );
  };

  return (
    <AppContainer goBack={() => navigate(KYC)} navTitle={`KYC  |  ${kycLvl}`} navHelper="CUSTOMER`S DOC">
      <section className="tw-w-full tw-h-screen tw-p-4 lg:tw-overflow-hidden">
        {kycCustomerStatus === 'loading' && (
          <>
            <div className="tw-h-[70vh] tw-mx-auto tw-flex tw-justify-center tw-items-center">
              <Oval
                height="80"
                width="80"
                color="#222b88cf"
                ariaLabel="tail-spin-loading"
                secondaryColor="#222b882b"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          </>
        )}
        {kycCustomerStatus === 'failed' && (
          <section className="tw-h-[calc(110vh_-_50vh)] tw-bg-isSmokeWhite tw-flex tw-justify-center tw-items-center">
            <div className="tw-text-isRed tw-items-center tw-bg-white tw-shadow-sm tw-p-5 tw-w-[90%] tw-mx-auto md:tw-w-7/12">
              {/* <h6 className='tw-font-normal'>Error! </h6> */}
              <span className="tw-text-6xl tw-block tw-text-center tw-mt-4">🙁</span>
              <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-my-5 tw-mt-6">
                <span className="tw-text-gray-400 tw-block tw-mb-2 tw-text-sm md:tw-text-[15px]">
                  We encounted an error, click to reload page
                </span>
                <span
                  className="tw-text-white tw-bg-isPrimary tw-p-2 tw-px-4 tw-block tw-text-sm tw-w-fit tw-rounded-m tw-cursor-pointer"
                  onClick={() => window.location.reload()}>
                  Refresh Page
                </span>
              </div>
            </div>
          </section>
        )}
        {kycCustomerStatus === 'succeeded' && kycCustomerState?.data && (
          <section className="lg:tw-flex">
            {verificationType !== 'pending' ? (
              <>
                <aside className="tw-my-4 tw-border-b tw-pb-4 lg:tw-h-screen lg:tw-w-[calc(100%_-_70%)] lg:tw-border-r">
                  <h5 className="tw-text-[#323348]">System Information</h5>
                  <div className="tw-mt-6 tw-grid tw-grid-cols-2 tw-flex-wra tw-gap-6 md:tw-grid-cols-1 ">
                    <InfoLabel title="Full Name" text={userDetails?.fullname} />
                    <InfoLabel title="Email Address" text={userDetails?.email} />
                    <InfoLabel title="Phone Number" text={userDetails?.phone_number} />
                    <InfoLabel title="BVN" text={userDetails?.bvn} />
                    <InfoLabel title="Date of Birth" text={userDetails?.date_of_birth} />
                    {kycLvl !== 'LEVEL 1' && <InfoLabel title="ID Number" text={userDetails?.id_number} />}
                    {kycLvl === 'Agency' && (
                      <InfoLabel
                        title="Address"
                        text={verificationData?.cac_document_verification?.business_address}
                        textExtraclass="tw-w-1/2"
                      />
                    )}
                    {/* <InfoLabel title="Date Approved" text='Sept 19 2023' /> */}
                  </div>
                </aside>
                <aside className="tw-my-4 tw-h-screen tw-overflow-y-scroll tw-pb-72 tw-bg-blac lg:tw-pl-8 lg:tw-pb-40">
                  <div className="tw-flex tw-items-center tw-gap-x-8 lg:tw-gap-x-12">
                    <VerificationDocumentCard title="BVN Picture" imgUrl={mediaData?.bvn_img_url} />
                    <VerificationDocumentCard title="Uploaded Selfie" imgUrl={mediaData?.selfie_img_url} />
                  </div>
                  <div className="tw-mt-8 tw-flex tw-items-center tw-gap-x-8 lg:tw-gap-x-12">
                    {kycLvl !== 'LEVEL 1' && (
                      <VerificationDocumentCard title="National ID Card" imgUrl={mediaData?.idCard_img_url} />
                    )}
                  </div>
                  <div className="tw-mt-8 tw-flex tw-items-center tw-gap-x-8 lg:tw-gap-x-12">
                    {kycLvl === 'LEVEL 3' ||
                      (kycLvl === 'Agency' && (
                        <VerificationDocumentCard title="Utility Bill" imgUrl={mediaData?.utilityBill_img_url} />
                      ))}
                  </div>
                  <div className="tw-mt-8 tw-flex tw-items-center tw-gap-x-8 lg:tw-gap-x-12">
                    {kycLvl === 'Agency' && (
                      <VerificationDocumentCard title="Business Reg Doc " imgUrl={mediaData?.cac_img_url} />
                    )}
                  </div>
                </aside>
              </>
            ) : (
              <>
                <aside className="tw-my-4 tw-overflow-y-scroll tw-bg-blac lg:tw-h-screen lg:tw-w-[calc(100%_-_45%)] lg:tw-pl-8 lg:tw-pb-40">
                  <h5 className="tw-text-[#323348] tw-mb-4">Verification Requirements</h5>
                  <section className="tw-bg-white tw-p-6 tw-rounded-xl">
                    {/* LEVEL 1 & 2 VERIFICATION DOCUMENT HERE */}
                    {kycLvl !== 'LEVEL 3' && kycLvl !== 'Agency' && (
                      <>
                        <span className="block tw-text-[#5E6366] tw-text-sm">
                          Click on an image to see in full view
                        </span>
                        <div className="tw-flex tw-items-center tw-mt-3 tw-gap-x-8 lg:tw-gap-x-12">
                          <VerificationDocumentCard
                            title="Uploaded Selfie"
                            imgUrl={mediaData?.selfie_img_url || TEST_IMAGE_2}
                            hasIndicator={false}
                            imgClass="tw-cursor-pointer"
                            handleClick={() => {
                              const img_url = mediaData?.selfie_img_url || TEST_IMAGE_2;
                              setImageToPreview(img_url);
                              setShowPreviewImageModal(true);
                            }}
                          />
                          <VerificationDocumentCard
                            title="BVN Picture"
                            imgUrl={mediaData?.bvn_img_url || TEST_IMAGE_2}
                            hasIndicator={false}
                            imgClass="tw-cursor-pointer"
                            handleClick={() => {
                              const img_url = mediaData?.bvn_img_url || TEST_IMAGE_2;
                              setImageToPreview(img_url);
                              setShowPreviewImageModal(true);
                            }}
                          />
                        </div>
                        {verificationData.bvn_selfie_verification.status === 'pending' ? (
                          <div className="tw-mt-3 tw-flex tw-gap-x-6">
                            <div
                              className="tw-flex tw-flex-col tw-items-center tw-cursor-pointer"
                              onClick={() => {
                                setApproveRejectModal({
                                  ...approveRejectModal,
                                  show: true,
                                  type: 'approve',
                                });
                                setSelectedVerificationType(kycVerificationbvn);
                              }}>
                              <span className="tw-w-9 tw-h-7 tw-rounded-md tw-bg-green-600 tw-text-white tw-grid tw-place-content-center">
                                <FaCheck />
                              </span>
                              <span className="tw-text-xs tw-text-[#162082]">Approve</span>
                            </div>
                            <div
                              className="tw-flex tw-flex-col tw-items-center tw-cursor-pointer"
                              onClick={() => {
                                setApproveRejectModal({
                                  ...approveRejectModal,
                                  show: true,
                                  type: 'reject',
                                });
                                setSelectedVerificationType(kycVerificationbvn);
                              }}>
                              <span className="tw-w-9 tw-h-7 tw-rounded-md tw-bg-red-500 tw-text-white tw-grid tw-place-content-center">
                                <MdClose />
                              </span>
                              <span className="tw-text-xs tw-text-[#162082]">Reject</span>
                            </div>
                          </div>
                        ) : verificationData.bvn_selfie_verification.status === 'approved' ? (
                          <span className="tw-inline-block tw-my-2 tw-p-[2px] tw-text-xs tw-px-4 tw-bg-green-500 tw-text-white tw-rounded-xl">
                            Approved
                          </span>
                        ) : (
                          <span className="tw-inline-block tw-my-2 tw-p-[2px] tw-text-xs tw-px-4 tw-bg-red-500 tw-text-white tw-rounded-xl">
                            Rejected
                          </span>
                        )}
                      </>
                    )}

                    {/* LEVEL 2 VERIFICATION HERE */}
                    {kycLvl === 'LEVEL 2' && (
                      <section className="tw-mt-4">
                        <VerificationDocumentCard
                          title=""
                          imgUrl={mediaData?.idCard_img_url || TEST_IMAGE_1}
                          hasIndicator={false}
                          subTitle="National ID Card"
                          imgClass="tw-cursor-pointer"
                          handleClick={() => {
                            const img_url = mediaData?.idCard_img_url || TEST_IMAGE_1;
                            setImageToPreview(img_url);
                            setShowPreviewImageModal(true);
                          }}
                        />
                        {verificationData.identity_card_verification.status === 'pending' ? (
                          <div className="tw-mt-3 tw-flex tw-gap-x-6">
                            <div
                              className="tw-flex tw-flex-col tw-items-center tw-cursor-pointer"
                              onClick={() => {
                                setApproveRejectModal({
                                  ...approveRejectModal,
                                  show: true,
                                  type: 'approve',
                                });
                                setSelectedVerificationType(kycVerificationidentityCard);
                              }}>
                              <span className="tw-w-9 tw-h-7 tw-rounded-md tw-bg-green-600 tw-text-white tw-grid tw-place-content-center">
                                <FaCheck />
                              </span>
                              <span className="tw-text-xs tw-text-[#162082]">Approve</span>
                            </div>
                            <div
                              className="tw-flex tw-flex-col tw-items-center tw-cursor-pointer"
                              onClick={() => {
                                setApproveRejectModal({
                                  ...approveRejectModal,
                                  show: true,
                                  type: 'reject',
                                });
                                setSelectedVerificationType(kycVerificationidentityCard);
                              }}>
                              <span className="tw-w-9 tw-h-7 tw-rounded-md tw-bg-red-500 tw-text-white tw-grid tw-place-content-center">
                                <MdClose />
                              </span>
                              <span className="tw-text-xs tw-text-[#162082]">Reject</span>
                            </div>
                          </div>
                        ) : verificationData.identity_card_verification.status === 'approved' ? (
                          <span className="tw-inline-block tw-my-2 tw-p-[2px] tw-text-xs tw-px-4 tw-bg-green-500 tw-text-white tw-rounded-xl">
                            Approved
                          </span>
                        ) : (
                          <span className="tw-inline-block tw-my-2 tw-p-[2px] tw-text-xs tw-px-4 tw-bg-red-500 tw-text-white tw-rounded-xl">
                            Rejected
                          </span>
                        )}
                      </section>
                    )}
                    {/* LEVEL 3 VERIFICATION HERE */}
                    {kycLvl === 'LEVEL 3' && (
                      <>
                        <div className="tw-flex tw-items-center tw-mt-3 tw-gap-x-8 lg:tw-gap-x-12">
                          <div className="tw-mt- tw-grid tw-grid-cols-2 tw-flex-wra tw-gap-6 md:tw-grid-cols-1">
                            <InfoLabel
                              title="Address"
                              text={verificationData?.address_verification?.address}
                              textExtraclass="md:tw-w-[85%] tw-text-left"
                            />
                            <InfoLabel title="LGA" text={verificationData?.address_verification?.lga} />
                            <InfoLabel title="State" text={verificationData?.address_verification?.state} />
                          </div>
                          <VerificationDocumentCard
                            title="Utility Bill"
                            imgUrl={mediaData?.utilityBill_img_url || TEST_IMAGE_2}
                            hasIndicator={false}
                            imgClass="tw-cursor-pointer"
                            handleClick={() => {
                              const img_url = mediaData?.utilityBill_img_url || TEST_IMAGE_2;
                              setImageToPreview(img_url);
                              setShowPreviewImageModal(true);
                            }}
                          />
                        </div>
                        {verificationData.address_verification.status === 'pending' ? (
                          <div className="tw-mt-3 tw-flex tw-gap-x-6">
                            <div
                              className="tw-flex tw-flex-col tw-items-center tw-cursor-pointer"
                              onClick={() => {
                                setApproveRejectModal({
                                  ...approveRejectModal,
                                  show: true,
                                  type: 'approve',
                                });
                                setSelectedVerificationType(kycVerificationBusinessAddressVerification);
                              }}>
                              <span className="tw-w-9 tw-h-7 tw-rounded-md tw-bg-green-600 tw-text-white tw-grid tw-place-content-center">
                                <FaCheck />
                              </span>
                              <span className="tw-text-xs tw-text-[#162082]">Approve</span>
                            </div>
                            <div
                              className="tw-flex tw-flex-col tw-items-center tw-cursor-pointer"
                              onClick={() => {
                                setApproveRejectModal({
                                  ...approveRejectModal,
                                  show: true,
                                  type: 'reject',
                                });
                                setSelectedVerificationType(kycVerificationBusinessAddressVerification);
                              }}>
                              <span className="tw-w-9 tw-h-7 tw-rounded-md tw-bg-red-500 tw-text-white tw-grid tw-place-content-center">
                                <MdClose />
                              </span>
                              <span className="tw-text-xs tw-text-[#162082]">Reject</span>
                            </div>
                          </div>
                        ) : verificationData.address_verification.status === 'approved' ? (
                          <span className="tw-inline-block tw-my-2 tw-p-[2px] tw-text-xs tw-px-4 tw-bg-green-500 tw-text-white tw-rounded-xl">
                            Approved
                          </span>
                        ) : (
                          <span className="tw-inline-block tw-my-2 tw-p-[2px] tw-text-xs tw-px-4 tw-bg-red-500 tw-text-white tw-rounded-xl">
                            Rejected
                          </span>
                        )}
                      </>
                    )}

                    {/* AGENCY LEVEL VERIFICATION HERE */}
                    {kycLvl === 'Agency' && (
                      <>
                        <div className="tw-flex tw-items-center tw-mt-3 tw-gap-x-8 lg:tw-gap-x-12">
                          <div className="tw-mt- tw-grid tw-grid-cols-2 tw-flex-wra tw-gap-6 tw-items-center md:tw-grid-cols-1">
                            <InfoLabel
                              title=""
                              text="Business Information"
                              textExtraclass="tw-text-left tw-font-medium"
                            />
                            <InfoLabel
                              title="Business Name"
                              text={verificationData?.cac_document_verification?.business_name}
                            />
                            <InfoLabel
                              title="Business Address"
                              text={verificationData?.cac_document_verification?.business_address}
                              textExtraclass="tw-text-left"
                            />
                            <InfoLabel
                              title="Registration Number"
                              text={verificationData?.cac_document_verification?.business_registration_number}
                            />
                          </div>
                          <VerificationDocumentCard
                            title="CAC Document"
                            imgUrl={mediaData?.cac_img_url || TEST_IMAGE_2}
                            hasIndicator={false}
                            imgClass="tw-cursor-pointer"
                            handleClick={() => {
                              const img_url = mediaData?.cac_img_url || TEST_IMAGE_2;
                              setImageToPreview(img_url);
                              setShowPreviewImageModal(true);
                            }}
                          />
                        </div>
                        {verificationData.cac_document_verification.status === 'pending' ? (
                          <div className="tw-mt-3 tw-flex tw-gap-x-6">
                            <div
                              className="tw-flex tw-flex-col tw-items-center tw-cursor-pointer"
                              onClick={() => {
                                setApproveRejectModal({
                                  ...approveRejectModal,
                                  show: true,
                                  type: 'approve',
                                });
                                setSelectedVerificationType(kycVerificationBusinessAddressVerification);
                              }}>
                              <span className="tw-w-9 tw-h-7 tw-rounded-md tw-bg-green-600 tw-text-white tw-grid tw-place-content-center">
                                <FaCheck />
                              </span>
                              <span className="tw-text-xs tw-text-[#162082]">Approve</span>
                            </div>
                            <div
                              className="tw-flex tw-flex-col tw-items-center tw-cursor-pointer"
                              onClick={() => {
                                setApproveRejectModal({
                                  ...approveRejectModal,
                                  show: true,
                                  type: 'reject',
                                });
                                setSelectedVerificationType(kycVerificationCACDocumentVerification);
                              }}>
                              <span className="tw-w-9 tw-h-7 tw-rounded-md tw-bg-red-500 tw-text-white tw-grid tw-place-content-center">
                                <MdClose />
                              </span>
                              <span className="tw-text-xs tw-text-[#162082]">Reject</span>
                            </div>
                          </div>
                        ) : verificationData.cac_document_verification.status === 'approved' ? (
                          <span className="tw-inline-block tw-my-2 tw-p-[2px] tw-text-xs tw-px-4 tw-bg-green-500 tw-text-white tw-rounded-xl">
                            Approved
                          </span>
                        ) : (
                          <span className="tw-inline-block tw-my-2 tw-p-[2px] tw-text-xs tw-px-4 tw-bg-red-500 tw-text-white tw-rounded-xl">
                            Rejected
                          </span>
                        )}
                      </>
                    )}

                    {kycLvl !== 'LEVEL 1' && (
                      <ZojaButton
                        onClick={handleCompareClick}
                        text="Compare Images"
                        extraClass="tw-mt-6 tw-bg-isPrimary tw-text-white tw-p-1 tw-px-3 tw-rounded-lg tw-font-normal tw-text-xs"
                      />
                    )}
                  </section>
                  <section>
                    <h5 className="tw-mt-8 tw-text-[#323348]">Previously Approved Documents</h5>
                    {kycLvl !== 'LEVEL 1' && (
                      <section className="tw-mt-4">
                        <div className="tw-flex tw-items-center tw-mt-3 tw-gap-x-8 lg:tw-gap-x-12">
                          <VerificationDocumentCard
                            title="BVN Picture"
                            imgUrl={mediaData?.bvn_img_url || TEST_IMAGE_2}
                            hasIndicator={true}
                            handleClick={() => null}
                          />
                          {verificationData?.bvn_selfie_verification?.status === 'approved' && (
                            <VerificationDocumentCard
                              title="Uploaded Selfie"
                              imgUrl={mediaData?.selfie_img_url || TEST_IMAGE_2}
                              hasIndicator={true}
                              handleClick={() => null}
                            />
                          )}
                        </div>
                      </section>
                    )}
                    <div className="tw-mt-4 tw-flex tw-items-center tw-gap-x-8 lg:tw-gap-x-12">
                      {kycLvl !== 'LEVEL 1' && kycLvl !== 'LEVEL 2' && (
                        <VerificationDocumentCard title="National ID Card" imgUrl={mediaData?.idCard_img_url} />
                      )}
                    </div>
                    <div className="tw-mt-8 tw-flex tw-items-center tw-gap-x-8 lg:tw-gap-x-12">
                      {kycLvl === 'LEVEL 3' ||
                        (kycLvl === 'Agency' && (
                          <VerificationDocumentCard title="Utility Bill" imgUrl={mediaData?.utilityBill_img_url} />
                        ))}
                    </div>
                  </section>
                </aside>
                <aside className="tw-my-4 tw-border-b tw-pb-4 tw-overflow-y-scroll lg:tw-ml-12 lg:tw-h-screen lg:tw-border-l lg:tw-pl-16">
                  <h5 className="tw-text-[#323348]">System Information</h5>
                  <div className="tw-mt-6 tw-grid tw-grid-cols-2 tw-flex-wra tw-gap-6 md:tw-grid-cols-1 ">
                    <InfoLabel title="Full Name" text={userDetails?.fullname} />
                    <InfoLabel title="Email Address" text={userDetails?.email} />
                    <InfoLabel title="Phone Number" text={userDetails?.phone_number} />
                    {kycLvl !== 'LEVEL 1' && <InfoLabel title="BVN" text={userDetails?.bvn} />}
                    <InfoLabel title="Date of Birth" text={userDetails?.date_of_birth} />
                    {kycLvl !== 'LEVEL 1' && kycLvl !== 'LEVEL 2' && (
                      <InfoLabel title="ID Number" text={userDetails?.id_number} />
                    )}
                    {kycLvl === 'Agency' && (
                      <InfoLabel
                        title="Address"
                        text={verificationData?.address_verification?.address}
                        textExtraclass="tw-w-"
                      />
                    )}
                    {/* <InfoLabel title="Date Approved" text='Sept 19 2023' /> */}
                  </div>
                </aside>
              </>
            )}
          </section>
        )}
        {approveRejectModal.show && (
          <ZojalActionModal
            show={approveRejectModal.show}
            handleClose={() => setApproveRejectModal({ ...approveRejectModal, show: false, type: '' })}
            actionIcon={approveRejectModal.type === 'approve' ? images.list : images.listReject}
            children={
              <>
                <span className="tw-block tw-mt-6 tw-text-sm tw-text-[#162082] tw-w-[70%]">{`Are you sure you want to ${approveRejectModal.type}  this customer's document`}</span>
                <div className="tw-flex tw-gap-x-4 tw-my-8">
                  <ZojaButton
                    onClick={() => {
                      if (approveRejectModal.type === 'reject') {
                        setShowRejectionReasonModal(true);
                        setApproveRejectModal({ ...approveRejectModal, show: false, type: '' });
                      } else {
                        handleApproveVerification();
                      }
                    }}
                    extraClass={`tw-rounded-lg tw-p-1 tw-px-7 tw-text-sm tw-font-thin tw-text-white ${
                      approveRejectModal.type === 'approve' ? 'tw-bg-green-600' : 'tw-bg-red-500'
                    } ${kycVerificationStatus === 'loading' && 'tw-px-10'}`}
                    text={approveRejectModal.type === 'approve' ? 'Approve' : 'Reject'}
                    loading={kycVerificationStatus === 'loading'}
                  />
                  <ZojaButton
                    onClick={() => setApproveRejectModal({ ...approveRejectModal, show: false, type: '' })}
                    extraClass="tw-rounded-lg tw-p-1 tw-px-7 tw-text-sm tw-font-medium tw-text-[#162082] hover:tw-bg-blue-800 hover:tw-text-white"
                    text="Cancel"
                  />
                </div>
              </>
            }
          />
        )}
        <ZojalActionModal
          show={showRejectionReasonModal}
          handleClose={() => setShowRejectionReasonModal(false)}
          actionIcon={images.reject}
          children={
            <>
              <span className="tw-block tw-font-medium tw-mt-6 tw-text-sm tw-text-[#162082] tw-w-[70%]">
                Select Rejection Reason
              </span>
              <ZojaSelect
                options={rejectionReason}
                setSelectedOption={selected => setSelectedRejectionReason(selected)}
                extraClass="tw-mt-4"
                showArrown={true}
                arrowDownClass="tw-top-4"
              />
              <div className="tw-flex tw-gap-x-4 tw-my-8">
                <ZojaButton
                  onClick={() => handleRejectVerification()}
                  extraClass={`tw-rounded-lg tw-p-1 tw-px-7 tw-text-sm tw-font-thin tw-text-white tw-bg-isPrimary ${
                    kycVerificationStatus === 'loading' && 'tw-px-10'
                  }`}
                  text={'Submit'}
                  loading={kycVerificationStatus === 'loading'}
                />
              </div>
            </>
          }
        />

        {/* Aprrove Success Modal */}
        <ZojalActionModal
          show={isSuccessModalVisible}
          handleClose={() => {
            setIsSuccesssModalVisible(false);
            setShowRejectionReasonModal(false);
            setActionType('');
            dispatch(kycVerificationReset());
            if (kycLvl === 'LEVEL 2') {
              window.location.reload();
            } else {
              navigate(KYC);
            }
          }}
          actionIcon={images.check}
          children={
            <>
              <span className="tw-block tw-mt-6 tw-text-sm tw-text-[#162082] tw-w-[70%]">
                You have successfully{' '}
                {actionType === 'approve' ? 'approved' : actionType === 'reject' ? 'rejected' : 'updated'} the
                customer`s document
              </span>
              <div className="tw-flex tw-gap-x-4 tw-my-8">
                <ZojaButton
                  onClick={() => {
                    setIsSuccesssModalVisible(false);
                    setShowRejectionReasonModal(false);
                    setActionType('');
                    dispatch(kycVerificationReset());
                    if (kycLvl === 'LEVEL 2') {
                      window.location.reload();
                    } else {
                      navigate(KYC);
                    }
                  }}
                  extraClass={`tw-rounded-lg tw-p-1 tw-px-7 tw-text-sm tw-font-thin tw-text-white tw-bg-isPrimary ${
                    false && 'tw-px-10'
                  }`}
                  text={'Close'}
                  loading={false}
                />
              </div>
            </>
          }
        />
        <PreviewImageModal
          show={showPreviewImageModal}
          handleClose={() => setShowPreviewImageModal(false)}
          imageUrl={imageToPreview}
        />
        <CompareDocumentModal
          show={showCompareDocumentModal}
          handleClose={() => setShowCompareDocumentModal(false)}
          imageUrl={imageToPreview}
          to_compare={compareDocumentData}
          compareAgainstDocUrl={compareDocumentData.right_doc_url}
          level={kycLvl}
        />
      </section>
    </AppContainer>
  );
};

export default KycCustomerInformation;
