import { ZojaButton } from '../tailwind'
import { icons } from '../../utils'
import { Oval } from 'react-loader-spinner'

interface IProps {
    title: string,
    icon?: React.ReactElement,
    children?: React.ReactElement,
    labelExtraclass?: string,
    labelStyle?: any,
    iconClass?: string,
    titleClass?: string,
    loading?: boolean,
    onClick: () => void
}

const ExportLabel = ({ title, loading = false, icon, iconClass, labelExtraclass, titleClass, labelStyle, onClick, children }: IProps) => {
    return (
        <div className="tw-relative">
           {!loading && <ZojaButton
                type='button'
                name='Export'
                text={title}
                icon={icon ? icon : <icons.FiDownload />}
                onClick={onClick}
                extraClass={`tw-bg-isPrimary tw-font-thin tw-text-white tw-text-[14px] tw-p-2.5 tw-px-4 tw-rounded-[4px] ${titleClass}`}
            />}
            {
                loading && (
                    <span className='tw-block tw-bg-isPrimary tw-font-thin tw-text-white tw-text-[14px] tw-p-2.5 tw-px-8 tw-rounded-[4px]'>
                        <Oval
                            height="20"
                            width="20"
                            color="#ffffff"
                            ariaLabel="tail-spin-loading"
                            secondaryColor='#ffffff'
                        />
                    </span>
                )
            }
            {children}
        </div>
    )
}

export default ExportLabel