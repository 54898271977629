import { handleSelectedOption, toggleItemChecked } from '../../utils';
import ZojaCheckbox from '../checkbox';

interface ModuleCheckProps {
  title?: string;
  itemName: string;
  moduleList: any[];
  module: any;
  setModule: any;
}

const ModuleCheck = ({ moduleList, module, setModule, itemName }: ModuleCheckProps) => {
  return (
    <div className={'tw-flex tw-flex-wrap tw-gap-x-8 tw-gap-y-9'}>
      {moduleList.map(item => (
        <div className="tw-flex tw-gap-x-4 tw-items-center" key={item.id}>
          <ZojaCheckbox
            isChecked={item.isChecked}
            className="tw-mb-4"
            onClick={() => toggleItemChecked(item.id, module, itemName, setModule)}
          />
          <p className=" tw-font-normal tw-text-base tw-text-[#323348] tw-m-0">{item.title}</p>
        </div>
      ))}
    </div>
  );
};

export default ModuleCheck;
