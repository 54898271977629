import React from 'react';
import { Oval } from 'react-loader-spinner';
interface ButtonProps {
  id?: string;
  name?: string;
  text: string | any;
  onClick?: (e: any) => void;
  extraClass?: string;
  labelExtras?: string;
  marginTop?: string;
  disabled?: boolean;
  type?: 'button' | 'submit';
  onBlur?: (e: any) => void;
  error?: any;
  icon?: any;
  loading?: boolean;
  loadingText?: string;
}

const ZojaButton = ({
  extraClass,
  text,
  onClick,
  marginTop,
  name,
  id,
  disabled,
  icon,
  type = 'submit',
  loading,
  loadingText
}: ButtonProps) => {
  return (
    <div className={marginTop}>
      <button
        id={id}
        name={name}
        disabled={disabled}
        className={`${extraClass} ${icon && 'tw-flex tw-items-center tw-justify-center tw-gap-x-2'
          } tw-font-semibold tw-p-1.5 disabled:tw-bg-isPrimary/70 tw-rounded-[0.4rem] tw-text-md tw-transition-all tw-duration-500 tw-ease-out`}
        onClick={onClick}
        type={type}>
        {
          loading ? <div className="tw-flex tw-items-center tw-gap-1">
            <Oval
              height="20"
              width="20"
              color="#ffffff"
              ariaLabel="tail-spin-loading"
              secondaryColor="#222b882b"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
            {loadingText && loadingText}
          </div> : <>{icon} {text}</>
        }
      </button>
    </div>
  );
};

export default ZojaButton;
