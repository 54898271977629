import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContainer, CountInfo } from '../../atoms';
import { UserContainer, UsersContainer, TableContainer, SearchContainer } from './style';
import { Dictionary } from '../../types';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import {
  getUsersRequest,
  getSuperAgentsRequest,
  // getInternalUsersRequest,
  downloadUsersRecord,
} from '../../redux/slice';
import { Oval } from 'react-loader-spinner';
import {
  SearchInput,
  UsersTable,
  Pagination,
  BorderedText,
  ExportLabel,
  FilterCard,
  FilterLabel,
  ZojaCheckbox,
  FilterColapsible,
  CustomDatePicker,
} from '../../components';
import { userDataHeader } from './data';
import {
  colors,
  dateFormat,
  routesPath,
  spacing,
  icons,
  handleSelectedOption,
  formatStartAndEndDateWithDelimeter,
} from '../../utils';
import { ZojaSelect } from '../../components/tailwind';

const { CUSTOMERDETAILS } = routesPath;

const activeUser = 'active';
const inActiveUser = 'inactive';
const superAgentUser = 'super_agent';

const userTypeToFetchByActivity = (data: Dictionary) => {
  let result: string = '';
  if (!data?.hasOwnProperty('id') || data?.id === 1) {
    result = activeUser;
  }

  if (data?.id === 2) {
    result = superAgentUser;
  }

  if (data?.id === 3) {
    result = inActiveUser;
  }

  return result;
};

// filter options constants
const sortByOptions = {
  aphabetically: [
    {
      id: 1,
      value: 'az',
      title: 'A-Z',
      isChecked: false,
    },
    {
      id: 2,
      value: 'za',
      title: 'Z-A',
      isChecked: false,
    },
    {
      id: 3,
      value: 'kyc_level',
      title: 'Kyc Level',
      isChecked: false,
    },
  ],
  orderBy: [
    {
      id: 1,
      value: 'newest',
      title: 'Newest',
      isChecked: false,
    },
    {
      id: 2,
      value: 'oldest',
      title: 'Oldest',
      isChecked: false,
    },
    {
      id: 3,
      value: 'last_seen',
      title: 'Last Seen',
      isChecked: false,
    },
  ],
};

const initialValues: Dictionary = {
  status: '',
  kyc_level: '',
  last_seen: '',
  sort_by: '',
  order_by: '',
  date_onboarded: '',
  deactivated_at: '',
};

const memberAndLastSeenOptions = [
  {
    id: 1,
    value: 'one_week',
    title: 'One week',
    isChecked: false,
  },
  {
    id: 2,
    value: 'thirty_days',
    title: '30 Days',
    isChecked: false,
  },
  {
    id: 3,
    value: 'six_months',
    title: '6 Months',
    isChecked: false,
  },
  {
    id: 4,
    value: 'custom',
    title: 'Custom',
    isChecked: false,
  },
];

const LastSeenOptions = [
  {
    id: 1,
    value: 'one_week',
    title: 'One week',
    isChecked: false,
  },
  {
    id: 2,
    value: 'thirty_days',
    title: '30 Days',
    isChecked: false,
  },
  {
    id: 3,
    value: 'six_months',
    title: '6 Months',
    isChecked: false,
  },
  {
    id: 4,
    value: 'custom',
    title: 'Custom',
    isChecked: false,
  },
];

const initialUserCountData = [
  {
    id: 1,
    count: 0,
    title: 'Active Users',
  },
  {
    id: 2,
    count: 0,
    title: 'Super Agents',
  },
  {
    id: 3,
    count: 0,
    title: 'Inactive Users',
  },
];

function Customers() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [userCountData, setUserCountData] = useState<any[]>(initialUserCountData);
  const [selectedUsersCard, setSelectedUsersCard] = useState<Dictionary>(initialUserCountData[0]);

  const [isSearchingUsers, setIsSearchingUsers] = useState(false);

  const [searchValue, setSearchValue] = useState('');

  const [usersData, setUsersData] = useState<any[]>([]);
  const [usersDataSuperAgent, setUsersDataSuperAgent] = useState<any[]>([]);
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(5);
  const [firstMount, setFirstMount] = useState(true);
  // const [toggleGetInternalUser, setToggleGetInternalUser] = useState(false);

  //filters and selectors and input states
  const [showExportOptions, setShowExportOptions] = useState(false);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [showSortBy, setShowSortBy] = useState(false);
  const [showDateOnboarded, setShowDateOnboarded] = useState(false);
  const [showLastSeen, setShowLastSeen] = useState(false);
  const [sortBy, setSortBy] = useState(sortByOptions.aphabetically);
  const [onboardedDateRange, setOnboardedDateRange] = useState({ state_date: '', end_date: '' });
  const [lastSeenDateRange, setLastSeenDateRange] = useState({ state_date: '', end_date: '' });
  const [filterOptions, setFilterOptions] = useState(initialValues);
  const [dateOnboarded, setDateOnboarded] = useState(memberAndLastSeenOptions);
  const [orderBy, setOrderBy] = useState(sortByOptions.orderBy);
  const [lastSeen, setLastSeen] = useState(LastSeenOptions);

  // redux state
  const usersState = useAppSelector(state => state.getUsers);
  const { status: usersStatus } = usersState;

  const superAgentsState = useAppSelector(state => state.getSuperAgents);
  const { status: superAgentsStatus } = superAgentsState;

  // const updateUserStatusState = useAppSelector(state => state.updateUserStatus);
  // const { status: updateUserStatusStatus } = updateUserStatusState;

  const { status: downloadStatus } = useAppSelector(state => state.downloadUsersRecord);

  // get users by status
  const userTypeToFetch = userTypeToFetchByActivity(selectedUsersCard);

  // filter table handler
  const handleSubmitFilter = () => {
    const onboarded_date_range = formatStartAndEndDateWithDelimeter(
      onboardedDateRange.state_date,
      onboardedDateRange.end_date,
    );
    const lastseen_date_range = formatStartAndEndDateWithDelimeter(
      lastSeenDateRange.state_date,
      lastSeenDateRange.end_date,
    );
    let payload = { ...filterOptions };
    payload = payload.date_onboarded === 'custom' ? { ...payload, date_onboarded: onboarded_date_range } : payload;
    payload = payload.last_seen === 'custom' ? { ...payload, last_seen: lastseen_date_range } : payload;

    if (userTypeToFetch === 'super_agent') {
      dispatch(
        getSuperAgentsRequest({
          filter: { ...payload },
          per_page: pageSize,
          page: currentPage,
        }),
      );
    } else {
      dispatch(
        getUsersRequest({
          path: `/sort-by-status?status=${userTypeToFetch}`,
          filter: { ...payload },
          per_page: pageSize,
          page: currentPage,
        }),
      );
    }
  };

  // filter and export methods
  const handleExportFile = (type: string) => {
    const path =
      searchValue?.length >= 1 ? `?term=${searchValue}&include=account` : `/sort-by-status?status=${userTypeToFetch}`;
    const pathSuperAgent = searchValue?.length >= 1 ? `?term=${searchValue}&include=account` : `/super-agents?`;

    const downloadPath = userTypeToFetch === 'super_agent' ? pathSuperAgent : path;

    let exportPayload = {
      ...filterOptions,
      path: downloadPath,
      status: userTypeToFetch,
      export: type,
      page: currentPage,
      per_page: pageSize,
    };
    dispatch(downloadUsersRecord(exportPayload));
    setShowExportOptions(false);
  };

  //useEffects
  useEffect(() => {
    dispatch(
      getUsersRequest({
        path:
          searchValue?.length >= 1
            ? `?term=${searchValue}&include=account`
            : `/sort-by-status?status=${userTypeToFetch}`,
        per_page: pageSize,
        page: currentPage,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsersCard, currentPage, isSearchingUsers]);

  useEffect(() => {
    if (usersStatus === 'succeeded') {
      let userCountResult: any[] = [];

      userCountResult = [
        {
          id: 1,
          count: usersState?.data?.active_users_count,
          title: 'Active Users',
        },
        {
          id: 2,
          count: usersState?.data?.super_agent_count,
          title: 'Super Agents',
        },
        {
          id: 3,
          count: usersState?.data?.inactive_users_count,
          title: 'Inactive Users',
        },
      ];

      let updateUsersData: any[] = [];

      usersState?.data?.users?.data?.forEach((item: Dictionary, index: number) => {
        if (userTypeToFetch === inActiveUser) {
          updateUsersData.push({
            id: index + 1,
            name: item?.name !== null ? `${item?.name}` : 'N/A',

            userId: item?.id,
            walletNo: item?.account?.number ? item?.account?.number : 'N/A',
            phone: item?.telephone,
            lastSeen: dateFormat(item?.last_login),
            email: item?.email,
          });
        } else {
          updateUsersData.push({
            id: index + 1,
            name: item?.name !== null ? `${item?.name}` : 'N/A',
            userId: item?.id,
            walletNo: item?.account?.number ? item?.account?.number : 'N/A',
            phone: item?.telephone,
            email: item?.email,
          });
        }
      });

      // this is because i want this to set just one time
      if (firstMount === true) {
        setUserCountData(userCountResult);
      }
      setUsersData(updateUsersData);
      setFirstMount(false);
      const {
        meta: { last_page },
      } = usersState?.data?.users;

      setTotalPages(last_page);
    }
    setShowFilterOptions(false);
    setShowSortBy(false);
    setShowExportOptions(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersStatus, usersState]);

  useEffect(() => {
    dispatch(getSuperAgentsRequest({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (superAgentsStatus === 'succeeded') {
      let updateUsersData: any[] = [];
      superAgentsState?.data?.users?.data?.forEach((item: Dictionary, index: number) => {
        updateUsersData.push({
          id: index + 1,
          name: item?.name !== null ? `${item?.name}` : 'N/A',
          userId: item?.id,
          walletNo: item?.account?.number ? item?.account?.number : 'N/A',
          phone: item?.telephone ? item?.telephone : 'N/A',
          subAgents: item?.sub_agents_count,
          email: item?.email,
        });
      });

      setUsersDataSuperAgent(updateUsersData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [superAgentsState]);

  // removing status and adding kyc_level to sort by option
  // useEffect(() => {
  //   setSortBy((prevState: any) => {
  //     let newOption = {
  //       id: prevState.length + 1,
  //       value: 'kyc_level',
  //       title: 'Kyc Level',
  //       isChecked: false,
  //     };
  //     let prevData = sortByOptions.aphabetically.filter((f: any) => f.value !== 'status');
  //     const findIndex = prevData.findIndex(d => d.value === 'kyc_level');
  //     if (findIndex) {
  //       sortByOptions.aphabetically.filter((f: any) => f.value !== 'kyc_level');
  //       return prevData;
  //     }
  //     prevData = [...prevData, { ...newOption }];
  //     return prevData;
  //   });
  //   dispatch(
  //     getInternalUsersRequest({
  //       status: '',
  //       last_seen: '',
  //       date_onboarded: '',
  //       sort_by: '',
  //       order_by: '',
  //       search: '',
  //     }),
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [toggleGetInternalUser, updateUserStatusState]);

  return (
    <AppContainer navTitle="CUSTOMERS">
      <>
        <UserContainer>
          {(showFilterOptions || showSortBy || showExportOptions) && (
            <div
              className="tw-absolute tw-bg-black/0 tw-cursor-pointer tw-z-30 tw-w-full tw-h-screen
           tw-top-0 tw-left-0"
              onClick={() => {
                setShowFilterOptions(false);
                setShowSortBy(false);
                setShowExportOptions(false);
              }}></div>
          )}
          <UsersContainer>
            <CountInfo
              data={userCountData}
              setSelectedData={setSelectedUsersCard}
              loading={usersStatus === 'loading'}
            />

            <div className="tw-flex tw-flex-col tw-gap-1 md:tw-gap-x-4 md:tw-flex-row md:tw-justify-end md:tw-items-center">
              <SearchContainer style={{ margin: '30px 0' }}>
                <SearchInput
                  placeholder="By Name, Acccount no"
                  backgroundColor={'transparent'}
                  name="SearchValue"
                  value={searchValue}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value.length < 1) {
                      setIsSearchingUsers(!isSearchingUsers);
                    }
                    setSearchValue(e.target.value);
                  }}
                />
                {searchValue?.length > 1 && (
                  <div style={{ marginLeft: spacing.xsmall }}>
                    <BorderedText
                      color={colors.white}
                      backgroundColor={colors.primary}
                      text="Search"
                      onClick={() => setIsSearchingUsers(!isSearchingUsers)}
                    />
                  </div>
                )}
              </SearchContainer>
              <div className="tw-flex tw-items-center tw-justify-end tw-gap-x-4 md:tw-justify-between md:tw-mr-4">
                <FilterLabel
                  title="Filter Options"
                  icon={<icons.BsFilter />}
                  onClick={() => setShowFilterOptions(true)}>
                  {/* <span className='tw-absolute tw-hidden'>here</span> */}
                  <FilterCard
                    showFilter={showFilterOptions}
                    title="Filter Options"
                    onClick={() => handleSubmitFilter()}
                    extraClass="tw-w-[13rem]"
                    // handleClose={() => setShowFilterOptions(false)}
                  >
                    <div className="tw-mt-4 tw-h-[14rem] tw-overflow-y-scroll tw-overflow-hidden">
                      {userTypeToFetch !== 'super_agent' && (
                        <ZojaSelect
                          options={[
                            { value: '', label: 'KYC Level' },
                            { value: 'basic', label: 'Basic' },
                            { value: 'intermediate', label: 'Intermediate' },
                            { value: 'premium', label: 'Premium' },
                          ]}
                          name="status"
                          showArrown={true}
                          setSelectedOption={selected => setFilterOptions({ ...filterOptions, kyc_level: selected })}
                          extraClass="tw-text-xs tw-p-3.5 tw-bg-white tw-border-0 tw-shadow-zojaShadowFive tw-rounded-md tw-font-normal tw-text-[#222B88] tw-mb-2"
                          arrowDownClass="tw-right-lg tw-top-3.5"
                        />
                      )}

                      <FilterColapsible
                        title={`${userTypeToFetch === 'inactive' ? 'Date Deactivated' : 'Date Onboarded'}`}
                        show={showDateOnboarded}
                        onClick={() => setShowDateOnboarded(!showDateOnboarded)}
                        extraClass="tw-shadow-none">
                        <>
                          <div className="">
                            {dateOnboarded.map((item: any) => (
                              <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                                <span className="tw-text-[13px] tw-mt-4 tw-text-[#84919A]">{item.title}</span>
                                <ZojaCheckbox
                                  isChecked={item.isChecked}
                                  onClick={() => handleSelectedOption(item, dateOnboarded, setDateOnboarded)}
                                />
                              </div>
                            ))}
                          </div>
                          <div
                            className={`tw-mt-2 tw-overflow-hidden tw-max-h-0 ${
                              filterOptions.date_onboarded === 'custom' && 'tw-max-h-fit'
                            }`}>
                            <div className="tw-mb-2">
                              <CustomDatePicker
                                label="State Date"
                                value={new Date()}
                                setValue={value => setOnboardedDateRange({ ...onboardedDateRange, state_date: value })}
                              />
                            </div>
                            <div className="tw-mb-2">
                              <CustomDatePicker
                                label="End Date"
                                value={new Date()}
                                setValue={value => setOnboardedDateRange({ ...onboardedDateRange, end_date: value })}
                              />
                            </div>
                          </div>
                        </>
                      </FilterColapsible>

                      {userTypeToFetch !== 'inactive' && (
                        <FilterColapsible
                          title="Last Seen"
                          show={showLastSeen}
                          onClick={() => setShowLastSeen(!showLastSeen)}
                          extraClass="tw-shadow-none">
                          <>
                            <div className="">
                              {lastSeen.map((item: any) => (
                                <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                                  <span className="tw-text-[13px] tw-mt-4 tw-text-[#84919A]">{item.title}</span>
                                  <ZojaCheckbox
                                    isChecked={item.isChecked}
                                    onClick={() => handleSelectedOption(item, lastSeen, setLastSeen)}
                                  />
                                </div>
                              ))}
                            </div>

                            <div
                              className={`tw-mt-2 tw-overflow-hidden tw-max-h-0 ${
                                filterOptions.last_seen === 'custom' && 'tw-max-h-fit'
                              }`}>
                              <div className="tw-mb-2">
                                <CustomDatePicker
                                  label="State Date"
                                  value={new Date()}
                                  setValue={value => setLastSeenDateRange({ ...lastSeenDateRange, state_date: value })}
                                />
                              </div>
                              <div className="tw-mb-2">
                                <CustomDatePicker
                                  label="End Date"
                                  value={new Date()}
                                  setValue={value => setLastSeenDateRange({ ...lastSeenDateRange, end_date: value })}
                                />
                              </div>
                            </div>
                          </>
                        </FilterColapsible>
                      )}
                    </div>
                  </FilterCard>
                </FilterLabel>

                <FilterLabel
                  title="Sort By"
                  icon={<icons.TbArrowsSort color="#5E6366" />}
                  onClick={() => setShowSortBy(true)}>
                  <FilterCard
                    showFilter={showSortBy}
                    title="Sort by"
                    onClick={() => handleSubmitFilter()}
                    submitButtonText="Apply"
                    extraClass="tw-w-[10rem] -tw-left-28">
                    <>
                      <div className="tw-pt-2">
                        {sortBy.map((item: any) => (
                          <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                            <span className="tw-mt-4 tw-text-[13px] tw-text-[#84919A]">{item.title}</span>
                            <ZojaCheckbox
                              isChecked={item.isChecked}
                              onClick={() => handleSelectedOption(item, sortBy, setSortBy)}
                            />
                          </div>
                        ))}
                      </div>
                      <div className="tw-pt-2 tw-border-">
                        {orderBy.map((item: any) => (
                          <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                            <span className="tw-mt-4 tw-text-[13px] tw-text-[#84919A]">{item.title}</span>
                            <ZojaCheckbox
                              isChecked={item.isChecked}
                              onClick={() => handleSelectedOption(item, orderBy, setOrderBy)}
                            />
                          </div>
                        ))}
                      </div>
                    </>
                  </FilterCard>
                </FilterLabel>

                <ExportLabel
                  title="Export"
                  onClick={() => setShowExportOptions(true)}
                  loading={downloadStatus === 'loading' ? true : false}>
                  <FilterCard
                    showFilter={showExportOptions}
                    title="Export as"
                    onClick={() => null}
                    showSubmitButton={false}
                    extraClass="tw-w-[10rem] -tw-left-28">
                    <div className="tw-flex tw-flex-col tw-mt-4">
                      <span
                        className="tw-text-xs tw-cursor-pointer tw-text-isGrey tw-border-b tw-border-b-[#f4f3f3] tw-pb-2 tw-mb-2"
                        onClick={() => handleExportFile('pdf')}>
                        PDF
                      </span>
                      <span
                        className="tw-text-xs tw-cursor-pointer tw-text-isGrey"
                        onClick={() => handleExportFile('csv')}>
                        CSV
                      </span>
                    </div>
                  </FilterCard>
                </ExportLabel>
              </div>
            </div>

            <TableContainer>
              {selectedUsersCard.id === 1 &&
                (usersStatus === 'loading' ? (
                  <div className="tw-h-[50vh] tw-mx-auto tw-flex tw-justify-center tw-items-center">
                    <Oval
                      height="80"
                      width="80"
                      color="#222b88cf"
                      ariaLabel="tail-spin-loading"
                      secondaryColor="#222b882b"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                ) : (
                  <UsersTable
                    type="active"
                    headerData={userDataHeader}
                    header={true}
                    data={usersData}
                    onClick={item => navigate(`${CUSTOMERDETAILS}${item.userId}`)}
                  />
                ))}
              {selectedUsersCard.id === 2 &&
                (usersStatus === 'loading' ? (
                  <div className="tw-h-[50vh] tw-mx-auto tw-flex tw-justify-center tw-items-center">
                    <Oval
                      height="80"
                      width="80"
                      color="#222b88cf"
                      ariaLabel="tail-spin-loading"
                      secondaryColor="#222b882b"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                ) : (
                  <UsersTable
                    headerData={userDataHeader}
                    header={true}
                    data={usersDataSuperAgent}
                    onClick={item => navigate(`${CUSTOMERDETAILS}${item.userId}`)}
                    type="subagents"
                  />
                ))}
              {selectedUsersCard.id === 3 &&
                (usersStatus === 'loading' ? (
                  <div className="tw-h-[50vh] tw-mx-auto tw-flex tw-justify-center tw-items-center">
                    <Oval
                      height="80"
                      width="80"
                      color="#222b88cf"
                      ariaLabel="tail-spin-loading"
                      secondaryColor="#222b882b"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                ) : (
                  <UsersTable
                    headerData={userDataHeader}
                    header={true}
                    data={usersData}
                    onClick={item => navigate(`${CUSTOMERDETAILS}${item.userId}`)}
                    type="inactive"
                  />
                ))}

              {(usersData.length >= 1 || usersDataSuperAgent.length >= 1) && (
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={selectedPage => {
                    setCurrentPage(selectedPage);
                  }}
                  isLoading={superAgentsStatus === 'loading' || usersStatus === 'loading'}
                />
              )}
            </TableContainer>
          </UsersContainer>
        </UserContainer>
      </>
    </AppContainer>
  );
}

export default Customers;
