import React from 'react';
import ZojaCheckbox from '../checkbox';
import { handleSelectedOption } from '../../utils';
import PermissionsCheck from './PermissionsCheck';

const PermissionDefault = ({ permissions, setPermissions, selectedRole }: any) => {
  // console.log(permissions, selectedRole);
  return (
    <div className="tw-rounded-lg tw-border tw-p-4 tw-h-[237px] tw-overflow-y-auto">
      {/* <h3 className=" tw-text-sm tw-font-medium tw-mb-5">Permission Defaults</h3> */}
      <div className="tw-grid tw-gap-y-2">
        <PermissionsCheck
          select={permissions}
          setSelected={setPermissions}
          permissionList={permissions[selectedRole?.toLowerCase()]}
          itemName={selectedRole}
          hasTitleBorder={true}
        />
      </div>
    </div>
  );
};

export default PermissionDefault;
