import moment from 'moment'
import { LabelOne } from '../../components'
import { Dictionary } from '../../types'
import { currencyFormat } from '../../utils'

interface CustomerAccountInformationProps {
    show: boolean,
    data: Dictionary
}

export default function CustomerAccountInformation({ show, data }: CustomerAccountInformationProps) {
    const { account_name, account_number, address, email, last_login, phone_number, status, tier, account_summary: { available_balance, total_money_in, total_money_out }  } = data
    return (
        <div className={`
        tw-mt-6 tw-border tw-rounded-m tw-bg-white tw-p-6 tw-shadow-zojaShadowNine md:tw-p-7 lg:tw-w-3/4
        ${!show && 'tw-hidden'}`}>
            <div className="tw-flex tw-flex-col tw-gap-4 tw-border-b tw-border-gray-100 tw-pb-5 tw-justify-between sm:tw-flex-row">
                <div className="tw-text-sm tw-flex tw-flex-col tw-gap-2 md:tw-w-2/6">
                <table className='tw-border-separate tw-border-spacing-y-1'>
                        <thead></thead>
                        <tbody>
                            <tr>
                                <td className='tw-text-sm'>Phone Number:</td>
                                <td className='tw-text-sm tw-font-normal tw-pl-2'>{phone_number}</td>
                            </tr>
                            <tr>
                                <td className='tw-text-sm'>Email:</td>
                                <td className='tw-text-sm tw-font-normal tw-pl-2'>{email}</td>
                            </tr>
                            <tr>
                                <td className='tw-text-sm'>Last Login:</td>
                                <td className='tw-text-sm tw-font-normal tw-pl-2'>{moment(last_login).format('D-MM-YYYY hh:mm a')}</td>
                            </tr>
                            <tr>
                                <td className='tw-text-sm'>Address:</td>
                                <td className='tw-text-sm tw-font-normal tw-pl-2'>{address}</td>
                            </tr>
                            <tr>
                                <td className='tw-text-sm'>Status:</td>
                                <td className='tw-text-sm tw-font-normal tw-pl-2'><span className={`tw-p-1 tw-px-6 tw-text-xs tw-text-white tw-rounded-sm ${
                                    status === 'active' ? 'tw-bg-green-400' : status === 'inactive' ? 'tw-bg-red-400' : 'tw-bg-gray-500'
                                }`}>{status}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="tw-flex tw-flex-col tw-gap-2">
                    <table className='tw-border-separate tw-border-spacing-y-1'>
                        <thead></thead>
                        <tbody>
                            <tr>
                                <td className='tw-text-sm'>Account Name:</td>
                                <td className='tw-text-sm tw-font-normal tw-pl-2'>{account_name}</td>
                            </tr>
                            <tr>
                                <td className='tw-text-sm'>Account Number:</td>
                                <td className='tw-text-sm tw-font-normal tw-pl-2'>{account_number}</td>
                            </tr>
                            <tr>
                                <td className='tw-text-sm'>Tier:</td>
                                <td className='tw-text-sm tw-font-normal tw-pl-2 tw-capitalize'>{ tier }</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="tw-mt-4 tw-pt-2 tw-bg-blue-50 tw-p-5">
                <h6 className="tw-pt-2 tw-text-sm tw-text-blue-800 tw-mb-1 tw-uppercase">Account Summary</h6>
                <table>
                    <thead></thead>
                    <tbody>
                        <tr>
                            <td className='tw-text-sm'>Available Balance:</td>
                            <td className='tw-text-sm tw-font-normal tw-pl-2'>{currencyFormat(available_balance)}</td>
                        </tr>
                        <tr>
                            <td className='tw-text-sm'>Total Money In:</td>
                            <td className='tw-text-sm tw-font-normal tw-pl-2'>{currencyFormat(total_money_in)}</td>
                        </tr>
                        <tr>
                            <td className='tw-text-sm'>Total Money Out:</td>
                            <td className='tw-text-sm tw-font-normal tw-pl-2'>{currencyFormat(total_money_out)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
