import React, { ReactElement } from 'react';
import { FaChevronDown } from 'react-icons/fa';
interface SelectProps {
  id?: string;
  name?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  extraClass?: string;
  labelExtraClass?: string;
  setSelectedOption: (value: any) => void;
  showArrown?: boolean;
  arrowDownClass?: string;
  marginTop?: string;
  label?: string;
  disabled?: boolean;
  options: Array<{ label: string; value: string | number }>;
  icon?: ReactElement;
  onBlur?: (e: any) => void;
  error?: any;
  touched?: any;
}

const ZojaSelect = ({
  id,
  name = '',
  value,
  options,
  touched = {},
  disabled,
  showArrown = false,
  arrowDownClass,
  label,
  error,
  marginTop,
  extraClass,
  onChange,
  icon,
  labelExtraClass,
  setSelectedOption,
}: SelectProps) => {
  return (
    <div className={marginTop}>
      {label && (
        <label htmlFor="" className={`${labelExtraClass} tw-w-full tw-text-md tw-text-[#6A616F] tw-mb-1`}>
          {label}
        </label>
      )}
      <div className="tw-relative tw-w-full">
        <select
          disabled={disabled}
          name={name}
          onChange={e => setSelectedOption(e.target.value)}
          className={`${extraClass} tw-border tw-text-gray-900 tw-text-sm tw-rounded-lg tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-block tw-p-2.5
                focus:tw-outline-none tw-w-full tw-appearance-none ${
                  touched[name] && error ? 'tw-border-red-400' : ''
                } ${disabled && 'tw-bg-gray-300'}`}>
          {options.map((option, index) => (
            <option
              key={index}
              value={option.value}
              className=""
              selected={value?.toString()?.toLowerCase() === option?.value?.toString().toLowerCase()}>
              {option.label}
            </option>
          ))}
        </select>
        {showArrown && (
          <span className={`${arrowDownClass} tw-absolute tw-text-isGreyVariantFour tw-text-xs tw-right-4`}>
            <FaChevronDown />
          </span>
        )}
      </div>
      {touched[name] && error && <span className="tw-text-xs tw-text-red-400">{error}</span>}
    </div>
  );
};

export default ZojaSelect;
