import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/redux-hooks";
import { ConfirmPayment, PaymentContainer, RejectPaymentFileUpload } from "../../../atoms";
import { ZojaButton, ZojaModal } from "../../../components/tailwind";
import { cn } from "../../../lib/utils";
import { BatchPaymentTable, CustomFileUploader, PaymentEmptyState, PaymentPageTitle, PaymentTableList, UploadedPaymentList } from "../../../components";
import { Download } from "lucide-react";
import { Dictionary } from "../../../types";
import { downloadPaymentListTemplateRequest, uploadPaymentListRequest, uploadPaymentListReset } from "../../../redux/slice";
import icons from '../../../utils/icons';
import { images, useSortTable } from "../../../utils";

type ACTIVE_TABS_TYPE = 'upload' | 'view' | 'pending' | 'approved' | ""

const INITIATOR_TABS = ['Initiate Payment', 'View Uploads']
const REVIEWER_TABS = ['Review Payment']
const APPROVAL_TABS = ['Approve Payment']
const ACTIVE_USER_PERMISSIONS = ['initiate customer upload', 'review transaction', 'approve transaction']

export default function BulkCreditCustomer() {
    const [showConfirmPaymentModal, setShowConfirmPaymentModal] = useState(false)
    const [showRejectUploadModal, setShowRejectUploadModal] = useState(false)
    const [selectedFile, setSelectedFile] = useState<any>("")
    const [fileError, setFileError] = useState("")
    const [activeTab, setActiveTab] = useState<ACTIVE_TABS_TYPE>('upload')
    const [selectedPaymentItemId, setSelectedPaymentItemId] = useState('')
    const [selectedTab, setSelectedTab] = useState('')
    const [currentTabs, setCurrentTabs] = useState(APPROVAL_TABS)
    const [userPermission, setUserPermission] = useState(ACTIVE_USER_PERMISSIONS[2])
    const loginState = useAppSelector(state => state.login);
    const {
        data: { name },
    } = useAppSelector(state => state.auth);
    const uploadPaymentListState = useAppSelector(state => state.uploadPaymentList)
    const { status: uploadPaymentStatus } = uploadPaymentListState;

    const reviewPaymentUploadState = useAppSelector((state) => state.reviewPaymentUpload)
    const { status: reviewPaymentUploadStatus } = reviewPaymentUploadState

    const approvePaymentUploadState = useAppSelector((state) => state.approvePaymentUpload)
    const {status: approvePaymentUploadStatus } = approvePaymentUploadState

    let CURRENT_USER_PERMISSION = loginState?.data?.user?.permissions?.map((d: Dictionary) => d.name)

    const dispatch = useAppDispatch()

    const handleDownloadPaymentTemplate = () => {
        dispatch(downloadPaymentListTemplateRequest())
    }

    // validate selected file (excel files allowed, max file 5mb)
    const isValidSelectedFile = (file: File) => {
        if (
            file.type === 'application/vnd.ms-excel' ||
            file.name.endsWith('.xls') ||
            file.name.endsWith('.xlsx')
        ) {
            if (file.size > 5 * 1024 * 1024) {
                setFileError('selected file exceeds the maximum allowed size (5MB)');
                return;
            } else {
                return true
            }
        } else {
            setFileError("supported file type .xlsx, .xls")
        }
    }

    // handle browse file
    const handleSetFile = (file: File) => {
        if (isValidSelectedFile(file)) {
            setSelectedFile(file)
            setFileError("")
        } else {
            setSelectedFile('')
        }
    }

    // handle drag and drop
    const handleDrop = (file: File) => {
        console.log(file)
        if (isValidSelectedFile(file)) {
            setSelectedFile(file)
            setFileError("")
        } else {
            setSelectedFile('')
        }
    }

    const handleFileUpload = () => {
        let formdata = new FormData()
        formdata.append('customers', selectedFile)
        formdata.append('type', 'credit')
        let payload = { customers: selectedFile, type: 'credit' }
        dispatch(uploadPaymentListRequest(formdata))
    }


    useEffect(() => {
        if (CURRENT_USER_PERMISSION.includes(ACTIVE_USER_PERMISSIONS[0])) {
            setCurrentTabs(INITIATOR_TABS)
        }
        if (CURRENT_USER_PERMISSION.includes(ACTIVE_USER_PERMISSIONS[1])) {
            setCurrentTabs(REVIEWER_TABS)
        }
        if (CURRENT_USER_PERMISSION.includes(ACTIVE_USER_PERMISSIONS[2])) {
            setCurrentTabs(APPROVAL_TABS)
        }
    }, [])

    useEffect(() => {
        if (reviewPaymentUploadStatus === 'succeeded' || approvePaymentUploadStatus === 'succeeded') {
            setActiveTab && setActiveTab('')
        }
    }, [reviewPaymentUploadStatus, approvePaymentUploadStatus])

    useEffect(() => {
        setFileError("")
        setSelectedFile("")
    }, [selectedTab])

    return (
        <>
            {/* Upload Success Modal */}
            <ZojaModal
                show={uploadPaymentStatus === "succeeded"}
                handleClose={() => {
                    setSelectedFile("")
                    dispatch(uploadPaymentListReset())
                }}
                height='auto'
                position='center'
                extraClass="md:tw-w-[26rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto tw-mt-12"
                borderRadius=".5rem"
            >
                <div className='tw-mx-3 tw-py-5  md:tw-mx-12 tw-p-4'>
                    {/* <h3 className='tw-text-[.9rem] tw-text-[#263238]'>Success!</h3> */}
                    <img src={images.successIconGif} alt="" />
                    <h3 className='tw-text-[1.2rem] tw-text-center tw-text-[#263238]'>File Upload successful</h3>
                    <span className="tw-block tw-text-center tw-text-[.7rem]">Data is currently being reviewed</span>

                    <span className='tw-absolute tw-top-2 tw-right-2 tw-cursor-pointer'
                        onClick={() => {
                            setSelectedFile("")
                            dispatch(uploadPaymentListReset())
                        }}
                    ><icons.MdClose /></span>
                    <div className='my-3 mt-4'>
                        <ZojaButton
                            onClick={() => {
                                setSelectedFile("")
                                dispatch(uploadPaymentListReset())
                            }}
                            text="Close"
                            // loading
                            // disabled
                            extraClass='tw-bg-isPrimary tw-grid tw-place-content-center tw-text-white tw-font-thin tw-text-sm tw-rounded-[4px] tw-w-full tw-p-2 tw-mt-4 hover:tw-bg-isPrimary/90 disabled:tw-bg-blue-900'
                        />
                    </div>
                </div>
            </ZojaModal>

            <ConfirmPayment
                show={showConfirmPaymentModal}
                handleClose={() => setShowConfirmPaymentModal(false)}
                tab={selectedTab}
                selectedPaymentItemId={selectedPaymentItemId}
            />
            <RejectPaymentFileUpload
                show={showRejectUploadModal}
                handleClose={() => setShowRejectUploadModal(false)}
                tab={selectedTab}
                selectedPaymentItemId={selectedPaymentItemId}
            />
            <PaymentContainer
                title="Note: Payments are processed from the Zojapay settlement account, to create a payment proceed below"
            >
                <>
                    <div className="tw-mt-4 tw-flex tw-flex-wrap tw-items-center tw-gap-8 tw-border-b tw-pb-1 tw-w-fit">
                        {
                            currentTabs.map((tab, idx) => (
                                <span key={idx} className={cn(
                                    "tw-text-sm tw-cursor-pointer text-[#7E7E96] tw-relative",
                                    selectedTab === tab && "tw-font-bold tw-text-isPrimary"
                                )}
                                    onClick={() => {
                                        setSelectedTab(tab)
                                        setActiveTab("")
                                    }}
                                >{tab}
                                    {selectedTab === tab && <span className="tw-h-[.15rem] tw-w-full tw-absolute tw-bg-isPrimary tw-left-0 -tw-bottom-1.5 tw-rounded-md"></span>}
                                </span>
                            ))
                        }
                    </div>

                    <div className="tw-mt-8 tw-text-xs">
                        <PaymentPageTitle
                            // title="Send to Multiple Accounts"
                            title={
                                activeTab === "view" ? "Review Uploads" :
                                    selectedTab === "Initiate Payment" ? "Send to Multiple Accounts" :
                                        selectedTab === "View Uploads" ? "View Uploaded List" :
                                            selectedTab === "Review Payment" ? "Review Payment" :
                                                selectedTab === "Approve Payment" ? "Approve Payment" : ""

                            }
                            contentRight={<>
                                {selectedTab === 'Initiate Payment' && <div className="tw-flex tw-items-center tw-gap-6">
                                    <span>Dont have the document format?</span>
                                    <span className="tw-flex tw-items-center tw-gap-2 tw-text-isPrimary tw-cursor-pointer" onClick={handleDownloadPaymentTemplate}><Download width={18} height={18} /> <span className="tw-hidden lg:tw-inline-block">Download Template</span> </span>
                                </div>}
                                {activeTab === "view" && selectedPaymentItemId &&
                                    (CURRENT_USER_PERMISSION.includes(ACTIVE_USER_PERMISSIONS[1]) || CURRENT_USER_PERMISSION.includes(ACTIVE_USER_PERMISSIONS[2])) &&
                                    <div className="tw-flex tw-items-center tw-gap-2">
                                        <ZojaButton
                                            text="Reject Upload"
                                            extraClass="tw-p-[.3rem] tw-px-8 tw-text-[.7rem] tw-font-normal tw-bg-transparent tw-text-red-600 tw-rounded-full hover:tw-bg-red-500 hover:tw-text-white"
                                            onClick={() => setShowRejectUploadModal(true)}
                                        />
                                        <ZojaButton
                                            text={
                                                CURRENT_USER_PERMISSION.includes(ACTIVE_USER_PERMISSIONS[1]) ? "Send for Approval" :
                                                'Approve'
                                            }
                                            extraClass="tw-p-[.3rem] tw-px-6 tw-text-[.7rem] tw-font-thin tw-bg-isPrimary tw-text-white tw-rounded-full"
                                            onClick={() => setShowConfirmPaymentModal(true)}
                                        />
                                    </div>}
                            </>}
                        />

                        {selectedTab === "Initiate Payment"
                            && <>
                                <CustomFileUploader
                                    selectedFile={selectedFile}
                                    setSelectedFile={handleSetFile}
                                    // handleDragOver={handleSetFile}
                                    handleDrop={handleDrop}
                                    error={fileError}
                                    handleUpload={handleFileUpload}
                                    loading={uploadPaymentStatus === 'loading'}
                                />
                            </>
                        }

                        {selectedTab === "" && <PaymentEmptyState
                            text="There is currently no data for you to review"
                        />}

                        {
                            (selectedTab === "Review Payment"
                                || selectedTab === "Approve Payment"
                                || selectedTab === "View Uploads"
                            )
                            && activeTab !== "view" &&
                            <BatchPaymentTable
                                tab={selectedTab}
                                setActiveTab={setActiveTab}
                                handleItemClick={(data) => {
                                    setSelectedPaymentItemId(data)
                                    setActiveTab("view")
                                }}
                                allowed_permissions={ACTIVE_USER_PERMISSIONS}
                            />
                            // <UploadedPaymentList
                            //     role={ACTIVE_USER_PERMISSIONS[0]}
                            //     handleItemClick={(data) => {
                            //         setActiveTab("view")
                            //     }}
                            // />
                        }

                        {activeTab === "view" && selectedPaymentItemId && 
                        <PaymentTableList 
                            selectedPaymentItemId={selectedPaymentItemId} 
                            setActiveTab={setActiveTab}
                        />}
                    </div>
                </>
            </PaymentContainer>
        </>
    )
}
