import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { BsFunnel } from "react-icons/bs";
import { CustomDatePicker, CustomTimePicker, ExportLabel, FilterCard, Pagination, PaymentEmptyState, StatusLabel, ZojaTable } from "../..";
import { useAppDispatch, useAppSelector } from "../../../redux/redux-hooks";
import { downloadCustomerAccountStatementRequest, getCustomerAccountStatement, getUploadedPaymentListRequest } from "../../../redux/slice";
import { Dictionary } from "../../../types";
import { currencyFormat, useSortTable } from "../../../utils";
import { ZojaButton, ZojaSelect } from "../../tailwind";
import { TD, TR } from "../../tailwind/table/tableElements";
import { StatusLoader } from "../../../atoms";
import { cn } from "../../../lib/utils";

interface BatchPaymentTableProps {
    tab: string,
    handleItemClick: (event: any) => void
    handleActionClick?: (event: any) => void,
    setActiveTab?: (event: any) => void,
    allowed_permissions: any,
    sorting?: any;
    order?: string;
}

const tableColumns = [
    {
        id: 1,
        title: '#',
    },
    {
        id: 2,
        title: 'Date & Time',
        value: 'created_at',
        hasSortIcon: true,
    },
    {
        id: 3,
        title: 'Uploaded Document',
        value: 'name',
        hasSortIcon: true,
    },
    {
        id: 4,
        title: 'Review Status',
        value: 'review_status',
        hasSortIcon: true,
    },
    {
        id: 5,
        title: 'Approval Status',
        value: 'approval_status',
        hasSortIcon: true,
    },
    {
        id: 6,
        title: 'Processing Status',
        value: 'processing_status',
        hasSortIcon: true,
    },
    {
        id: 7,
        title: 'Rejection Reason',
        value: 'review_rejection_message',
        hasSortIcon: true,
    }
]

const initiatorColumns = [
    {
        id: 1,
        title: '#',
    },
    {
        id: 2,
        title: 'Date & Time',
        value: 'created_at',
        hasSortIcon: true,
    },
    {
        id: 3,
        title: 'Uploaded Document',
        value: 'name',
        hasSortIcon: true,
    },
    {
        id: 4,
        title: 'Review Status',
        value: 'review_status',
        hasSortIcon: true,
    },
    {
        id: 5,
        title: 'Rejection Reason',
        value: 'review_rejection_message',
        hasSortIcon: true,
    },
    {
        id: 6,
        title: 'Approval Status',
        value: 'approval_status',
        hasSortIcon: true,
    },
    {
        id: 7,
        title: 'Processing Status',
        value: 'processing_status',
        hasSortIcon: true,
    },
]
const reviewerTableColumns = [
    {
        id: 1,
        title: '#',
    },
    {
        id: 2,
        title: 'Date & Time',
        value: 'created_at',
        hasSortIcon: true,
    },
    {
        id: 3,
        title: 'Uploaded Document',
        value: 'name',
        hasSortIcon: true,
    },
    {
        id: 4,
        title: 'Review Status',
        value: 'review_status',
        hasSortIcon: true,
    },
    {
        id: 5,
        title: 'Rejection Reason',
        hasSortIcon: true,
    },
    {
        id: 6,
        title: 'Approval Status',
        value: 'approval_status',
        hasSortIcon: true,
    },
    {
        id: 7,
        title: 'Processing Status',
        value: 'processing_status',
        hasSortIcon: true,
    },
]
const approvalTableColumns = [
    {
        id: 1,
        title: '#',
    },
    {
        id: 2,
        title: 'Date & Time',
        value: 'created_at',
        hasSortIcon: true,
    },
    {
        id: 3,
        title: 'Uploaded Document',
        value: 'name',
        hasSortIcon: true,
    },
    {
        id: 4,
        title: 'Review Status',
        value: 'review_status',
        hasSortIcon: true,
    },
    {
        id: 5,
        title: 'Rejection Reason',
        value: 'review_rejection_message',
        hasSortIcon: true,
    },
    {
        id: 6,
        title: 'Approval Status',
        value: 'approval_status',
        hasSortIcon: true,
    },
    {
        id: 7,
        title: 'Processing Status',
        value: 'processing_status',
        hasSortIcon: true,
    },
]

const STATUS_COLOR: Dictionary = {
    PENDING: 'tw-text-yellow-500',
    APPROVED: 'tw-text-green-500',
    REJECTED: 'tw-text-red-500',
}

export default function BatchPaymentTable({ tab, handleItemClick, setActiveTab, allowed_permissions }: BatchPaymentTableProps) {
    const [activeTableColumn, setActiveTableColumn] = useState<any>(tableColumns);
    const [batchPaymentData, setBatchPaymentData] = useState<any[]>([]);
    const [order, setOrder] = useState('ASC');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(5);
    const [showExportOptions, setShowExportOptions] = useState(false);
    const [showFilterOptions, setShowFilterOptions] = useState(false);
    const [dateRange, setDateRange] = useState({ state_date: '', end_date: '' });
    const [payload, setPayload] = useState({
        page: 1,
        start_date: '',
        end_date: '',
        time: '',
        per_page: 10,
    })
    let timeRef = useRef(null)

    const customerAccountStatement = useAppSelector((state) => state.customerAccountStatement)
    const downloadCustomerAccountStatement = useAppSelector((state) => state.downloadCustomerAccountStatement)

    const loginState = useAppSelector(state => state.login);
    let CURRENT_USER_PERMISSION = loginState?.data?.user?.permissions?.map((d: Dictionary) => d.name)
    // console.log(loginState?.data?.user?.permissions)

    const getUploadPaymentListState = useAppSelector((state) => state.getUploadedPaymentList)
    const { status: getUploadPaymentListStatus } = getUploadPaymentListState;
    const reviewPaymentUploadState = useAppSelector((state) => state.reviewPaymentUpload)
    const { status: reviewPaymentUploadStatus } = reviewPaymentUploadState


    const dispatch = useAppDispatch()

    // handle download kyc records
    const handleExportFile = (type: string) => {
        let filters = customerAccountStatement.filters
        dispatch(downloadCustomerAccountStatementRequest({
            ...filters,
            export: type
        }))
        setShowExportOptions(false);
    };

    // handle page number change
    const handlePageNumberChange = (pageNumber: any) => {
        let filters = getUploadPaymentListState.filters
        dispatch(
            getUploadedPaymentListRequest({
                ...payload, per_page: pageNumber,
                ...filters,
                page: getUploadPaymentListState?.data?.bulk_imports?.meta?.current_page,
            }),
        );
    }

    // filter table handler
    const handleSubmitFilter = () => {
        dispatch(
            getCustomerAccountStatement({
                ...payload, per_page: payload?.per_page,
                page: currentPage,
                account_number: customerAccountStatement?.data?.account_info?.account_number,
                start_date: dateRange.state_date,
                end_date: dateRange.end_date,
                time: timeRef.current || ''
            }),
        );
    };

    // Handle table sorting
    // Custom Hooks
    const { handleSortItems } = useSortTable({
        order: order,
        setOrder,
        data: batchPaymentData,
        setData: setBatchPaymentData,
    });

    useEffect(() => {
        if (getUploadPaymentListState?.data?.bulk_imports?.data?.length > 0) {
            let updateData: any[] = [];
            getUploadPaymentListState?.data?.bulk_imports?.data.forEach((item: Dictionary, index: number) => {
                updateData.push({
                    id: index + 1,
                    uuid: item.id,
                    created_at: item.created_at,
                    name: item.name,
                    review_status: item?.review_status,
                    approval_status: item?.approval_status,
                    processing_status: item?.processing_status,
                    review_rejection_message: item?.review_rejection_message,
                });
                // console.log(item)
            });

            setBatchPaymentData(updateData);
            const {
                last_page,
            } = getUploadPaymentListState?.data?.bulk_imports?.meta;
            setTotalPages(last_page);
            setPayload({ ...payload, page: currentPage });
        }
    }, [getUploadPaymentListState?.data, currentPage]);

    // Format table data
    // useEffect(() => {
    //     if (getUploadPaymentListStatus === 'succeeded') {
    //         let updateData: any[] = [];
    //         getUploadPaymentListState?.data?.bulk_imports?.data.forEach((item: Dictionary, index: number) => {
    //             updateData.push({
    //                 id: index + 1,
    //                 created_at: item.created_at,
    //                 name: item.name,
    //                 review_status: item?.review_status,
    //                 approval_status: item?.approval_status,
    //                 processing_status: item?.processing_status,
    //                 review_rejection_message: item?.review_rejection_message,
    //             });
    //         });

    //         setBatchPaymentData(updateData);
    //     }
    // }, [getUploadPaymentListState, currentPage]);

    useEffect(() => {
        // dispatch(getUploadedPaymentListReset())
        dispatch(getUploadedPaymentListRequest({ page: currentPage, per_page: 10 }))
    }, [reviewPaymentUploadStatus])

    useEffect(() => {
        if (CURRENT_USER_PERMISSION.includes(allowed_permissions[0])) {
            setActiveTableColumn(initiatorColumns)
        }
        if (CURRENT_USER_PERMISSION.includes(allowed_permissions[1])) {
            setActiveTableColumn(reviewerTableColumns)
        }
        if (CURRENT_USER_PERMISSION.includes(allowed_permissions[2])) {
            setActiveTableColumn(approvalTableColumns)
        }
    }, [])

    return (
        <>
            {
                getUploadPaymentListStatus === "loading" ?
                    <StatusLoader status={getUploadPaymentListStatus} width={'80'} height={'80'} wrapperHeight={'tw-h-[60vh]'} showEmptyState={false} /> :
                    getUploadPaymentListStatus === "succeeded" && getUploadPaymentListState?.data?.bulk_imports?.data?.length ?
                        <>
                            {/* {(showExportOptions || showFilterOptions) && (
                                <div
                                    className="tw-absolute tw-bg-black/0 tw-cursor-pointer tw-z-30 tw-w-full tw-h-screen
            tw-top-0 tw-left-0"
                                    onClick={() => {
                                        setShowExportOptions(false);
                                        setShowFilterOptions(false)
                                    }}></div>
                            )} */}
                            <div className={`
                tw-mt- tw-borde tw-rounded-m tw-bg-white tw-p-6 tw-shadow-zojaShadowNine`}>
                                {/* <div className="tw-flex tw-flex-col tw-justify-between lg:tw-items-center lg:tw-flex-row">
                        <h6 className="tw-font-normal tw-uppercase tw-text-sm">Transactions</h6>
                        <div className="tw-flex tw-justify-between tw-items-center tw-gap-x-3">
                            <div className="tw-flex tw-flex-col lg:tw-gap-4 lg:tw-flex-row">
                                <div className="tw-flex tw-gap-3">
                                    <div className="tw-mb-2">
                                        <CustomDatePicker
                                            label="State Date"
                                            onOpenPickNewDate={false}
                                            value={dateRange.state_date}
                                            setValue={value => setDateRange({ ...dateRange, state_date: value })}
                                        />
                                    </div>
                                    <div className="tw-mb-4">
                                        <CustomDatePicker
                                            label="End Date"
                                            onOpenPickNewDate={false}
                                            value={dateRange.end_date}
                                            setValue={value => setDateRange({ ...dateRange, end_date: value })}
                                        />
                                    </div>
                                </div>
                                <div className="tw-flex tw-gap-1">
                                    <div className="tw-mb-4">
                                        <CustomTimePicker
                                            label="Time"
                                            onOpenPickNewDate={true}
                                            value={new Date()}
                                            setValue={value => timeRef.current = value}
                                        />
                                    </div>
                                    <ZojaButton
                                        text={<BsFunnel />}
                                        extraClass={
                                            `tw-bg-isPrimary tw-text-gray-200 tw-border tw-p-2 tw-h-fit tw-rounded-sm tw-cursor-pointer tw-mt-[1.1rem]
                                            `
                                        }
                                        onClick={handleSubmitFilter}
                                    // loading
                                    />
                                    <div className="tw-mt-4">
                                        <ExportLabel
                                            title=""
                                            onClick={() => setShowExportOptions(true)}
                                            loading={downloadCustomerAccountStatement.status === 'loading'}
                                        >
                                            <FilterCard
                                                showFilter={showExportOptions}
                                                title="Export as"
                                                onClick={() => null}
                                                showSubmitButton={false}
                                                extraClass="tw-w-[10rem] -tw-left-28">
                                                <div className="tw-flex tw-flex-col tw-mt-4">
                                                    <span
                                                        className="tw-text-xs tw-cursor-pointer tw-text-isGrey"
                                                        onClick={() => handleExportFile('csv')}>
                                                        CSV
                                                    </span>
                                                </div>
                                            </FilterCard>
                                        </ExportLabel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                                <div>
                                    <ZojaTable
                                        column={activeTableColumn}
                                        sorting={handleSortItems}
                                        order={order}
                                        thClass="tw-text-gray-600 tw-font-semibold"
                                    >
                                        {
                                            batchPaymentData?.map((data: Dictionary, idx: number) => (
                                                <TR key={idx} onClick={() => handleItemClick(data.uuid)}>
                                                    <TD value={idx + 1} extraClass="tw-text-gray-500" />
                                                    <TD value={moment(data.created_at).format('D-MM-YYYY hh:mm a')} extraClass="tw-text-gray-500 tw-lowercase" />
                                                    <TD value={data.name} extraClass="tw-text-gray-500" />
                                                    <TD value={data.review_status} extraClass={cn(
                                                        `tw-text-center tw-text-xs`,
                                                        data.review_status && `${STATUS_COLOR[data.review_status]}`,
                                                    )} />
                                                    <TD
                                                        value={data.review_rejection_message ? data.review_rejection_message : 'NA'}
                                                    />
                                                    <TD value={data.approval_status} extraClass={cn(
                                                        `tw-text-center tw-text-xs`,
                                                        data.approval_status && `${STATUS_COLOR[data.approval_status]}`,
                                                        // (!CURRENT_USER_PERMISSION.includes(allowed_permissions[1]) && !CURRENT_USER_PERMISSION.includes(allowed_permissions[2]) && 'tw-hidden')
                                                    )} />
                                                    <TD value={data.processing_status} extraClass={cn(
                                                        "tw-text-isPrimary tw-text-center tw-text-xs",
                                                        data.processing_status && `${STATUS_COLOR[data.processing_status]}`,
                                                        // !CURRENT_USER_PERMISSION.includes(allowed_permissions[2]) && 'tw-hidden'
                                                    )} />
                                                </TR>
                                            ))
                                        }
                                        {/* {tab === 'View Uploads' &&
                                            getUploadPaymentListState?.data?.bulk_imports?.data?.filter((f: Dictionary) => (f.review_status === 'PENDING' || f.review_status === 'REJECTED'))?.map((data: Dictionary, idx: number) => (
                                                <TR key={idx} onClick={() => handleItemClick(data.id)}>
                                                    <TD value={idx + 1} extraClass="tw-text-gray-500" />
                                                    <TD value={moment(data.created_at).format('D-MM-YYYY hh:mm a')} extraClass="tw-text-gray-500 tw-lowercase" />
                                                    <TD value={data.name} extraClass="tw-text-gray-500" />
                                                    <TD value={data.review_status} extraClass={cn(
                                                        `tw-text-center tw-text-xs`,
                                                        data.review_status && `${STATUS_COLOR[data.review_status]}`,
                                                    )} />
                                                    <TD value={data.approval_status} extraClass={cn(
                                                        `tw-text-center tw-text-xs`,
                                                        data.approval_status && `${STATUS_COLOR[data.approval_status]}`,
                                                        (!CURRENT_USER_PERMISSION.includes(allowed_permissions[1]) && !CURRENT_USER_PERMISSION.includes(allowed_permissions[2]) && 'tw-hidden')
                                                    )} />
                                                    <TD value={data.processing_status} extraClass={cn(
                                                        "tw-text-isPrimary tw-text-center tw-text-xs",
                                                        data.processing_status && `${STATUS_COLOR[data.processing_status]}`,
                                                        !CURRENT_USER_PERMISSION.includes(allowed_permissions[2]) && 'tw-hidden'
                                                    )} />
                                                    <TD
                                                        value={data.review_rejection_message ? data.review_rejection_message : 'NA'}
                                                    />
                                                </TR>
                                            ))
                                        }
                                        {tab === 'Review Payment' &&
                                            batchPaymentData?.filter((f: Dictionary) => (f.review_status === 'PENDING'))?.map((data: Dictionary, idx: number) => (
                                                <TR key={idx} onClick={() => handleItemClick(data.id)}>
                                                    <TD value={idx + 1} extraClass="tw-text-gray-500" />
                                                    <TD value={moment(data.created_at).format('D-MM-YYYY hh:mm a')} extraClass="tw-text-gray-500 tw-lowercase" />
                                                    <TD value={data.name} extraClass="tw-text-gray-500" />
                                                    <TD value={data.review_status} extraClass={cn(
                                                        `tw-text-center tw-text-xs`,
                                                        data.review_status && `${STATUS_COLOR[data.review_status]}`,
                                                    )} />
                                                    <TD value={data.approval_status} extraClass={cn(
                                                        `tw-text-center tw-text-xs`,
                                                        data.approval_status && `${STATUS_COLOR[data.approval_status]}`,
                                                        (!CURRENT_USER_PERMISSION.includes(allowed_permissions[1]) && !CURRENT_USER_PERMISSION.includes(allowed_permissions[2]) && 'tw-hidden')
                                                    )} />
                                                    <TD value={data.processing_status} extraClass={cn(
                                                        "tw-text-isPrimary tw-text-center tw-text-xs",
                                                        data.processing_status && `${STATUS_COLOR[data.processing_status]}`,
                                                        !CURRENT_USER_PERMISSION.includes(allowed_permissions[2]) && 'tw-hidden'
                                                    )} />
                                                    <TD
                                                        value={data.review_rejection_message ? data.review_rejection_message : 'NA'}
                                                    />
                                                </TR>
                                            ))
                                        }
                                        {tab === 'Approve Payment' &&
                                            batchPaymentData?.filter((f: Dictionary) => (f.review_status === 'APPROVED'))?.map((data: Dictionary, idx: number) => (
                                                <TR key={idx} onClick={() => handleItemClick(data.id)}>
                                                    <TD value={idx + 1} extraClass="tw-text-gray-500" />
                                                    <TD value={moment(data.created_at).format('D-MM-YYYY hh:mm a')} extraClass="tw-text-gray-500 tw-lowercase" />
                                                    <TD value={data.name} extraClass="tw-text-gray-500" />
                                                    <TD value={data.review_status} extraClass={cn(
                                                        `tw-text-center tw-text-xs`,
                                                        data.review_status && `${STATUS_COLOR[data.review_status]}`,
                                                    )} />
                                                    <TD value={data.approval_status} extraClass={cn(
                                                        `tw-text-center tw-text-xs`,
                                                        data.approval_status && `${STATUS_COLOR[data.approval_status]}`,
                                                        (!CURRENT_USER_PERMISSION.includes(allowed_permissions[1]) && !CURRENT_USER_PERMISSION.includes(allowed_permissions[2]) && 'tw-hidden')
                                                    )} />
                                                    <TD value={data.processing_status} extraClass={cn(
                                                        "tw-text-isPrimary tw-text-center tw-text-xs",
                                                        data.processing_status && `${STATUS_COLOR[data.processing_status]}`,
                                                        !CURRENT_USER_PERMISSION.includes(allowed_permissions[2]) && 'tw-hidden'
                                                    )} />
                                                    <TD
                                                        value={data.review_rejection_message ? data.review_rejection_message : 'NA'}
                                                    />
                                                </TR>
                                            ))
                                        } */}
                                    </ZojaTable>
                                </div>
                                <div className="tw-pb-2 tw-flex tw-flex-col tw-items-center tw-gap-3 md:tw-pb-8 md:tw-flex-row">
                                    <Pagination
                                        isLoading={false}
                                        currentPage={getUploadPaymentListState?.data?.bulk_imports?.meta?.current_page}
                                        totalPages={totalPages}
                                        onPageChange={selectedPage => {
                                            setCurrentPage(selectedPage);
                                            let currentPage = selectedPage;
                                            let filterBy = {
                                                ...payload,
                                                per_page: getUploadPaymentListState?.data?.bulk_imports?.meta?.per_page,
                                                page: currentPage,
                                            };
                                            dispatch(getUploadedPaymentListRequest(filterBy));
                                        }}
                                    />
                                    <div className="-tw-mt-12 tw-flex tw-items-center tw-h-fit">
                                        <span className="tw-text-xs tw-w-16">Per page</span>
                                        <ZojaSelect
                                            options={[
                                                { label: '10', value: '10' },
                                                { label: '25', value: '25' },
                                                { label: '50', value: '50' },
                                            ]}
                                            value={getUploadPaymentListState?.data?.bulk_imports?.meta?.per_page}
                                            setSelectedOption={(selected) => handlePageNumberChange(selected)}
                                            extraClass="tw-rounded-none tw-text-xs tw-py-2 tw-w-[2.5rem]"
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <PaymentEmptyState
                            text="There is currently no data for you to view"
                        />
            }
        </>
    )
}
