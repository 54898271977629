import { useEffect, useRef, useState } from 'react';
import { Oval } from 'react-loader-spinner';
import {
  CustomDatePicker,
  DownloadSuccessModal,
  ExportLabel,
  FilterCard,
  FilterColapsible,
  FilterLabel,
  KycTable,
  Pagination,
  SingleCountCard,
  ZojaCheckbox,
} from '../../components';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import {
  downloadKycCustomer,
  downloadKycCustomerReset,
  getKycsAnalyticsRequest,
  getKycsRequest,
} from '../../redux/slice';
import { Dictionary } from '../../types';
import {
  formatStartAndEndDateWithDelimeter,
  getSelectedOptionValue,
  handleSelectedOption,
  icons,
  images,
  useSortTable,
} from '../../utils';
import { ZojaSelect } from '../../components/tailwind';

const KycPageTabs: Dictionary = [
  {
    id: 1,
    title: 'Verified Users',
    url: 'verified_users',
  },
  {
    id: 2,
    title: 'Pending Verifications',
    url: 'pending_verifications',
  },
  {
    id: 3,
    title: 'Rejected Verifications',
    url: 'rejected_verifications',
  },
];

type TabType = 'Level 1' | 'Level 2' | 'Level 3' | 'Agency' | '';

const activeType: TabType[] = ['Level 1', 'Level 2', 'Level 3', 'Agency'];

const KycCountData = [
  {
    id: 1,
    helper: activeType[0],
    title: 'Level 1',
    total: 0,
    subtitle: 'Total Records',
    slug: '',
  },
  {
    id: 2,
    helper: activeType[1],
    title: 'Level 2',
    total: 0,
    subtitle: 'Total Records',
    slug: '',
  },
  {
    id: 3,
    helper: activeType[2],
    title: 'Level 3',
    total: 0,
    subtitle: 'Total Records',
    slug: '',
  },
  {
    id: 4,
    helper: activeType[3],
    title: 'Agency',
    total: 0,
    subtitle: 'Total Records',
    slug: '',
  },
];

const verifiedKycLevelOne = '/verified?level=level one';
const verifiedKycLevelTwo = '/verified?level=level two';
const verifiedKycLevelThree = '/verified?level=level three';
const verifiedKycAgency = '/fetchAgentVerification?status=approved&include=bvn';

const kycLevelZero = '?level=level zero&include=bvn,verifications';
const kycLevelOne = '?level=level one&include=bvn,verifications';
const kycLevelTwo = '?level=level two&include=bvn,verifications';
const kycLevelAgency = '/fetchAgentVerification?&include=bvn,verifications';

const sortByOtions = {
  aphabetically: [
    {
      id: 1,
      value: 'az',
      title: 'A-Z',
      isChecked: false,
    },
    {
      id: 2,
      value: 'za',
      title: 'Z-A',
      isChecked: false,
    },
  ],
  orderBy: [
    {
      id: 1,
      value: 'newest',
      title: 'Newest',
      isChecked: false,
    },
    {
      id: 2,
      value: 'oldest',
      title: 'Oldest',
      isChecked: false,
    },
  ],
};

const memberSinceOptions = [
  {
    id: 1,
    value: 'one_week',
    title: 'One week',
    isChecked: false,
  },
  {
    id: 2,
    value: 'thirty_days',
    title: '30 Days',
    isChecked: false,
  },
  {
    id: 3,
    value: 'six_months',
    title: '6 Months',
    isChecked: false,
  },
  {
    id: 4,
    value: 'custom',
    title: 'Custom',
    isChecked: false,
  },
];

const initialValues: Dictionary = {
  sort_by: '',
  order_by: '',
  date_range: '',
  term: '',
};

const FILTER_OPTION_TYPES: Dictionary = {
  identity_type: 'identity_type',
  verification_entry: 'verification_entry',
  bill_type: 'bill_type',
};

const KycContainer = () => {
  const [activePageTab, setActivePageTab] = useState('verified_users');
  const [activeTab, setActiveTab] = useState('');
  const [kycData, setKycData] = useState<any[]>([]);
  const [kycCountList, setKycCountList] = useState<any[]>(KycCountData);
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(5);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [showSortBy, setShowSortBy] = useState(false);
  const [showExportOptions, setShowExportOptions] = useState(false);
  const [sortBy, setSortBy] = useState(sortByOtions.aphabetically);
  const [orderBy, setOrderBy] = useState(sortByOtions.orderBy);
  const [showDateOnboarded, setShowDateOnboarded] = useState(false);
  const [dateOnboarded, setDateOnboarded] = useState(memberSinceOptions);
  const [filterOptions, setFilterOptions] = useState(initialValues);
  const [onboardedDateRange, setOnboardedDateRange] = useState({ state_date: '', end_date: '' });
  const [showSuccessDownloadModal, setShowSuccessDownloadModal] = useState(false);

  const [order, setOrder] = useState('ASC');

  const kycsAnalyticsState = useAppSelector(state => state.getKycsAnalytics);
  const { status: kycsAnalyticsStatus } = kycsAnalyticsState;
  const kycsState = useAppSelector(state => state.getKycs);
  const { status: kycsStatus } = kycsState;

  // download records status states
  const { status: downloadStatus } = useAppSelector(state => state.downloadKycCustomer);
  const { handleSortItems } = useSortTable({ order: order, setOrder, data: kycData, setData: setKycData });

  const pageSize = 10;

  const dispatch = useAppDispatch();
  const successModalRef = useRef<any>(null);

  // filter options parameters
  const identityTypeParam = `filter_option[${FILTER_OPTION_TYPES.identity_type}]`;
  const verificationEntryParam = `filter_option[${FILTER_OPTION_TYPES.verification_entry}]`;
  const billTypeParam = `filter_option[${FILTER_OPTION_TYPES.bill_type}]`;

  // Sort Table Header
  const sorting = (col: string) => {
    if (order === 'ASC') {
      const sorted = [...kycData].sort((a, b) => (a[col]?.toLowerCase() > b[col]?.toLowerCase() ? 1 : -1));
      setKycData(sorted);
      setOrder('DSC');
    }
    if (order === 'DSC') {
      const sorted = [...kycData].sort((a, b) => (a[col]?.toLowerCase() < b[col]?.toLowerCase() ? 1 : -1));
      setKycData(sorted);

      setOrder('ASC');
    }
  };

  // handle download kyc records
  const handleExportFile = (type: string) => {
    const onboarded_date_range = formatStartAndEndDateWithDelimeter(
      onboardedDateRange.state_date,
      onboardedDateRange.end_date,
    );
    let payload = { ...filterOptions };
    payload = payload.date_range === 'custom' ? { ...payload, date_range: onboarded_date_range } : payload;
    // console.log(payload)
    const status =
      activePageTab === 'pending_verifications'
        ? 'pending'
        : activePageTab === 'rejected_verifications'
        ? 'rejected'
        : activePageTab === 'verified_users'
        ? 'approved'
        : '';
    dispatch(
      downloadKycCustomer({
        kycLevel: `${kycLevel.path}&status=${status}`,
        // per_page: pageSize,
        // page: currentPage,
        filter: payload,
        export: type,
      }),
    );
    setShowExportOptions(false);
  };

  // filter table handler
  const handleSubmitFilter = () => {
    const onboarded_date_range = formatStartAndEndDateWithDelimeter(
      onboardedDateRange.state_date,
      onboardedDateRange.end_date,
    );
    let payload = { ...filterOptions };
    payload = payload.date_range === 'custom' ? { ...payload, date_range: onboarded_date_range } : payload;
    // console.log(payload)
    const status =
      activePageTab === 'pending_verifications'
        ? 'pending'
        : activePageTab === 'rejected_verifications'
        ? 'rejected'
        : activePageTab === 'verified_users'
        ? 'approved'
        : '';
    dispatch(
      getKycsRequest({
        kycLevel: `${kycLevel.path}&status=${status}`,
        per_page: pageSize,
        page: currentPage,
        filter: payload,
      }),
    );
  };

  const setActiveTabHandler = (tab: string) => setActiveTab(tab);

  function determineKycToFetch() {
    let result: Dictionary;

    // Tab View for verified user
    if (activePageTab === 'verified_users') {
      if (activeTab === '') {
        result = { path: verifiedKycLevelOne, level: 'LEVEL 1' };
      } else if (activeTab === 'Level 2') {
        result = { path: verifiedKycLevelTwo, level: 'LEVEL 2' };
      } else if (activeTab === 'Level 3') {
        result = { path: verifiedKycLevelThree, level: 'LEVEL 3' };
      } else if (activeTab === 'Agency') {
        result = { path: verifiedKycAgency, level: 'Agency' };
      } else {
        result = { path: verifiedKycLevelOne, level: 'LEVEL 1' };
      }
    }
    // Tab View for unverified users
    else {
      if (activeTab === '') {
        result = { path: kycLevelZero, level: 'LEVEL 1' };
      } else if (activeTab === 'Level 1') {
        result = { path: kycLevelZero, level: 'LEVEL 1' };
      } else if (activeTab === 'Level 2') {
        result = { path: kycLevelOne, level: 'LEVEL 2' };
      } else if (activeTab === 'Level 3') {
        result = { path: kycLevelTwo, level: 'LEVEL 3' };
      } else if (activeTab === 'Agency') {
        result = { path: kycLevelAgency, level: 'Agency' };
      } else {
        result = { path: kycLevelZero, level: 'LEVEL 1' };
      }
    }

    return result;
  }

  let kycLevel = determineKycToFetch();

  // console.log(filterOptions)

  // on check get onboarded value
  useEffect(() => {
    const getOnboardedvalue = getSelectedOptionValue(dateOnboarded);
    const getSelectedSortBy = getSelectedOptionValue(sortBy);
    const getSelectedOrderBy = getSelectedOptionValue(orderBy);

    let onboarded_data = getOnboardedvalue;

    setFilterOptions({
      ...filterOptions,
      date_range: onboarded_data,
      sort_by: getSelectedSortBy,
      order_by: getSelectedOrderBy,
      term: searchValue,
    });
  }, [dateOnboarded, sortBy, orderBy, searchValue]);

  // api
  useEffect(() => {
    const onboarded_date_range = formatStartAndEndDateWithDelimeter(
      onboardedDateRange.state_date,
      onboardedDateRange.end_date,
    );
    let payload = { ...filterOptions };
    payload = payload.date_range === 'custom' ? { ...payload, date_range: onboarded_date_range } : payload;

    const status =
      activePageTab === 'pending_verifications'
        ? 'pending'
        : activePageTab === 'rejected_verifications'
        ? 'rejected'
        : activePageTab === 'verified_users'
        ? 'approved'
        : '';
    setSearchValue('');
    if (activeTab !== '') {
      dispatch(
        getKycsRequest({
          kycLevel: `${kycLevel.path}&status=${status}`,
          per_page: pageSize,
          page: currentPage,
          filter: payload,
        }),
      );
    }
  }, [activeTab, currentPage]);

  // THIS RESETS THE PAGE WHEN SEARCH FIELD IS CLEARED
  useEffect(() => {
    const status =
      activePageTab === 'pending_verifications'
        ? 'pending'
        : activePageTab === 'rejected_verifications'
        ? 'rejected'
        : activePageTab === 'verified_users'
        ? 'approved'
        : '';

    if (searchValue.length < 1) {
      dispatch(
        getKycsRequest({
          kycLevel: `${kycLevel.path}&status=${status}`,
          per_page: pageSize,
          page: currentPage,
          // filter: payload,
        }),
      );
    }
  }, [currentPage, searchValue.length]);

  // Set kyc tab counter
  useEffect(() => {
    // Check if kycsAnalyticsStatus is 'succeeded' and other necessary conditions
    if (kycsAnalyticsStatus === 'succeeded' && kycsAnalyticsState?.data) {
      // Create an array of objects with computed properties
      const result = [
        {
          id: 1,
          total: kycsAnalyticsState.data.level_one_kyc_count,
          helper: 'Level 1',
          title: 'Level 1',
          subtitle: 'Total Records',
        },
        {
          id: 2,
          total: kycsAnalyticsState.data.level_two_kyc_count,
          helper: 'Level 2',
          title: 'Level 2',
          subtitle: 'Total Records',
        },
        {
          id: 3,
          total: kycsAnalyticsState.data.level_three_kyc_count,
          helper: 'Level 3',
          title: 'Level 3',
          subtitle: 'Total Records',
        },
        {
          id: 4,
          total: kycsAnalyticsState.data.agent_kyc_count,
          helper: 'Agency',
          title: 'Agency',
          subtitle: 'Total Records',
        },
      ];

      // Update the state only when conditions are met
      setKycCountList(result);
    }
  }, [kycsAnalyticsStatus, kycsAnalyticsState]);

  // Format table data
  useEffect(() => {
    if (kycsStatus === 'succeeded') {
      let updateData: any[] = [];
      kycsState.data.users.data.forEach((item: Dictionary, index: number) => {
        updateData.push({
          id: index + 1,
          userName: `${item?.bvn?.last_name} ${item?.bvn?.first_name}`,
          bvn: item?.bvn?.bvn_number ? ` ${item?.bvn?.bvn_number}` : 'N/A',
          phoneNo: item?.telephone,
          detailsId: item?.id,
          created_at: item?.created_at,
          rejection_reason: item?.verification?.comment,
          verifications: item?.verifications,
        });
        // console.log(item)
      });

      setKycData(updateData);

      const {
        meta: { last_page },
      } = kycsState?.data?.users;

      setTotalPages(last_page);
    }
  }, [kycsState, activePageTab]);

  useEffect(() => {
    dispatch(
      getKycsAnalyticsRequest({
        kycType:
          activePageTab === 'verified_users'
            ? 'approved'
            : activePageTab === 'pending_verifications'
            ? 'pending'
            : activePageTab === 'rejected_verifications' && 'rejected',
      }),
    );
    // console.log(activePageTab)
    setCurrentPage(1);
    setTotalPages(5);
    setActiveTab('');
  }, [activePageTab]);

  useEffect(() => {
    if (downloadStatus === 'succeeded') {
      setShowSuccessDownloadModal(true);
    }
  }, [downloadStatus]);

  // handle page number change
  const handlePageNumberChange = (pageNumber: any) => {
    const onboarded_date_range = formatStartAndEndDateWithDelimeter(
      onboardedDateRange.state_date,
      onboardedDateRange.end_date,
    );
    let payload = { ...filterOptions };
    payload = payload.date_range === 'custom' ? { ...payload, date_range: onboarded_date_range } : payload;

    const status =
      activePageTab === 'pending_verifications'
        ? 'pending'
        : activePageTab === 'rejected_verifications'
        ? 'rejected'
        : activePageTab === 'verified_users'
        ? 'approved'
        : '';

    dispatch(
      getKycsRequest({
        kycLevel: `${kycLevel.path}&status=${status}`,
        per_page: pageNumber,
        page: currentPage,
        filter: payload,
      }),
    );
  };

  return (
    <section>
      <DownloadSuccessModal
        show={showSuccessDownloadModal}
        handleClose={() => {
          dispatch(downloadKycCustomerReset());
          setShowSuccessDownloadModal(false);
        }}
        closeOnClickOutside={false}
        contentRef={successModalRef}
        title="Item Downloaded"
        content="Your document has been downloaded to your device"
      />
      {/* Overlay to hide filter options */}
      {(showFilterOptions || showSortBy || showExportOptions) && (
        <div
          className="tw-absolute tw-bg-black/0 tw-cursor-pointer tw-z-30 tw-w-full tw-h-screen
           tw-top-0 tw-left-0"
          onClick={() => {
            setShowFilterOptions(false);
            setShowSortBy(false);
            setShowExportOptions(false);
          }}></div>
      )}
      <div className="tw-p-2 tw-mb-28 tw-mt-4 md:tw-mt-7">
        <div className="tw-flex tw-items-center tw-gap-x-4 tw-overflow-scroll md:tw-gap-x-8">
          {KycPageTabs.map((items: any, idx: number) => (
            <div
              key={items.id}
              className={`tw-block tw-cursor-pointer tw-min-w-fit tw-text-sm tw-no-underline hover:tw-text-[#222B88] md:tw-text-[16px] md:tw-overflow-auto ${
                activePageTab === items.url
                  ? 'tw-text-[#222B88] tw-font-medium tw-border-b-2 tw-border-b-[#222B88]'
                  : 'tw-text-[#6A616F]'
              }`}
              onClick={() => {
                if (kycsAnalyticsStatus !== 'loading') {
                  setActiveTab('');
                  setActivePageTab(KycPageTabs[idx].url);
                }
              }}>
              {items.title}
            </div>
          ))}
        </div>

        {
          <section className="tw-my-5 md:tw-mt-8">
            <div className="tw-grid tw-gap-4 tw-grid-cols-2 xl:tw-grid-cols-4 md:tw-gap-6">
              {kycCountList.map(({ id, helper, title, total, subtitle }) => (
                <SingleCountCard
                  key={id}
                  id={id}
                  title={title}
                  total={total}
                  subtitle={subtitle}
                  active={activeTab === helper ? true : false}
                  onClick={() => {
                    if (kycsAnalyticsStatus !== 'loading' && kycsStatus !== 'loading') {
                      setActiveTabHandler(helper);
                    }
                  }}
                  loading={kycsAnalyticsStatus === 'loading'}
                />
              ))}
            </div>
          </section>
        }

        {activeTab === '' && (
          <section className="tw-h-[calc(100vh_-_25rem)] tw-flex tw-items-center tw-justify-center">
            <img className="tw-w-[20rem]" src={images.emptyList} alt="" />
          </section>
        )}

        {activeTab === '' ||
          (kycsAnalyticsStatus === 'succeeded' && kycsStatus === 'succeeded' && kycData?.length !== 0 && (
            <div className="tw-mb-4 md:tw-h-[70vh] md:tw-mb-28 tw-mt-10">
              <div className="tw-my-3 tw-flex tw-flex-col tw-justify-between md:tw-flex-row ">
                <p className="tw-flex tw-items-center tw-gap-x-2 tw-text-sm tw-text-[#222B88] tw-font-[400] tw-order-1 md:-tw-order-1">
                  <span>Page</span>{' '}
                  <span className="tw-w-6 tw-h-6 tw-rounded-[4px] tw-p-2 tw-bg-isPrimary/5 tw-grid tw-place-content-center">
                    {currentPage || 0}
                  </span>
                </p>
                <div className="tw-flex tw-flex-col tw-gap-2 tw-items-end lg:tw-flex-row lg:tw-gap-x-3 md:tw-items-center">
                  <div className="tw-relative tw-w-full md:tw-mr-4">
                    <input
                      type="text"
                      name="reference"
                      id="reference"
                      value={searchValue}
                      onChange={e => setSearchValue(e.target.value)}
                      className="tw-w-full tw-pl-1 tw-bg-none tw-border-b tw-text-sm tw-text-isPrimary placeholder:tw-text-xs placeholder:tw-text-[#162082] placeholder:tw-font-thin focus:tw-outline-none md:tw-w-72"
                      placeholder="Search by name, phone number, bvn"
                      style={{ backgroundColor: 'transparent' }}
                    />
                    <span
                      className="tw-text-xs tw-absolute tw-right-0 tw-top-1 tw-text-isPrimary tw-cursor-pointer"
                      onClick={() => handleSubmitFilter()}>
                      <icons.FaSearch />
                    </span>
                  </div>
                  <div className="tw-flex tw-items-center tw-gap-x-2 md:tw-gap-x-4">
                    <FilterLabel
                      title="Filter Options"
                      icon={<icons.BsFilter />}
                      onClick={() => setShowFilterOptions(true)}>
                      <FilterCard
                        showFilter={showFilterOptions}
                        title="Filter Options"
                        onClick={handleSubmitFilter}
                        extraClass="tw-w-[13rem]">
                        <div className="tw-mt-4 tw-h-[14rem] tw-overflow-y-scroll tw-overflow-hidden">
                          {activePageTab === 'rejected_verifications' && (
                            <ZojaSelect
                              options={[
                                { value: '', label: 'Rejection Reason' },
                                { value: 'blurry_image', label: 'Blurry Image' },
                                { value: 'images_do_not_match', label: 'Images do not match' },
                                { value: 'details_do_not_match', label: 'Details do not match' },
                                { value: 'unverifiable_details', label: 'Unverifiable details' },
                              ]}
                              name="status"
                              showArrown={true}
                              setSelectedOption={selected =>
                                setFilterOptions({ ...filterOptions, rejection_reason: selected })
                              }
                              extraClass="tw-text-xs tw-p-3.5 tw-bg-white tw-border-0 tw-shadow-zojaShadowFive tw-rounded-md tw-font-normal tw-text-[#222B88]"
                              arrowDownClass="tw-right-lg tw-top-3.5"
                            />
                          )}
                          {activeTab !== 'Agency' && (
                            <>
                              {(activeTab === 'Level 1' && activePageTab === 'pending_verifications') ||
                                (activePageTab === 'rejected_verifications' && activeTab === 'Level 1' && (
                                  <ZojaSelect
                                    options={[
                                      { value: '', label: 'Verification Entry' },
                                      { value: 'bvn', label: 'BVN' },
                                      { value: 'bvn_image', label: 'BVN Image' },
                                    ]}
                                    name="status"
                                    showArrown={true}
                                    setSelectedOption={selected =>
                                      setFilterOptions({ ...filterOptions, [identityTypeParam]: selected })
                                    }
                                    extraClass="tw-text-xs tw-p-3.5 tw-bg-white tw-border-0 tw-shadow-zojaShadowFive tw-rounded-md tw-font-normal tw-text-[#222B88]"
                                    arrowDownClass="tw-right-lg tw-top-3.5"
                                  />
                                ))}

                              {activeTab === 'Level 2' && (
                                <>
                                  <ZojaSelect
                                    options={[
                                      { value: '', label: 'Identity Type' },
                                      { value: 'national_id', label: 'National Id' },
                                      { value: 'voter_card', label: "Voter's Card" },
                                      { value: 'international_passport', label: 'International Passport' },
                                    ]}
                                    name="verification_entry"
                                    showArrown={true}
                                    setSelectedOption={selected =>
                                      setFilterOptions({ ...filterOptions, [verificationEntryParam]: selected })
                                    }
                                    extraClass="tw-text-xs tw-p-3.5 tw-bg-white tw-border-0 tw-shadow-zojaShadowFive tw-rounded-md tw-font-normal tw-text-[#222B88]"
                                    arrowDownClass="tw-right-lg tw-top-3.5"
                                  />
                                </>
                              )}
                              {activeTab === 'Level 3' && (
                                <ZojaSelect
                                  options={[
                                    { value: '', label: 'Bill Type' },
                                    { value: 'house_rent', label: 'House Rent' },
                                    { value: 'water_bill', label: 'Water Bill' },
                                    { value: 'electricity_bill', label: 'Electricity Bill' },
                                    { value: 'bank_statement', label: 'Bank Statement' },
                                  ]}
                                  name="bill_type"
                                  showArrown={true}
                                  setSelectedOption={selected =>
                                    setFilterOptions({ ...filterOptions, [billTypeParam]: selected })
                                  }
                                  extraClass="tw-text-xs tw-p-3.5 tw-bg-white tw-border-0 tw-shadow-zojaShadowFive tw-rounded-md tw-font-normal tw-text-[#222B88]"
                                  arrowDownClass="tw-right-lg tw-top-3.5"
                                />
                              )}
                            </>
                          )}

                          <FilterColapsible
                            title="Date Range"
                            show={showDateOnboarded}
                            onClick={() => setShowDateOnboarded(!showDateOnboarded)}
                            extraClass="tw-shadow-none">
                            <>
                              <div className="">
                                {dateOnboarded.map((item: any) => (
                                  <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                                    <span className="tw-text-[13px] tw-mt-4 tw-text-[#84919A]">{item.title}</span>
                                    <ZojaCheckbox
                                      isChecked={item.isChecked}
                                      onClick={() => handleSelectedOption(item, dateOnboarded, setDateOnboarded)}
                                    />
                                  </div>
                                ))}
                              </div>
                              <div
                                className={`tw-mt-2 tw-overflow-hidden tw-max-h-0 ${
                                  filterOptions.date_range === 'custom' && 'tw-max-h-fit'
                                }`}>
                                <div className="tw-mb-2">
                                  <CustomDatePicker
                                    label="State Date"
                                    value={new Date()}
                                    setValue={value =>
                                      setOnboardedDateRange({ ...onboardedDateRange, state_date: value })
                                    }
                                  />
                                </div>
                                <div className="tw-mb-2">
                                  <CustomDatePicker
                                    label="End Date"
                                    value={new Date()}
                                    setValue={value =>
                                      setOnboardedDateRange({ ...onboardedDateRange, end_date: value })
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          </FilterColapsible>
                        </div>
                      </FilterCard>
                    </FilterLabel>

                    <FilterLabel
                      title="Sort By"
                      icon={<icons.TbArrowsSort color="#5E6366" />}
                      onClick={() => setShowSortBy(true)}>
                      <FilterCard
                        showFilter={showSortBy}
                        title="Sort by"
                        onClick={handleSubmitFilter}
                        submitButtonText="Apply"
                        extraClass="tw-w-[10rem] -tw-left-28">
                        <>
                          <div className="tw-pt-2">
                            {sortBy.map((item: any) => (
                              <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                                <span className="tw-mt-4 tw-text-[13px] tw-text-[#84919A]">{item.title}</span>
                                <ZojaCheckbox
                                  isChecked={item.isChecked}
                                  onClick={() => handleSelectedOption(item, sortBy, setSortBy)}
                                />
                              </div>
                            ))}
                          </div>
                          <div className="tw-pt-2 tw-border-">
                            {orderBy.map((item: any) => (
                              <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                                <span className="tw-mt-4 tw-text-[13px] tw-text-[#84919A]">{item.title}</span>
                                <ZojaCheckbox
                                  isChecked={item.isChecked}
                                  onClick={() => handleSelectedOption(item, orderBy, setOrderBy)}
                                />
                              </div>
                            ))}
                          </div>
                        </>
                      </FilterCard>
                    </FilterLabel>

                    <ExportLabel
                      title="Export"
                      onClick={() => setShowExportOptions(true)}
                      loading={downloadStatus === 'loading'}>
                      <FilterCard
                        showFilter={showExportOptions}
                        title="Export as"
                        onClick={() => null}
                        showSubmitButton={false}
                        extraClass="tw-w-[10rem] -tw-left-28">
                        <div className="tw-flex tw-flex-col tw-mt-4">
                          <span
                            className="tw-text-xs tw-cursor-pointer tw-text-isGrey tw-border-b tw-border-b-[#f4f3f3] tw-pb-2 tw-mb-2"
                            onClick={() => handleExportFile('pdf')}>
                            PDF
                          </span>
                          <span
                            className="tw-text-xs tw-cursor-pointer tw-text-isGrey"
                            onClick={() => handleExportFile('csv')}>
                            CSV
                          </span>
                        </div>
                      </FilterCard>
                    </ExportLabel>
                  </div>
                </div>
              </div>
              <KycTable
                data={kycData}
                level={kycLevel.level}
                tab={activePageTab}
                sorting={handleSortItems}
                order={order}
              />
            </div>
          ))}

        {activeTab !== '' && kycsStatus === 'succeeded' && kycData?.length === 0 && (
          <div className="">No Record Found</div>
        )}

        {kycData.length !== 0 && activeTab !== '' && kycsStatus === 'succeeded' && (
          <div className="tw-pb-2 tw-flex tw-flex-col tw-items-center tw-gap-3 md:tw-pb-8 md:tw-flex-row">
            <Pagination
              isLoading={kycsStatus === 'succeeded' ? false : true || kycsAnalyticsStatus === 'loading'}
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={selectedPage => {
                setCurrentPage(selectedPage);
              }}
            />
            <div className="-tw-mt-12 tw-flex tw-items-center tw-h-fit">
              <span className="tw-text-xs tw-w-16">Per page</span>
              <ZojaSelect
                options={[
                  { label: '10', value: '10' },
                  { label: '20', value: '20' },
                  { label: '30', value: '30' },
                ]}
                value={kycsState?.data?.users?.meta?.per_page}
                setSelectedOption={selected => handlePageNumberChange(selected)}
                extraClass="tw-rounded-none tw-text-xs tw-py-2 tw-w-10"
              />
            </div>
          </div>
        )}

        {kycsStatus === 'loading' && kycsAnalyticsStatus !== 'loading' && (
          <>
            <div className="tw-h-[60vh] tw-mx-auto tw-flex tw-justify-center tw-items-center">
              <Oval
                height="80"
                width="80"
                color="#222b88cf"
                ariaLabel="tail-spin-loading"
                secondaryColor="#222b882b"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default KycContainer;
