import { useNavigate, useParams } from 'react-router-dom';
import { AppContainer, SettlementReport, TimebasedGeneratedReport } from '../../atoms';
import {
  routesPath,
  useDebounce,
  replaceCommasInQueryParam,
  getSelectedOptionValue,
  getEverySelectedOptionValue,
} from '../../utils';
import { DownloadSuccessModal } from '../../components';
import { dateFormat } from '../../utils/dateFormat';
import { reportTitle, settlementReport, settlementReportColumn, timebasedReportColumn } from './dummyData';
import { Dictionary } from '../../types';
import { useEffect, useRef, useState } from 'react';
import {
  transactionStatusOptions,
  dateOptions,
  kycLevelOptions,
  figureRangeOptions,
  transactionTypeGen,
  manageColumnOptions,
  sortByOtions,
  genderOptions,
  locationOptions,
  methodOptions,
  channel,
  refundStatus,
  transactionTypeRecon,
  reconciliationStatus,
  timeStamp,
  settlementType,
  settlementDestination,
  settlementMethod,
  chargebacks,
  deviceType,
  sourceOfReg,
  userStatusOptions,
  userLevel,
  userFeedback,
  accountVerification,
  activityType,
  securityEvents,
  sessionDuration,
} from './filterOptions';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import {
  downloadGeneralTransactionReportRequest,
  downloadGeneralTransactionReportReset,
  getAllGeneralTransactionReportRequest,
  getAllGeneralTransactionReportReset,
  resetGeneralTransactionFilterOptions,
  saveReportRequest,
  saveReportReset,
  searchUserAccountRequest,
  setGeneralTransactionFilterOptions,
} from '../../redux/slice';
import moment from 'moment';
import SaveReportModal from '../../components/modalTypes/SaveReportModal';
import { toast } from 'react-toastify';
const { REPORT } = routesPath;

export interface generalTransactionReportRow {
  id: number;
  sn: number;
  transactionDate: string;
  transactionType: string;
  accountID: string;
  accountName: string;
  amount: number;
  timeStamp: string;
  status: string;
  paymentMethod: string;
  transactionChannel: string;
  transactionCode: number;
  refundStatus: string;
  transactionFee: number;
  kycLevel: number;
}

export interface financeReportRow {
  id: number;
  sn: number;
  transactionDate: string;
  transactionType: string;
  accountID: string;
  accountName: string;
  amount: number;
  timeStamp: string;
  status: string;
  paymentMethod: string;
  transactionChannel: string;
  transactionCode: number;
  userLevel: string;
  transactionFee: number;
}

interface FilterOption {
  sortBy: { id: number; value: string; title: string; isChecked: boolean }[];
  orderBy: { id: number; value: string; title: string; isChecked: boolean }[];
  securityEvents: { id: number; value: string; title: string; isChecked: boolean }[];
  gender: { id: number; value: string; title: string; isChecked: boolean }[];
  accountInfo: any[];
  transactionDate: { id: number; value: string; title: string; isChecked: boolean }[];
  dateOnboarded: { id: number; value: string; title: string; isChecked: boolean }[];
  lastLogin: { id: number; value: string; title: string; isChecked: boolean }[];
  transactionType: { id: number; value: string; title: string; isChecked: boolean }[];
  transactionTypeRecon: { id: number; value: string; title: string; isChecked: boolean }[];
  transactionStatus: { id: number; value: string; title: string; isChecked: boolean }[];
  location: { id: number; value: string; title: string; isChecked: boolean }[];
  kycLevel: { id: number; value: string; title: string; isChecked: boolean }[];
  dateRange: { start_date: string; end_date: string };
  selectedOption: string;
  feesRange: { id: number; value: string; title: string; isChecked: boolean }[];
  reportFilterType: {
    title: string;
    id: number;
    value: string;
  };
  manageColumn: { id: number; value: string; title: string; isChecked: boolean }[];
  manageColumnTransactions: { id: number; value: string; title: string; isChecked: boolean }[];
  paymentMethod: { id: number; value: string; title: string; isChecked: boolean }[];
  channel: { id: number; value: string; title: string; isChecked: boolean }[];
  refundStatus: { id: number; value: string; title: string; isChecked: boolean }[];
  reconciliationStatus: { id: number; value: string; title: string; isChecked: boolean }[];
  timeStamp: { id: number; value: string; title: string; isChecked: boolean }[];
  settlementType: { id: number; value: string; title: string; isChecked: boolean }[];
  settlementMethod: { id: number; value: string; title: string; isChecked: boolean }[];
  chargebacks: { id: number; value: string; title: string; isChecked: boolean }[];
  settlementDestination: { id: number; value: string; title: string; isChecked: boolean }[];
  deviceType: { id: number; value: string; title: string; isChecked: boolean }[];
  sourceOfReg: { id: number; value: string; title: string; isChecked: boolean }[];
  userStatus: { id: number; value: string; title: string; isChecked: boolean }[];
  userLevel: { id: number; value: string; title: string; isChecked: boolean }[];
  userFeedback: { id: number; value: string; title: string; isChecked: boolean }[];
  accountVerification: { id: number; value: string; title: string; isChecked: boolean }[];
  activityType: { id: number; value: string; title: string; isChecked: boolean }[];
  sessionDuration: { id: number; value: string; title: string; isChecked: boolean }[];
}

const GeneralReport = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { componentName } = useParams();

  // REFS
  const successModalRef = useRef<any>(null);
  const saveReportModalRef = useRef<any>(null);

  // REDUX STATES
  const allGeneralTransactionReportState = useAppSelector(state => state.getAllGeneralTransactionReport);
  const { status: getAllGeneralTransactionReportStatus, data: getAllGeneralTransactionReportData } =
    allGeneralTransactionReportState;
  const generalTransactionFilterOptions = useAppSelector(state => state.generalTransactionFilterOptions);
  // const { status: downloadTransactionReportStatus } = useAppSelector(state => state.downloadGeneralTransactionReport);
  const searchedUserAccount = useAppSelector(state => state.searchUserAccount);
  const { status: saveReportStatus } = useAppSelector(state => state.saveReportSlice);
  const { status: downloadGeneralTransactionReportStatus } = useAppSelector(
    state => state.downloadGeneralTransactionReport,
  );

  //   REACT STATES

  const [visibleColumns, setVisibleColumns] = useState(timebasedReportColumn.map(column => column.id));
  const [showManageColumn, setShowManageColumn] = useState(false);
  const [search, setSearch] = useState<any>();
  const [totalItems, setTotalItems] = useState();
  const [currentPage, setCurrentPage] = useState(allGeneralTransactionReportState?.data?.meta?.current_page || 1);
  const [perPage, setPerPage] = useState();
  const [generalTransactionReportData, setGeneralTransactionReportData] = useState<generalTransactionReportRow[]>([]);
  const [selectedPageOption, setSelectedPageOption] = useState<'10' | '20' | '30'>();
  const [showSuccessDownloadModal, setShowSuccessDownloadModal] = useState(false);
  const [showSuccessSavedModal, setShowSuccessSavedModal] = useState(false);
  const [showSaveReportModal, setShowSaveReportModal] = useState(false);
  const [reportName, setReportName] = useState('');
  const [reportQueries, setReportQueries] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  // Use Refs
  const isFirstRenderRef = useRef('true');

  //  DEBOUNCE HOOK
  const debouncedSearch = useDebounce(search, 300);

  // const [filterType, setFilterType] = useState('transaction');
  const initialValues: Dictionary = {
    transaction_date: '',
    reference: '',
    type: '',
    min_amount: '',
    max_amount: '',
    sort_by: '',
    order_by: '',
  };

  const [filterOptions, setFilterOptions] = useState(initialValues);

  // Grouped state variables for show options
  const [showOptions, setShowOptions] = useState({
    ageRange: false,
    totalTransaction: false,
    location: false,
    accountBalance: false,
    filterType: false,
    loginTime: false,
    gender: false,
    onboarded: false,
    sortBy: false,
    reportFilter: false,
    kycLevel: false,
    accountInfo: false,
    exportOptions: false,
    bigFilter: true,
    manageColumn: false,
    transactionDate: false,
    transactionAmount: false,
    transactionType: false,
    transactionStatus: false,
    feesRange: false,
    paymentMethod: false,
    channel: false,
    refundStatus: false,
    reconciliationStatus: false,
    timeStamp: false,
    settlementType: false,
    chargebacks: false,
    settlementDestination: false,
    settlementMethod: false,
    settlementBatchID: false,
    bankStatRef: false,
    bankAccount: false,
    deviceType: false,
    sourceOfReg: false,
    userStatus: false,
    userLevel: false,
    userFeedback: false,
    accountVerification: false,
    activityType: false,
    sessionDuration: false,
    securityEvents: false,
    transactionFee: false,
    transactionCode: false,
  });

  // Grouped state variables for filter options
  const [filterOption, setFilterOption] = useState<FilterOption>({
    sortBy: sortByOtions.aphabetically,
    orderBy: sortByOtions.orderBy,
    gender: genderOptions,
    accountInfo: [] as any[],
    transactionDate: dateOptions,
    dateOnboarded: dateOptions,
    lastLogin: dateOptions,
    transactionType: transactionTypeGen,
    transactionTypeRecon: transactionTypeRecon,
    transactionStatus: transactionStatusOptions,
    location: locationOptions,
    kycLevel: kycLevelOptions,
    dateRange: { start_date: '', end_date: '' },
    selectedOption: '10',
    feesRange: figureRangeOptions,
    reportFilterType: {
      title: 'Filter Type',
      id: 1,
      value: 'initial',
    },
    manageColumn: manageColumnOptions.transaction,
    manageColumnTransactions: manageColumnOptions.timebased,
    paymentMethod: methodOptions,
    channel: channel,
    refundStatus: refundStatus,
    reconciliationStatus: reconciliationStatus,
    timeStamp: timeStamp,
    settlementType: settlementType,
    settlementMethod: settlementMethod,
    chargebacks: chargebacks,
    settlementDestination: settlementDestination,
    deviceType: deviceType,
    sourceOfReg: sourceOfReg,
    userStatus: userStatusOptions,
    userLevel: userLevel,
    userFeedback: userFeedback,
    accountVerification: accountVerification,
    activityType: activityType,
    sessionDuration: sessionDuration,
    securityEvents: securityEvents,
  });

  const handleSubmitFilter = () => {
    if (currentFrequency) {
      dispatch(getAllGeneralTransactionReportRequest(generalTransactionFilterOptions));
    }
    setShowOptions({ ...showOptions, sortBy: false });
  };

  // close filter on state change
  useEffect(() => {
    setShowOptions({ ...showOptions, sortBy: false });
  }, [getAllGeneralTransactionReportStatus]);

  // filter and export methods
  const handleExportFile = (type: string) => {
    dispatch(
      downloadGeneralTransactionReportRequest({
        ...generalTransactionFilterOptions,
        exp: type,
        per_page: selectedPageOption || 10,
        frequency: currentFrequency,
      }),
    );
    setShowOptions({ ...showOptions, exportOptions: false });
  };

  // Input changer handler
  const handleInputChange = ({ target: { name, value } }: Dictionary) => {
    dispatch(setGeneralTransactionFilterOptions({ ...generalTransactionFilterOptions, [name]: value }));
  };

  // this processes strings to be used as saved reports
  useEffect(() => {
    const urlLink = getAllGeneralTransactionReportData?.links?.last;

    // Find the index of the first "?" and the last "&"
    const firstQuestionMarkIndex = urlLink?.indexOf('?');
    const lastAmpersandIndex = urlLink?.lastIndexOf('&');

    // Extract the substring between the first "?" and the last "&"
    const extractedParams =
      firstQuestionMarkIndex !== -1 ? urlLink?.substring(firstQuestionMarkIndex + 1, lastAmpersandIndex) : '';

    if (extractedParams?.startsWith('https://')) {
      // Set expectedParams to an empty string if it starts with "https://"
      setReportQueries('');
    } else {
      setReportQueries(replaceCommasInQueryParam(extractedParams));
    }
  }, [downloadGeneralTransactionReportStatus, getAllGeneralTransactionReportData?.links?.last, reportQueries]);

  const [currentFrequency, setCurrentFrequency] = useState('');

  useEffect(() => {
    const frequency =
      componentName && (reportTitle as any)[componentName] ? (reportTitle as any)[componentName]?.frequency : '';

    // console.log('Path', frequency);

    setCurrentFrequency(frequency);
  }, [componentName]);

  // THIS EFFECT UPDATES THE FILTER OPTIONS FOR TRANSACTIONS
  useEffect(() => {
    if (currentFrequency) {
      dispatch(
        setGeneralTransactionFilterOptions({
          ...generalTransactionFilterOptions,
          start_date: currentFrequency && filterOption.dateRange.start_date,
          end_date: filterOption.dateRange.end_date,
          status: getEverySelectedOptionValue(filterOption.transactionStatus),
          type: getEverySelectedOptionValue(filterOption.paymentMethod),
          transaction_type: getEverySelectedOptionValue(filterOption.transactionType),
          filter_accounts: getEverySelectedOptionValue(filterOption.accountInfo, 'account'),
          fees_range: getEverySelectedOptionValue(filterOption.feesRange),
          sort_by: getSelectedOptionValue(filterOption.sortBy),
          order_by: getSelectedOptionValue(filterOption.orderBy),
          per_page: selectedPageOption,
          page: currentPage,
          kyc_level: getEverySelectedOptionValue(kycLevelOptions),
          frequency: currentFrequency,
        }),
      );
    }
  }, [dispatch, filterOption, selectedPageOption, currentFrequency, currentPage]);

  // console.log(generalTransactionFilterOptions, filterOption.dateRange);

  // THIS EFFECT CALLS GET USER ENDPOINT WHEN selectedPageOption CHANGES
  useEffect(() => {
    if (selectedPageOption && currentFrequency) {
      dispatch(
        getAllGeneralTransactionReportRequest({
          ...generalTransactionFilterOptions,
          per_page: selectedPageOption,
          frequency: currentFrequency,
        }),
      );
      // setIsPageNumberChange(true);
    }
  }, [selectedPageOption]);

  const getTransactionReportOnPageChange = () => {
    dispatch(
      getAllGeneralTransactionReportRequest({
        ...generalTransactionFilterOptions,
        page: currentPage,
        frequency: currentFrequency,
      }),
    );
  };

  useEffect(() => {
    if (currentPage && currentFrequency) {
      const { start_date, end_date } = filterOption.dateRange;
      if (currentFrequency === 'daily' && start_date && end_date === '') {
        getTransactionReportOnPageChange();
      } else if (currentFrequency === 'weekly' && start_date && end_date) {
        const date_difference = getDateDifference(start_date, end_date);
        date_difference === 6 && getTransactionReportOnPageChange();
      } else if (currentFrequency === 'monthly' && start_date && end_date) {
        const date_difference = getDateDifference(start_date, end_date);
        date_difference > 27 && date_difference < 32 && getTransactionReportOnPageChange();
      }
    }
  }, [currentPage]);

  // THIS EFFECT UPDATES THE USERLIST DATA
  useEffect(() => {
    if (searchedUserAccount.status === 'succeeded') {
      let updatedList: any[] = [];

      searchedUserAccount?.data?.users?.forEach((item: any, index: number) => {
        updatedList.push({
          id: index + 1,
          isChecked: false,
          accountNumber: item.account_number,
          name: item.name,
          value: item.name,
          telephone: item.telephone,
        });
      });

      setFilterOption({ ...filterOption, accountInfo: updatedList });
    }
  }, [searchedUserAccount?.data.user, searchedUserAccount?.data?.users, searchedUserAccount.status]);

  useEffect(() => {
    return () => {
      dispatch(resetGeneralTransactionFilterOptions()); // Reset the filter options when the component is unmounted
    };
  }, []);

  console.log(generalTransactionFilterOptions);

  // THIS EFFECT CALLS SEARCH ENDPOINT WHEN THE DEBOUNCED SEARCH VALUE CHANGES
  useEffect(() => {
    if (search) {
      dispatch(searchUserAccountRequest({ search: debouncedSearch }));
    }
  }, [dispatch, debouncedSearch]);

  // useEffect(() => {
  //   if (downloadTransactionReportStatus === 'succeeded') {
  //     setShowSuccessDownloadModal(true);
  //   }
  // }, [downloadTransactionReportStatus]);

  const getDateDifference = (startDate: string | number | Date, endDate: string | number | Date) => {
    const diffInTime = new Date(endDate).getTime() - new Date(startDate).getTime();
    const diffInDays = diffInTime / (1000 * 3600 * 24);

    return diffInDays;
  };

  useEffect(() => {
    isFirstRenderRef.current = 'true';
    dispatch(getAllGeneralTransactionReportReset());
  }, [currentFrequency]);

  useEffect(() => {
    if (getAllGeneralTransactionReportStatus === 'succeeded') {
      // setSelectedPageOption(undefined);
    }
  }, [getAllGeneralTransactionReportStatus, selectedPageOption]);

  // CALLS
  useEffect(() => {
    // isFirstRenderRef.current && console.log(isFirstRenderRef.current);
    // console.log(isFirstRenderRef.current === '');
    if (!!currentFrequency && isFirstRenderRef.current !== '') {
      const { start_date, end_date } = filterOption.dateRange;
      if (currentFrequency === 'daily' && start_date && end_date === '') {
        getTransactionReportOnPageChange();
      } else if (currentFrequency === 'weekly' && start_date && end_date) {
        const date_difference = getDateDifference(start_date, end_date);
        date_difference === 6 && getTransactionReportOnPageChange();
      } else if (currentFrequency === 'monthly' && start_date && end_date) {
        const date_difference = getDateDifference(start_date, end_date);
        date_difference > 27 && date_difference < 32 && getTransactionReportOnPageChange();
      }
    }

    // return () => {
    //   isFirstRenderRef.current = '';
    // };
  }, [generalTransactionFilterOptions, currentFrequency, filterOptions]);

  // useEffect(() => {
  //   if (currentFrequency) {
  //     getTransactionReportOnPageChange();
  //   }
  // }, [filterOptions, currentFrequency]);

  //
  //SHOWS DOWNLOAD SUCCESS MODAL
  useEffect(() => {
    if (downloadGeneralTransactionReportStatus === 'succeeded') {
      setShowSuccessDownloadModal(true);
    }
  }, [downloadGeneralTransactionReportStatus]);

  //SHOWS SAVED SUCCESS MODAL
  useEffect(() => {
    if (saveReportStatus === 'succeeded') {
      setShowSuccessSavedModal(true);
    }
  }, [saveReportStatus]);

  //SHOWS SAVE REPORT MODAL
  useEffect(() => {
    if (saveReportStatus === 'succeeded') {
      setShowSaveReportModal(false);
    }
  }, [saveReportStatus]);

  // THIS EFFECT UPDATES THE GENERAL TRANSACTIONS REPORT DATA
  useEffect(() => {
    if (getAllGeneralTransactionReportStatus === 'succeeded') {
      isFirstRenderRef.current = '';
      let updatedList: generalTransactionReportRow[] = [];

      allGeneralTransactionReportState?.data?.data?.forEach((item: any, index: number) => {
        updatedList.push({
          id: index + 1 || 0,
          sn: index + 1 || 0,
          transactionDate: dateFormat(item?.created_at) || '-',
          transactionType: item?.transaction_type || '-',
          accountID: item?.source_account?.number || '-',
          accountName: item?.source_account?.name || '-',
          paymentMethod: item?.type || '-',
          transactionChannel: item?.channel || '-',
          refundStatus: '-',
          transactionCode: item?.transaction_reference || '-',
          transactionFee: item?.charge || '-',
          amount: item?.amount || '-',
          timeStamp: `${moment(item?.created_at).fromNow(true)} ago` || '-',
          status: item?.status || '-',
          kycLevel: item?.user?.kyc_level || '-',
        });
      });

      const {
        meta: { per_page, total },
      } = allGeneralTransactionReportState?.data;
      setTotalItems(total);
      setPerPage(per_page);

      setGeneralTransactionReportData(updatedList);
    }
  }, [getAllGeneralTransactionReportStatus, allGeneralTransactionReportState]);

  // CALLS API WHEN THE APPLY FILTER BUTTON IS CLICKED
  const handleApplyFilter = () => {
    dispatch(getAllGeneralTransactionReportRequest(generalTransactionFilterOptions));
  };

  const title =
    componentName && (reportTitle as any)[componentName]
      ? (reportTitle as any)[componentName]?.title
      : 'General Report';

  const handleShowSaveReport = () => {
    setShowSaveReportModal(true);
    setShowSuccessDownloadModal(false);
  };

  // input changer handler for saved report
  const handleSavedReportInputChange = (e: { target: { name: any; value: any } }) => {
    const { value } = e.target;
    setReportName(value);
  };

  // SAVES REPORT WHEN BUTTON IS CLICKED
  const handleReportSave = () => {
    const payload = { query: reportQueries, name: reportName, type: 'income' };
    dispatch(saveReportRequest(payload));
  };

  useEffect(() => {
    if (startDate && endDate) {
      // const DATE_RANGE = Number(endDate.replace(/-/g, '')) - Number(startDate.replace(/-/g, ''));
      const diffInTime = new Date(endDate).getTime() - new Date(startDate).getTime();
      const diffInDays = diffInTime / (1000 * 3600 * 24);
      if (startDate && endDate && diffInDays < 1) {
        toast.error('End date must be a future date');
        setEndDate('');
        return;
      }
      if (startDate && endDate && currentFrequency === 'weekly' && diffInDays !== 6) {
        // console.log('Error', diffInDays);
        toast.error('The date range must be 7 days');
        setEndDate('');
        return;
      }
      // console.log('Error', diffInDays);
      if (startDate && endDate && currentFrequency === 'monthly' && (diffInDays < 28 || diffInDays > 31)) {
        toast.error('The date range must be between 28 and 31 days');
        setEndDate('');
        return;
      }
    }
  }, [startDate, endDate, currentFrequency]);

  useEffect(() => {
    if (startDate) {
      setEndDate('');
    }
  }, [startDate]);

  useEffect(() => {
    if (currentFrequency) {
      const today = new Date();
      const getYearMonthDay = (date: Date) => date.toISOString().split('T')[0];
      // console.log(today.setDate(today.getDate() - 6));
      if (currentFrequency === 'daily') {
        setFilterOption({
          ...filterOption,
          dateRange: { start_date: getYearMonthDay(today), end_date: '' },
        });
      }
      if (currentFrequency === 'weekly') {
        setFilterOption(prevState => {
          let newDate = new Date();
          newDate.setDate(newDate.getDate() - 6);
          return {
            ...prevState,
            dateRange: {
              ...prevState.dateRange,
              end_date: getYearMonthDay(today),
              start_date: getYearMonthDay(newDate),
            },
          };
        });
      }
      if (currentFrequency === 'monthly') {
        setFilterOption(prevState => {
          let newDate = new Date();
          newDate.setDate(newDate.getDate() - 30);
          return {
            ...prevState,
            dateRange: {
              ...prevState.dateRange,
              end_date: getYearMonthDay(today),
              start_date: getYearMonthDay(newDate),
            },
          };
        });
      }
    }
  }, [currentFrequency]);

  const resetArray = (arr: { id: number; value: string; title: string; isChecked: boolean }[]) => {
    return arr.map(item => ({
      ...item,
      isChecked: false,
    }));
  };

  const resetIsCheckedForAll = () => {
    const updatedFilterOption: FilterOption = { ...filterOption };

    // Reset isChecked to false for each array property
    updatedFilterOption.sortBy = resetArray(updatedFilterOption.sortBy);
    updatedFilterOption.paymentMethod = resetArray(updatedFilterOption.paymentMethod);
    updatedFilterOption.transactionStatus = resetArray(updatedFilterOption.transactionStatus);
    updatedFilterOption.accountInfo = resetArray(updatedFilterOption.accountInfo);
    updatedFilterOption.accountVerification = resetArray(updatedFilterOption.accountVerification);
    updatedFilterOption.activityType = resetArray(updatedFilterOption.activityType);
    updatedFilterOption.channel = resetArray(updatedFilterOption.channel);
    updatedFilterOption.chargebacks = resetArray(updatedFilterOption.chargebacks);
    updatedFilterOption.dateOnboarded = resetArray(updatedFilterOption.dateOnboarded);
    updatedFilterOption.feesRange = resetArray(updatedFilterOption.feesRange);
    updatedFilterOption.gender = resetArray(updatedFilterOption.gender);
    updatedFilterOption.kycLevel = resetArray(updatedFilterOption.kycLevel);
    updatedFilterOption.userStatus = resetArray(updatedFilterOption.userStatus);
    updatedFilterOption.userLevel = resetArray(updatedFilterOption.userLevel);
    updatedFilterOption.userFeedback = resetArray(updatedFilterOption.userFeedback);
    updatedFilterOption.userFeedback = resetArray(updatedFilterOption.userFeedback);
    // Reset other array properties similarly...

    // Update the state with the modified filterOption
    setFilterOption(updatedFilterOption);

    console.log(updatedFilterOption.paymentMethod);
  };

  useEffect(() => {
    resetIsCheckedForAll(); // Reset on mount
  }, []); // Empty dependency array means this effect runs only once on mount

  return (
    <AppContainer
      navTitle="Reports"
      goBack={() => navigate(REPORT)}
      navHelper={
        <>
          <h4 className="tw-uppercase tw-my-0 tw-text-[#323348] tw-text-xs md:tw-text-2xl">Reports</h4>
        </>
      }>
      <div>
        <DownloadSuccessModal
          show={showSuccessDownloadModal}
          handleClose={() => {
            setShowSuccessDownloadModal(false);
            dispatch(downloadGeneralTransactionReportReset());
          }}
          closeOnClickOutside={false}
          contentRef={successModalRef}
          title="Item Downloaded"
          content="Your document has been downloaded to your device"
          handleShowSaveReport={() => {
            handleShowSaveReport();
            dispatch(downloadGeneralTransactionReportReset());
          }}
          type="saveDownloadModal"
        />
        <DownloadSuccessModal
          show={showSuccessSavedModal}
          handleClose={() => {
            setShowSuccessSavedModal(false);
            dispatch(saveReportReset());
          }}
          closeOnClickOutside={false}
          contentRef={successModalRef}
          title="Report Saved"
          content="Your report has been saved successfully"
        />
        <SaveReportModal
          show={showSaveReportModal}
          handleClose={() => setShowSaveReportModal(false)}
          closeOnClickOutside={false}
          contentRef={saveReportModalRef}
          onHandleChange={handleSavedReportInputChange}
          title="Save your Report"
          value={reportName}
          onSave={handleReportSave}
          saveReportStatus={saveReportStatus}
          content="Give your report a name and see it on your report homepage to reprint anytime, and would not be automatically deleted with rest of your history"
        />
        <h1 className="tw-text-gray-800 tw-text-2xl tw-font-medium tw-my-7">{title}</h1>
        {(componentName === 'dailyreport' || componentName === 'weeklyreport' || componentName === 'monthlyreport') && (
          <div className="">
            <TimebasedGeneratedReport
              onSubmitSort={() => handleSubmitFilter()}
              filterOptions={filterOptions}
              setFilterOptions={setFilterOptions}
              column={timebasedReportColumn}
              tableData={generalTransactionReportData}
              onExport={handleExportFile}
              generalTransactionState={allGeneralTransactionReportState}
              manageColumnOptions={manageColumnOptions}
              filterOption={filterOption}
              setFilterOption={setFilterOption}
              showOptions={showOptions}
              setShowOptions={setShowOptions}
              showManageColumn={showManageColumn}
              setShowManageColumn={setShowManageColumn}
              onApplyFilter={() => handleApplyFilter()}
              frequency={currentFrequency}
              setSearch={setSearch}
              handleInputChange={handleInputChange}
              setSelectedPageOption={setSelectedPageOption}
              itemsPerPage={perPage}
              totalItems={totalItems}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              visibleColumns={visibleColumns}
              setVisibleColumns={setVisibleColumns}
              componentName={componentName}
              downloadStatus={downloadGeneralTransactionReportStatus}
              generalTransactionFilterOptions={generalTransactionFilterOptions}
              setStartDate={setStartDate}
              startDate={startDate}
              setEndDate={setEndDate}
              endDate={endDate}
            />
          </div>
        )}
        {componentName === 'settlementreport' && (
          <div className="">
            <SettlementReport
              onSubmitSort={() => handleSubmitFilter()}
              filterOptions={filterOptions}
              setFilterOptions={setFilterOptions}
              column={settlementReportColumn}
              tableData={settlementReport}
              onExport={handleExportFile}
              manageColumnOptions={manageColumnOptions}
              filterOption={filterOption}
              setFilterOption={setFilterOption}
              showOptions={showOptions}
              setShowOptions={setShowOptions}
              showManageColumn={showManageColumn}
              setShowManageColumn={setShowManageColumn}
              onApplyFilter={() => handleApplyFilter()}
              //  setSearch={setSearch}
              generalTransactionFilterOptions={generalTransactionFilterOptions}
              handleInputChange={handleInputChange}
              setSelectedPageOption={setSelectedPageOption}
              //  itemsPerPage={perPage}
              //  totalItems={totalItems}
              //  currentPage={currentPage}
              //  setCurrentPage={setCurrentPage}
              //  incomeState={allIncomeReportState}
              visibleColumns={visibleColumns}
              setVisibleColumns={setVisibleColumns}
              componentName={componentName}
              //  downloadStatus={downloadIncomeReportStatus}
            />
          </div>
        )}
      </div>
    </AppContainer>
  );
};

export default GeneralReport;
