import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-multi-date-picker';
import { MdOutlineEditCalendar, MdTimer } from 'react-icons/md';
import { formatRMDatePicker } from '../../utils';
import { title } from 'process';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import { formatRMTimePicker } from '../../utils/dateFormat';

interface IProps {
  label?: string;
  placeholder?: string;
  setValue: (event: any) => void;
  value: any;
  onOpenPickNewDate?: boolean;
}

const CustomTimePicker = ({ label, placeholder, value, setValue, onOpenPickNewDate }: IProps) => {
  const [selectedTime, setSelectedTime] = useState<any>(null);
  const timePickerRef = useRef<any>(null);

  const handleDateChange = (date: any) => {
    const new_time = formatRMTimePicker(date);
    setSelectedTime(new_time);
    setValue(new_time);
  };

  const openDatePicker = () => {
    if (timePickerRef.current) {
      timePickerRef.current.openCalendar();
    }
  };

  return (
    <div>
      <span className="tw-block tw-text-[#84919A] tw-text-[11px]">{label}</span>
      <div className="tw-border tw-text-[11.3px] tw-text-gray-400 tw-p-[9px] tw-px-7 tw-rounded-[4px] tw-flex tw-gap-5 tw-relative md:tw-px-8">
        <span className={`${selectedTime && 'tw-text-gray-600 tw-text-[12px] tw-font-normal'}`}>
          {selectedTime !== null ? selectedTime : placeholder ? placeholder : 'HH:mm'}
        </span>
        <span className="tw-text-lg tw-text-isPrimary tw-cursor-pointer" onClick={openDatePicker}>
          <MdTimer />
        </span>
        <DatePicker
          disableDayPicker
          value={value}
          format="HH:mm"
          plugins={[
            <TimePicker
              position="bottom"
              hideSeconds
              // onChange={handleDateChange}
            />,
          ]}
          onChange={handleDateChange}
          ref={timePickerRef}
          containerClassName="tw-absolute tw-bottom-2 tw-w-[1rem] tw-text-md tw-hidden -tw-mt-7"
          inputClass="tw-bg-inherit tw-outline-none tw-hidden"
          onOpenPickNewDate={onOpenPickNewDate}
          portal
        />
      </div>
    </div>
  );
};

export default CustomTimePicker;
