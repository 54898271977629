import icons from '../../utils/icons';
interface IProps {
  isChecked: boolean;
  onClick?: () => void;
  className?: string;
}

const ZojaCheckbox = ({ isChecked, onClick, className }: IProps) => {
  return (
    <div
      className={`tw-relative tw-grid tw-mt-4 tw-place-content-center tw-w-4 tw-h-4 tw-rounded-[4px] tw-border md:tw-w-5 md:tw-h-5 
        ${isChecked && 'tw-bg-[#004BFF] tw-border-0'} ${className}`}
      onClick={onClick}>
      <span className="tw-text-[10px] tw-text-white md:tw-text-[11px]">
        <icons.FaCheck />
      </span>
    </div>
  );
};

export default ZojaCheckbox;
