import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../redux/redux-hooks"
import { getSinglePaymentListRequest } from "../../redux/slice"
import { StatusLoader } from "../../atoms"
import PaymentEmptyState from "./paymentEmtyState"
import { Dictionary } from "../../types"
import { LucideChevronLeft } from "lucide-react"
import { currencyFormat } from "../../utils"

interface PaymentTableListProps {
    selectedPaymentItemId: string | number,
    setActiveTab: (event: any) => void
}

export default function PaymentTableList({ selectedPaymentItemId, setActiveTab }: PaymentTableListProps) {
    const getSinglePaymentListState = useAppSelector((state) => state.getSinglePaymentList)
    const { status: getSinglePaymentListStatus } = getSinglePaymentListState
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(getSinglePaymentListRequest({ bulkImportId: selectedPaymentItemId }))
    }, [selectedPaymentItemId])
    return (
        <>
            <section>
                {
                    getSinglePaymentListStatus === "loading" ?
                        <StatusLoader status={getSinglePaymentListStatus} width={'80'} height={'80'} wrapperHeight={'tw-h-[60vh]'} showEmptyState={false} /> :
                        getSinglePaymentListStatus === "succeeded" && getSinglePaymentListState?.data?.bulk_import?.imported_customers?.length > 0 ?
                            <div className="tw-bg-whit tw-h-[60vh] tw-pb-8 tw-overflow-auto">
                                <table className="tw-mt-6 tw-px-2 tw-table-auto tw-border-separat tw-border-spacing-y- tw-w-full">
                                    <thead>
                                        <th className="tw-whitespace-nowrap tw-border-l tw-border-t tw-border-b tw-text-center tw-p-2 tw-text-[##323348] tw-text-[.9rem] tw-font-normal">S/N</th>
                                        {/* <th className="tw-whitespace-nowrap tw-border-l tw-border-t tw-border-b tw-text-center tw-p-2 tw-text-[##323348] tw-text-[.9rem] tw-font-normal">Wallet Name</th> */}
                                        <th className="tw-whitespace-nowrap tw-border-l tw-border-t tw-border-b tw-text-center tw-p-2 tw-text-[##323348] tw-text-[.9rem] tw-font-normal">Account Name</th>
                                        <th className="tw-whitespace-nowrap tw-border-l tw-border-t tw-border-b tw-text-center tw-p-2 tw-text-[##323348] tw-text-[.9rem] tw-font-normal">Account Number</th>
                                        <th className="tw-whitespace-nowrap tw-border-l tw-border-t tw-border-b tw-text-center tw-p-2 tw-text-[##323348] tw-text-[.9rem] tw-font-normal">Amount</th>
                                        <th className="tw-whitespace-nowrap tw-border-l tw-border-t tw-border-b tw-text-center tw-p-2 tw-text-[##323348] tw-text-[.9rem] tw-font-normal tw-border-r">Description</th>
                                    </thead>
                                    <tbody>
                                        {
                                            getSinglePaymentListState?.data?.bulk_import?.imported_customers?.map((item: Dictionary, idx: number) => (
                                                <tr key={idx} className="odd:tw-bg-white even:tw-bg-gray-50 last-of-type:tw-border-b">
                                                    <td className="tw-p-2 tw-text-center tw-border-l lg:tw-text-[.9rem]">{idx + 1}</td>
                                                    {/* <td className="tw-p-2 tw-text-center tw-border-l lg:tw-text-[.9rem]">Wallet Name</td> */}
                                                    <td className="tw-p-2 tw-text-center tw-border-l lg:tw-text-[.9rem]">{item.name}</td>
                                                    <td className="tw-p-2 tw-text-center tw-border-l lg:tw-text-[.9rem]">{item.account.number}</td>
                                                    <td className="tw-p-2 tw-text-center tw-border-l lg:tw-text-[.9rem]">{`${currencyFormat(Number(item.amount))}`}</td>
                                                    <td className="tw-p-2 tw-text-center tw-border-l lg:tw-text-[.9rem] tw-border-r">{item.narration}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            :
                            <PaymentEmptyState
                                text="There is currently no data for you to view"
                            />

                }
                <span className="tw-mt-4 tw-flex tw-cursor-pointer"
                    onClick={() => setActiveTab("")}
                > <LucideChevronLeft width={15} height={15} /> <span>Go Back</span></span>
            </section>
        </>
    )
}
