import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContainer, ActivityActionModal } from '../../atoms';

import {
  colors,
  routesPath,
  images,
  spacing,
  getAdminUserSelectedRoles,
  // files
  canViewDashboard,
  canViewSupport,
  canViewKyc,
  canViewSettlement,
  canViewUsers,
  canViewTransaction,
  canViewSettings,
  handleSelectedOption,
  getCheckedTitles,
} from '../../utils';

import {
  RoleDetailsPermissionContainer,
  RoleDetailsPermissionContentOne,
  RoleDetailsPermissionContentTwo,
  RoleDetailsAccess,
  RoleDetailsUpdateContainer,
  RoleDetailsAllUsersContainer,
  RoleDetailsNameContainer,
  RoleDetailsHorizontalLine,
} from './style';
import { H2, H3 } from '../../styles';
import { createRoleRequest, createRoleReset } from '../../redux/slice';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import {
  Switch,
  Picker,
  Input,
  Button,
  ModuleCheck,
  PermissionsCheck,
  PermissionDefault,
  ModulePermissions,
} from '../../components';
import { ZojaButton, ZojaInput } from '../../components/tailwind';
import { adminModules, permissionDefault } from './data';

const { USERS } = routesPath;

function CreateRole() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [toggleAllPermissions, setToggleAllPermissions] = useState<boolean>(false);
  //   dashboard
  const [toggleDashboard, setToggleDashboard] = useState<boolean>(false);
  // support
  const [toggleSupport, setToggleSupport] = useState(false);
  const [toggleCanEditSupport, setToggleCanEditSupport] = useState<boolean>(false);

  // kyc
  const [toggleKyc, setToggleKyc] = useState<boolean>(false);
  const [toggleAllKycAccessRight, setToggleAllKycAccessRight] = useState<boolean>(false);
  const [toggleCanAcceptKyc, setToggleCanAcceptKyc] = useState<boolean>(false);
  const [toggleCanRejectKyc, setToggleCanRejectKyc] = useState<boolean>(false);
  // settlement
  const [toggleSettlement, setToggleSettlement] = useState<boolean>(false);
  const [toggleAllSettlementAccessRight, setToggleAllSettlementAccessRight] = useState<boolean>(false);
  // reconcilation
  const [toggleReconciliation, setToggleReconciliation] = useState<boolean>(false);
  const [toggleCanReconcileAccount, setToggleCanReconcileAccount] = useState<boolean>(false);
  // users
  const [toggleUsers, setToggleUsers] = useState(false);
  const [toggleCanEditUsers, setToggleCanEditUsers] = useState<boolean>(false);
  // transaction
  const [toggleTransactions, setToggleTransactions] = useState<boolean>(false);
  // settings
  const [toggleSettings, setToggleSettings] = useState<boolean>(false);
  const [toggleCanEditSettings, setToggleCanEditSettings] = useState<boolean>(false);

  const [currentAccess, setCurrentAccess] = useState<string>(canViewDashboard);
  const [module, setModule] = useState({ adminModules: adminModules });
  const [selectedUserRoles, setSelectedUserRoles] = useState<string[]>([]);
  const [, setSelectedUserType] = useState('');
  const [roleNameValue, setRoleNameValue] = useState('');
  const [roleNameError, setRoleNameError] = useState('');

  const [permissions, setPermissions] = useState({
    settings: permissionDefault.settings,
    roles: permissionDefault.roles,
    users: permissionDefault.users,
    supports: permissionDefault.supports,
    reports: permissionDefault.reports,
    transactions: permissionDefault.transactions,
    dealOffers: permissionDefault.dealOffers,
    customer: permissionDefault.customer,
    appFeedback: permissionDefault.appFeedback,
    payments: permissionDefault.payments,
    reconciliation: permissionDefault.reconciliation,
    settlements: permissionDefault.settlements,
    kyc: permissionDefault.kyc,
    dashboard: permissionDefault.dashboard,
  });

  const titleList = getCheckedTitles(adminModules);

  // redux state
  const createRoleState = useAppSelector(state => state.createRole);
  const { status: createRoleStatus } = createRoleState;

  useEffect(() => {
    const allArrResult = getAdminUserSelectedRoles(
      toggleAllPermissions,
      toggleDashboard,
      toggleSupport,
      toggleCanEditSupport,
      toggleKyc,
      toggleAllKycAccessRight,
      toggleCanAcceptKyc,
      toggleCanRejectKyc,
      toggleSettlement,
      toggleAllSettlementAccessRight,
      toggleReconciliation,
      toggleCanReconcileAccount,
      toggleUsers,
      toggleCanEditUsers,
      toggleTransactions,
      toggleSettings,
      toggleCanEditSettings,
    );
    setSelectedUserRoles(allArrResult);
  }, [
    toggleAllPermissions,
    toggleDashboard,
    toggleSupport,
    toggleCanEditSupport,
    toggleKyc,
    toggleAllKycAccessRight,
    toggleCanAcceptKyc,
    toggleCanRejectKyc,
    toggleSettlement,
    toggleAllSettlementAccessRight,
    toggleReconciliation,
    toggleCanReconcileAccount,
    toggleUsers,
    toggleCanEditUsers,
    toggleTransactions,
    toggleSettings,
    toggleCanEditSettings,
  ]);

  const handleCreateRole = () => {
    if (roleNameValue.length < 2) {
      setRoleNameError('Enter role name');
    } else {
      setRoleNameError('');
      const payload = {
        role: roleNameValue,
        permissions: selectedUserRoles,
      };

      dispatch(createRoleRequest(payload));
    }
  };

  return (
    <AppContainer goBack={() => navigate(USERS)} navTitle={`USER CONTROL USERS`} navHelper="ROLE DETAILS">
      <div className="tw-mt-7 tw-w-[85%]">
        <div className=" tw-flex tw-items-center tw-justify-between">
          <h2 className="tw-text-xl tw-font-medium">Add New Role</h2>
          <ZojaButton extraClass="tw-bg-isPrimary tw-text-white tw-p-3 tw-text-base shadow" text={'Create Role'} />
        </div>
        <div className="tw-mt-4">
          <h3 className="tw-text-base tw-font-medium ">Role Information</h3>
          <div className="tw-flex tw-items-center tw-gap-x-12">
            <div className="tw-w-[40%]">
              <ZojaInput label="Role Name" placeholder="Role Name" />
            </div>
            <div className="tw-w-[60%]">
              <ZojaInput label="Role Function" placeholder="Role Function" />
            </div>
          </div>
        </div>
        <div className="tw-mt-8 tw-mb-4">
          <h3 className="tw-text-base tw-font-medium ">Available Modules</h3>
          <p className="tw-text-xs tw-font-light">
            Select below the modules that will be visible to the users in this role
          </p>
          <ModuleCheck moduleList={adminModules} module={module} setModule={setModule} itemName="adminModules" />
        </div>
        <div>
          <h3 className="tw-text-base tw-font-medium ">Permissions</h3>
          <p className="tw-text-xs tw-font-light">
            See below the permissions attached to visible modules selected above and set default permissions{' '}
          </p>
          <div>
            <ModulePermissions
              permissionDefault={permissionDefault}
              setPermissions={setPermissions}
              permissions={permissions}
              checkedTitles={titleList}
            />
          </div>
        </div>
      </div>
    </AppContainer>
  );
}

export default CreateRole;
