import { useEffect } from 'react';
import { ZojaButton, ZojaModal } from '../../components/tailwind';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import { approvePaymentUploadRequest, approvePaymentUploadReset, reviewPaymentUploadRequest, reviewPaymentUploadReset } from '../../redux/slice';
import { icons } from '../../utils';
import ZojaConfirmPaymentSuccess from './ConfirmPaymentSuccess';


interface ConfirmPaymentProps {
    show: boolean,
    handleClose: () => void,
    contentRef?: any,
    tab?: string,
    selectedPaymentItemId: string | number
}

export default function ConfirmPayment({ show, handleClose, tab, selectedPaymentItemId }: ConfirmPaymentProps) {

    const reviewPaymentUploadState = useAppSelector((state) => state.reviewPaymentUpload)
    const {status: reviewPaymentUploadStatus } = reviewPaymentUploadState

    const approvePaymentUploadState = useAppSelector((state) => state.approvePaymentUpload)
    const {status: approvePaymentUploadStatus } = approvePaymentUploadState

    const dispatch = useAppDispatch()

    const handleApprove = () => {
        // console.log(values, tab)
     tab === 'Review Payment' &&  dispatch(reviewPaymentUploadRequest({bulkImportId: selectedPaymentItemId, data: {status: 'APPROVED'}}))
     tab === 'Approve Payment' &&  dispatch(approvePaymentUploadRequest({bulkImportId: selectedPaymentItemId, data: {status: 'APPROVED'}}))
    }

    useEffect(() => {
        if (
            reviewPaymentUploadStatus === "succeeded" 
            || reviewPaymentUploadStatus === "failed" 
            || approvePaymentUploadStatus === "succeeded"
            || approvePaymentUploadStatus === "failed"
            ) {
            handleClose()
        }
    },[reviewPaymentUploadStatus, approvePaymentUploadStatus])

    return (
        <>
            <ZojaConfirmPaymentSuccess
                title={`${tab === 'Review Payment' ? 'File Reviewed Successfully': 'Approved Successfully'}`}
                subTitle={`${tab === 'Review Payment' ? 'Payment has been sent for approval': 'Payment has been approved'}`}
                show={(reviewPaymentUploadStatus === "succeeded" && reviewPaymentUploadState?.data?.bulk_import?.review_status === 'APPROVED') || (approvePaymentUploadStatus === 'succeeded' && approvePaymentUploadState?.data?.bulk_import?.approval_status === 'APPROVED')}
                handleClose={() => {
                    dispatch(reviewPaymentUploadReset())
                    dispatch(approvePaymentUploadReset())
                }}
            />
            <ZojaModal
                show={show}
                handleClose={handleClose}
                height='auto'
                position='center'
                extraClass="md:tw-w-[32rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto tw-mt-12"
                borderRadius='8px'
            >
                <div className='tw-mx-3 tw-py-5  md:tw-mx-2 tw-p-4'>
                    <h3 className='tw-text-[.95rem] tw-text-[#263238] tw-mb-1'>{tab}</h3>
                    {/* <span className='tw-block tw-mt-5 tw-text-[.9rem] tw-text-[#263238]'>Confirm that you want to proceed with debiting <span className='tw-font-semibold'>#amount</span> from <span className='tw-font-semibold'>Boluwatife's</span> account</span> */}
                    {tab === 'Review Payment' && <span className='tw-block tw-mt-5 tw-text-[.9rem] tw-text-[#263238]'>Confirm that you want to proceed with approving this review</span>}
                    {tab === 'Approve Payment' && <span className='tw-block tw-mt-5 tw-text-[.9rem] tw-text-[#263238]'>Confirm that you want to approve this payment</span>}
                    <span className='tw-absolute tw-top-2 tw-right-2 tw-cursor-pointer'
                        onClick={handleClose}
                    ><icons.MdClose /></span>
                    <div className='my-3 mt-1 tw-flex tw-justify-end tw-gap-x-3'>
                        <ZojaButton
                            onClick={handleClose}
                            text="Cancel"
                            // loading
                            extraClass='tw-bg-transparent tw-grid tw-place-content-center tw-text-red-600 tw-font-semibold tw-text-[.75rem] tw-rounded-[4px] tw-px-5 tw-w-full tw-p-2 tw-mt-4 hover:tw-text-white hover:tw-bg-red-600'
                            type='button'
                        />
                        <ZojaButton
                            onClick={handleApprove}
                            text="Yes, Continue"
                            loading={reviewPaymentUploadStatus === 'loading' || approvePaymentUploadStatus === "loading"}
                            loadingText='Yes, Continue'
                            disabled={reviewPaymentUploadStatus === 'loading' || approvePaymentUploadStatus === "loading"}
                            extraClass='tw-bg-isPrimary tw-border tw-grid tw-place-content-center tw-text-white tw-font-thin tw-text-[.75rem] tw-rounded-[4px] tw-w-full tw-px-5 tw-p-2 tw-mt-4 hover:tw-text-white hover:tw-bg-isPrimary'
                            type='button'
                        />
                    </div>
                </div>
            </ZojaModal>
        </>
    )
}
