import { images } from "../../utils";

interface PaymentEmptyStateProps {
    text: string;
}

export default function PaymentEmptyState({ text }: PaymentEmptyStateProps) {
    return (
        <div className="tw-h-[20rem] tw-p-4 tw-mt-6 tw-rounded-md tw-bg-white tw-shadow-sm tw-flex tw-justify-center tw-items-center">
            <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
                <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
                    <img src={images.fileExcelEmpty} width={120} alt="icon" />
                    <span className="tw-block tw-py-3 tw-text-center">{text}</span>
                </div>
            </div>
        </div>
    )
}
