import React, { useEffect, useState } from 'react';
import { Dictionary } from '../../../types';
import { ZojaTable } from '../..';
import { TD, TR } from '../../tailwind/table/tableElements';
import { dateTimeFormat3 } from '../../../utils/dateFormat';
import { FaChevronRight } from 'react-icons/fa';
import { useAppDispatch } from '../../../redux/redux-hooks';
import { useNavigate } from 'react-router-dom';
import { routesPath } from '../../../utils';
const { KYCCUSTOMERINFORMATION } = routesPath;

interface IProps {
  data: Dictionary[];
  level?: string;
  tab?: string;
  sorting?: any;
  order?: string;
}

const tableColumns = [
  {
    id: 1,
    title: '#',
    hasSortIcon: false,
  },
  {
    id: 2,
    title: 'Profile Name',
    value: 'userName',
    hasSortIcon: true,
  },
  {
    id: 3,
    title: 'BVN',
    value: 'bvn',
    hasSortIcon: true,
  },
  {
    id: 4,
    title: 'Phone Number',
    value: 'phoneNo',
    hasSortIcon: true,
  },
  {
    id: 5,
    title: 'Date Approved',
    value: 'created_at',
    hasSortIcon: true,
  },
  {
    id: 6,
    title: '',
    hasSortIcon: false,
  },
];
const pendingTableColumns = [
  {
    id: 1,
    title: '#',
    hasSortIcon: false,
  },
  {
    id: 2,
    title: 'Profile Name',
    hasSortIcon: true,
  },
  {
    id: 3,
    title: 'BVN',
    hasSortIcon: true,
  },
  {
    id: 4,
    title: 'Phone Number',
    hasSortIcon: true,
  },
  {
    id: 5,
    title: 'Date Created',
    hasSortIcon: true,
  },
  {
    id: 6,
    title: '',
    hasSortIcon: false,
  },
];

const rejectedTableColumns = [
  {
    id: 1,
    title: '#',
    hasSortIcon: false,
  },
  {
    id: 2,
    title: 'Profile Name',
    value: 'userName',
    hasSortIcon: true,
  },
  {
    id: 3,
    title: 'BVN',
    value: 'bvn',
    hasSortIcon: true,
  },
  {
    id: 4,
    title: 'Phone Number',
    value: 'phoneNo',
    hasSortIcon: true,
  },
  {
    id: 5,
    title: 'Rejection Reason',
    hasSortIcon: true,
  },
  {
    id: 6,
    title: 'Date Rejected',
    hasSortIcon: false,
  },
  // {
  //     id: 7,
  //     title: '',
  // },
];

const verifiedUsers: string = 'approved';
const pendingUsers: string = 'pending';
const rejectedUsers: string = 'rejected';

const KycTable = ({ data, tab, level, sorting, order }: IProps) => {
  const [kycTableColumns, setKycTableColumns] = useState(tableColumns);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleRowClicked = (id: any) => {
    navigate(`${KYCCUSTOMERINFORMATION}${id}`, {
      state: {
        kycLvl: level,
        verificationType:
          tab === 'verified_users'
            ? verifiedUsers
            : tab === 'pending_verifications'
            ? pendingUsers
            : tab === 'rejected_verifications'
            ? rejectedUsers
            : '',
      },
    });
  };

  useEffect(() => {
    if (tab === 'rejected_verifications') {
      setKycTableColumns(rejectedTableColumns);
      // console.log(data)
    } else if (tab === 'pending_verifications') {
      setKycTableColumns(pendingTableColumns);
    } else {
      setKycTableColumns(tableColumns);
    }
  }, [tab]);

  return (
    <div>
      <ZojaTable column={kycTableColumns} sorting={sorting} order={order}>
        {data?.map((item: Dictionary, idx: number) => (
          <TR key={item.id}>
            <TD value={idx + 1} />
            <TD>{item.userName}</TD>
            <TD>{item.bvn}</TD>
            <TD>{item.phoneNo}</TD>
            <TD extraClass={`${tab === 'rejected_verifications' ? '' : 'tw-hidden'}`}>
              {
                // item.rejection_reason
                item?.verifications
                  ?.filter((f: Dictionary) => f.status === 'rejected')
                  .map((data: Dictionary) => (
                    <div key={data.id} className="tw-flex tw-gap-2">
                      <span>{data.verification_type}: </span>
                      <span>{data.comment} </span>
                    </div>
                  ))
              }
            </TD>
            <TD value={dateTimeFormat3(item.created_at)} extraClass="tw-font-thin" />
            <TD
              extraClass={`${tab !== 'rejected_verifications' ? '' : 'tw-hidden'}`}
              onClick={() => {
                tab !== 'rejected_verifications' && handleRowClicked(item.detailsId);
              }}>
              <span className="tw-text-xs">
                {' '}
                <FaChevronRight />
              </span>
            </TD>
          </TR>
        ))}
      </ZojaTable>
    </div>
  );
};

export default KycTable;
