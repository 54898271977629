import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { BsFunnel } from "react-icons/bs";
import { CustomDatePicker, CustomTimePicker, ExportLabel, FilterCard, Pagination, StatusLabel, ZojaTable } from "../..";
import { useAppDispatch, useAppSelector } from "../../../redux/redux-hooks";
import { downloadCustomerAccountStatementRequest, getCustomerAccountStatement } from "../../../redux/slice";
import { Dictionary } from "../../../types";
import { currencyFormat } from "../../../utils";
import { ZojaButton, ZojaSelect } from "../../tailwind";
import { TD, TR } from "../../tailwind/table/tableElements";

interface CustomerTransactionsProps {
    show: boolean,
}

const tableColumns = [
    {
        id: 1,
        title: '#',
    },
    {
        id: 2,
        title: 'Date & Time',
    },
    {
        id: 3,
        title: 'Description',
    },
    {
        id: 4,
        title: 'Debit',
    },
    {
        id: 5,
        title: 'Credit',
    },
    {
        id: 6,
        title: 'Balance',
    },
    {
        id: 7,
        title: 'Status',
    }
]

export default function CustomerTransactions({ show }: CustomerTransactionsProps) {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(5);
    const [showExportOptions, setShowExportOptions] = useState(false);
    const [showFilterOptions, setShowFilterOptions] = useState(false);
    const [dateRange, setDateRange] = useState({ state_date: '', end_date: '' });
    const [payload, setPayload] = useState({
        page: 1,
        start_date: '',
        end_date: '',
        time: '',
        per_page: 10,
    })
    let timeRef = useRef(null)

    const customerAccountStatement = useAppSelector((state) => state.customerAccountStatement)
    const downloadCustomerAccountStatement = useAppSelector((state) => state.downloadCustomerAccountStatement)

    const dispatch = useAppDispatch()

    // handle download kyc records
    const handleExportFile = (type: string) => {
        let filters = customerAccountStatement.filters
        dispatch(downloadCustomerAccountStatementRequest({
            ...filters,
            export: type
        }))
        setShowExportOptions(false);
    };

    // handle page number change
    const handlePageNumberChange = (pageNumber: any) => {
        let filters = customerAccountStatement.filters
        dispatch(
            getCustomerAccountStatement({
                ...payload, per_page: pageNumber,
                ...filters,
                page: customerAccountStatement?.data?.transactions?.current_page,
            }),
        );
     }

    // filter table handler
    const handleSubmitFilter = () => {
        dispatch(
            getCustomerAccountStatement({
                ...payload, per_page: payload?.per_page,
                page: currentPage,
                account_number: customerAccountStatement?.data?.account_info?.account_number,
                start_date: dateRange.state_date,
                end_date: dateRange.end_date,
                time: timeRef.current || ''
            }),
        );
    };

    useEffect(() => {
        if (customerAccountStatement.data?.transactions?.data?.length > 0) {
            const {
                last_page,
            } = customerAccountStatement?.data?.transactions;
            setTotalPages(last_page);
            setPayload({ ...payload, page: currentPage });
        }
    }, [customerAccountStatement?.data, currentPage]);
    return (
        <>
            {(showExportOptions || showFilterOptions) && (
                <div
                    className="tw-absolute tw-bg-black/0 tw-cursor-pointer tw-z-30 tw-w-full tw-h-screen
           tw-top-0 tw-left-0"
                    onClick={() => {
                        setShowExportOptions(false);
                        setShowFilterOptions(false)
                    }}></div>
            )}
            <div className={`
            tw-mt- tw-borde tw-rounded-m tw-bg-white tw-p-6 tw-shadow-zojaShadowNine
            ${!show && 'tw-hidden'}`}>
                <div className="tw-flex tw-flex-col tw-justify-between lg:tw-items-center lg:tw-flex-row">
                    <h6 className="tw-font-normal tw-uppercase tw-text-sm">Transactions</h6>
                    <div className="tw-flex tw-justify-between tw-items-center tw-gap-x-3">
                        <div className="tw-flex tw-flex-col lg:tw-gap-4 lg:tw-flex-row">
                            <div className="tw-flex tw-gap-3">
                                <div className="tw-mb-2">
                                    <CustomDatePicker
                                        label="State Date"
                                        onOpenPickNewDate={false}
                                        value={dateRange.state_date}
                                        setValue={value => setDateRange({ ...dateRange, state_date: value })}
                                    />
                                </div>
                                <div className="tw-mb-4">
                                    <CustomDatePicker
                                        label="End Date"
                                        onOpenPickNewDate={false}
                                        value={dateRange.end_date}
                                        setValue={value => setDateRange({ ...dateRange, end_date: value })}
                                    />
                                </div>
                            </div>
                            <div className="tw-flex tw-gap-1">
                                <div className="tw-mb-4">
                                    <CustomTimePicker
                                        label="Time"
                                        onOpenPickNewDate={true}
                                        value={new Date()}
                                        setValue={value => timeRef.current = value}
                                    />
                                </div>
                                <ZojaButton
                                    text={<BsFunnel />}
                                    extraClass={
                                        `tw-bg-isPrimary tw-text-gray-200 tw-border tw-p-2 tw-h-fit tw-rounded-sm tw-cursor-pointer tw-mt-[1.1rem]
                                        `
                                    }
                                    onClick={handleSubmitFilter}
                                // loading
                                />
                                <div className="tw-mt-4">
                                    <ExportLabel
                                        title=""
                                        onClick={() => setShowExportOptions(true)}
                                        loading={downloadCustomerAccountStatement.status === 'loading'}
                                    >
                                        <FilterCard
                                            showFilter={showExportOptions}
                                            title="Export as"
                                            onClick={() => null}
                                            showSubmitButton={false}
                                            extraClass="tw-w-[10rem] -tw-left-28">
                                            <div className="tw-flex tw-flex-col tw-mt-4">
                                                {/* <span
                                                className="tw-text-xs tw-cursor-pointer tw-text-isGrey tw-border-b tw-border-b-[#f4f3f3] tw-pb-2 tw-mb-2"
                                                onClick={() => handleExportFile('pdf')}>
                                                PDF
                                            </span> */}
                                                <span
                                                    className="tw-text-xs tw-cursor-pointer tw-text-isGrey"
                                                    onClick={() => handleExportFile('csv')}>
                                                    CSV
                                                </span>
                                            </div>
                                        </FilterCard>
                                    </ExportLabel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <ZojaTable
                        column={tableColumns}
                        thClass="tw-text-gray-600 tw-font-semibold"
                    >
                        {/* {data?.map((item: Dictionary, idx: number) => ( */}
                        {
                            customerAccountStatement?.data?.transactions?.data.map((data: Dictionary, idx: number) => (
                                <TR key={2} onClick={() => null}>
                                    <TD value={idx + 1} extraClass="tw-text-gray-500" />
                                    <TD value={moment(data.data_time).format('D-MM-YYYY hh:mm a')} extraClass="tw-text-gray-500 tw-lowercase" />
                                    <TD value={data.description} extraClass="tw-text-gray-500" />
                                    <TD value={data.debit ? `${currencyFormat(data.debit).replace('-', '')}` : '-'} extraClass={`tw-text-center ${data.debit === '' ? 'tw-text-gray-500' : 'tw-text-red-500'}`} />
                                    <TD value={data.credit ? `${currencyFormat(data.credit)}` : '-'} extraClass={`tw-text-center ${data.credit === '' ? 'tw-text-gray-500' : 'tw-text-green-500'}`} />
                                    <TD value={currencyFormat(data.balance)} extraClass="tw-text-isPrimary" />
                                    <TD value={<StatusLabel status={data.status} title={data.status} />} />
                                </TR>
                            ))
                        }
                        {/* ))} */}
                    </ZojaTable>
                </div>
                <div className="tw-pb-2 tw-flex tw-flex-col tw-items-center tw-gap-3 md:tw-pb-8 md:tw-flex-row">
                    {customerAccountStatement.data.transactions?.data?.length > 0 && (
                        <Pagination
                            isLoading={customerAccountStatement.status === 'loading' ? true : false}
                            currentPage={customerAccountStatement?.data?.transactions?.current_page}
                            totalPages={totalPages}
                            onPageChange={selectedPage => {
                                setCurrentPage(selectedPage);
                                let currentPage = selectedPage;
                                let filterBy = {
                                    ...payload, 
                                    per_page: customerAccountStatement?.data?.transactions?.per_page,
                                    page: currentPage,
                                    account_number: customerAccountStatement?.data?.account_info?.account_number
                                };
                                dispatch(getCustomerAccountStatement(filterBy));
                            }}
                        />
                    )}
                    <div className="-tw-mt-12 tw-flex tw-items-center tw-h-fit">
                        <span className="tw-text-xs tw-w-16">Per page</span>
                        <ZojaSelect
                            options={[
                                { label: '10', value: '10' },
                                { label: '20', value: '20' },
                                { label: '30', value: '30' },
                            ]}
                            value={customerAccountStatement?.data?.transactions?.per_page}
                            setSelectedOption={(selected) => handlePageNumberChange(selected)}
                            extraClass="tw-rounded-none tw-text-xs tw-py-2 tw-w-10"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
