
interface PaymentContainerProps {
    title: string;
    children: React.ReactNode
}

export default function PaymentContainer({title, children}: PaymentContainerProps) {
    return (
        <div>
            <span className="tw-text-xs -tw-leading-4 tw-text-[#7E7E96]">{title}</span>

            {children}
            
        </div>
    )
}
