import { memo } from 'react';

import { HB, H1, H4 } from '../../../styles';
import { colors } from '../../../utils';
import { Container } from './style';
import Skeleton from 'react-loading-skeleton';

export interface CountInfoCardIProps {
  id?: number;
  isSelected?: boolean;
  count: number | string;
  title: string;
  helper?: string;
  color?: string;
  background?: string;
  shadow?: string;
  tag?: string;
  loading?: boolean;
}

interface IProps extends CountInfoCardIProps {
  onClick?: () => void;
}
function CountInfoCard({
  isSelected,
  count,
  title,
  color,
  helper,
  background = colors.white,
  shadow,
  tag,
  loading,
  onClick,
}: IProps) {
  return (
    <Container background={background} isSelected={isSelected} onClick={onClick} shadow={shadow}>
      {loading ? (
        <Skeleton width={100} borderRadius={0} />
      ) : (
        <H4 left color={colors.grey}>
          {title}
        </H4>
      )}

      {loading ? (
        <Skeleton width={40} borderRadius={0} />
      ) : (
        <HB style={{ fontFamily: 'Poppins', fontWeight: '700' }} left bold color={color ? color : colors.primary}>
          {count}
        </HB>
      )}

      {loading ? (
        <Skeleton width={100} borderRadius={0} />
      ) : (
        <H4 left color={colors.grey}>
          {helper ? helper : 'Total Records'}
        </H4>
      )}
    </Container>
  );
}

export default memo(CountInfoCard);
