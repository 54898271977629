import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { Oval } from "react-loader-spinner";
import * as yup from "yup";
import { AppContainer, CustomerAccountInformation } from "../../atoms";
import { CustomerTransactionsTable, LabelTwo } from "../../components";
import { ZojaButton, ZojaInput } from "../../components/tailwind";
import { useAppDispatch, useAppSelector } from "../../redux/redux-hooks";
import { customerAccountStatementReset, getCustomerAccountStatement } from "../../redux/slice";
import { images } from "../../utils";

const TAB_DATA = ['Account Information', 'Transactions']

interface inititialValues {
    account_number: string;
}

export default function AccountTransaction() {
    const [activeTab, setActiveTab] = useState("Account Information")
    const [payload, setPayload] = useState({
        page: 1,
        start_date: '',
        end_date: '',
        time: '',
        per_page: 10,
    })

    const customerAccountStatement = useAppSelector((state) => state.customerAccountStatement)
    console.log(customerAccountStatement)

    const handleSetActiveTab = (tab: string) => setActiveTab(tab)

    const dispatch = useAppDispatch()

    const handleSearchAccount = (values: inititialValues) => {
        const data = { ...values, ...payload }
        dispatch(getCustomerAccountStatement(data))
    }

    const { values, errors, handleChange, handleSubmit, touched, resetForm } = useFormik(({
        initialValues: { account_number: '' },
        validationSchema: yup.object().shape({
            account_number: yup.string().required('account number is required')
        }),
        onSubmit: handleSearchAccount
    }))

    useEffect(() => {
        dispatch(customerAccountStatementReset())
    }, [])

    return (
        <AppContainer
            navTitle={
                <span className="tw-ml-3 tw-text-[#5E6366] tw-text-xs tw-font-medium md:tw-text-[1.2rem]">Account Management</span>
            }>
            <div className="tw-p-2 tw-mb-28 tw-mt-4 md:tw-mt-7">
                <div className="tw-bg-white tw-p-6 tw-shadow-zojaShadowNine tw-rounded-md md:tw-p-8 lg:tw-w-3/5">
                    <form onSubmit={handleSubmit}>
                        <div className="tw-flex tw-flex-col tw-gap-3 md:tw-flex-row">
                            <div className="tw-w-full">
                                <ZojaInput
                                    value={values.account_number}
                                    onChange={handleChange}
                                    name="account_number"
                                    touched={touched}
                                    error={errors.account_number}
                                    placeholder="Enter account number"
                                    extraClass={`tw-border-gray-300 tw-p-2 placeholder:tw-text-sm focus:tw-outline-none focus:tw-border-isPrimary`}
                                    icon={<FaSearch />}
                                />
                            </div>
                            <ZojaButton
                                text="Search"
                                extraClass={`tw-p-3 tw-px-8 tw-font-normal tw-bg-isPrimary tw-text-white tw-rounded-sm tw-text-xs ${customerAccountStatement.status === 'loading' && 'tw-h-10'
                                    }`}
                                type="submit"
                                disabled={customerAccountStatement.status === "loading"}
                            />
                        </div>
                    </form>
                </div>

                {
                    customerAccountStatement.status === 'loading' ? (
                        (<div className="tw-mt-5 tw-bg-white tw-h-[calc(100vh_-_15rem)] tw-flex tw-justify-center">
                            <div className="tw-h-[calc(100vh_-_15rem)] tw-flex tw-justify-center tw-items-center">
                                <Oval
                                    height="80"
                                    width="80"
                                    color="#222b88cf"
                                    ariaLabel="tail-spin-loading"
                                    secondaryColor="#222b882b"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                            </div>
                        </div>)
                    ) : Object.keys(customerAccountStatement.data)?.length ? (
                        <>
                            <div className="tw-my-4 tw-p-4 tw-shadow-zojaShadowNine tw-bg-[#f1f1f1]">
                                {
                                    TAB_DATA.map((item, index) => (
                                        <LabelTwo key={index} title={item} active={activeTab === item} handleClick={() => handleSetActiveTab(item)} />
                                    ))
                                }
                                {/* <LabelTwo title="Transactions"/> */}
                            </div>
                            <CustomerAccountInformation
                                show={activeTab === TAB_DATA[0]}
                                data={customerAccountStatement.data.account_info}
                            />
                            <CustomerTransactionsTable
                                show={activeTab === TAB_DATA[1]}
                            />
                        </>
                    ) : (<div className="tw-mt-5 tw-bg-white tw-h-[calc(100vh_-_15rem)] tw-flex tw-justify-center">
                        <img
                            src={images.emptyState}
                            alt="empty-icon"
                            className=""
                        />
                    </div>)
                }
            </div>
        </AppContainer>
    )
}
