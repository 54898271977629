import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Container, Content, BtnContainer } from './style';
import { Modal, Button, Input, Picker, PermissionDefault } from '../../components';
import { H5 } from '../../styles';
import { colors, getCheckedTitles } from '../../utils';
import { Dictionary } from '../../types';
import { useAppSelector } from '../../redux/redux-hooks';
import { ZojaSelect } from '../../components/tailwind';

interface RoleOptionsIProps {
  value: string;
  label: string;
}

interface DefaultValuesIProps {
  defaultEmail: string;
  defaultFirstName: string;
  defaultLastName: string;
  defaultRole: string;
  defaultPermissions?: any[];
}

export interface IProps {
  isModalVisible: boolean;
  editing: boolean;
  closeModal: () => void;
  title: string;
  isLoading: boolean;
  onSubmit: (item: Dictionary) => any;
  isSubmittingInternalUser: boolean;
  isSuccessInternaluser: any;
  roleOption: RoleOptionsIProps[];
  rolesAndPermissions?: Dictionary[];
  actionBtnText: string;
  defaultValues: DefaultValuesIProps;
  permissionDefault?: any;
  setOpenPermissions?: any;
  setUserRole?: any;
  setPermissions?: any;
  setCurrentUserData?: any;
  openPermissions?: boolean;
  permissions?: any;
}

function CreateInternalUserModal({
  isModalVisible,
  editing,
  closeModal,
  title,
  isLoading,
  onSubmit,
  permissionDefault,
  isSubmittingInternalUser,
  roleOption,
  actionBtnText,
  defaultValues,
  setOpenPermissions,
  openPermissions,
  setUserRole,
  permissions,
  setPermissions,
  setCurrentUserData,
  rolesAndPermissions,
  isSuccessInternaluser,
}: IProps) {
  const { defaultEmail, defaultFirstName, defaultLastName, defaultRole, defaultPermissions } = defaultValues;
  const [selectedRole, setSelectedRole] = useState(defaultRole);
  const [currentPermissions, setCurrentPermissions] = useState<any>({});

  useEffect(() => {
    setUserRole(selectedRole);
  }, [selectedRole, setUserRole]);

  useEffect(() => {
    if (rolesAndPermissions?.length !== 0 && defaultRole && editing && selectedRole) {
      // let filterBy = '';
      // filterBy = selectedRole.toLowerCase() === defaultRole.toLowerCase() ? selectedRole : defaultRole;
      const filteredPermissions = rolesAndPermissions?.filter(
        f => f.name.toLowerCase() === selectedRole.toLowerCase(),
      )[0]?.permissions;

      let data = filteredPermissions?.map((d: any) => {
        return {
          ...d,
          title: d.name,
          value: d.name,
          isChecked: false,
        };
      });

      // Create a Set of ids from the default permission
      const idsSet = new Set(defaultPermissions?.map((obj: Dictionary) => obj.id));

      // Iterate over each object in the first Roles and Permission
      const result = data.map((obj: Dictionary) => {
        // If the id exists in the idsSet, set isChecked to true
        if (idsSet.has(obj.id)) {
          return { ...obj, isChecked: true };
        }
        return obj; // Otherwise, return the object as it is
      });

      // let newData = data?.map((d: any, idx: number) => {
      //   if (d.id === loginState?.data?.user?.permissions?.[idx]?.id) {
      //     return {
      //       ...d,
      //       isChecked: true,
      //     };
      //   }
      //   return d;
      // });
      // console.log(result);

      setCurrentPermissions({ [selectedRole.toLowerCase()]: [...result] });
    }
  }, [defaultRole, rolesAndPermissions, selectedRole, editing]);

  useEffect(() => {
    if (editing) {
      setSelectedRole(defaultRole);
    }
  }, [editing]);

  const schema = yup.object().shape({
    email: yup.string().email().required('Email is required'),
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    role: selectedRole.length < 2 ? yup.string().required('Role is required') : yup.string(),
  });
  return (
    <Modal title={title} isModalVisible={isModalVisible} closeModal={closeModal}>
      {isLoading ? (
        <Container>
          <H5>Please wait loading...</H5>
        </Container>
      ) : (
        <Container>
          <Content>
            <Formik
              initialValues={{
                email: defaultEmail,
                firstName: defaultFirstName,
                lastName: defaultLastName,
                role: defaultRole,
              }}
              enableReinitialize={true}
              validationSchema={schema}
              onSubmit={async (values, { setSubmitting }) => {
                const { email, firstName, lastName, role } = values ?? '';

                const payload = {
                  email: email,
                  first_name: firstName,
                  last_name: lastName,
                  role: selectedRole.length > 2 ? selectedRole : role,
                  permissions: editing
                    ? getCheckedTitles(currentPermissions?.[selectedRole?.toLowerCase()])
                    : getCheckedTitles(permissionDefault?.[selectedRole]),
                };
                onSubmit(payload);
                setSubmitting(false);
                isSuccessInternaluser === true && setOpenPermissions(false);
              }}>
              {formikProps => {
                const { handleChange, values, handleSubmit, errors } = formikProps;
                return (
                  <form onSubmit={handleSubmit}>
                    <div>
                      <Input
                        label="Email"
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={values.email}
                        onChange={handleChange}
                        error={errors.email}
                        backgroundColor={colors.white}
                        normalEmail={true}
                      />
                      <Input
                        label="First Name"
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        value={values.firstName}
                        onChange={handleChange}
                        error={errors.firstName}
                        backgroundColor={colors.white}
                      />
                      <Input
                        label="Last Name"
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        value={values.lastName}
                        onChange={handleChange}
                        error={errors.lastName}
                        backgroundColor={colors.white}
                      />
                      {/* <Picker
                        error={errors.role}
                        label="Role"
                        selectedValue={setSelectedRole}
                        placeholder={defaultRole.length > 2 ? defaultRole : 'Select Agent'}
                        options={roleOption}
                        width={'100%'}
                        setOpenPermissions={setOpenPermissions}
                      /> */}

                      <ZojaSelect
                        label="Role"
                        options={roleOption}
                        setSelectedOption={selected => {
                          setSelectedRole(selected);
                          setOpenPermissions(true);
                        }}
                        value={selectedRole}
                      />

                      {openPermissions && !editing && selectedRole && (
                        <div>
                          <PermissionDefault
                            permissions={permissions}
                            setPermissions={setPermissions}
                            selectedRole={selectedRole}
                          />
                        </div>
                      )}
                      {editing && (
                        <div>
                          <PermissionDefault
                            permissions={currentPermissions}
                            setPermissions={setCurrentPermissions}
                            selectedRole={selectedRole.toLowerCase()}
                          />
                        </div>
                      )}
                      <BtnContainer>
                        <Button type="submit" text={actionBtnText} disabled={isSubmittingInternalUser} />
                        <Button
                          secondary
                          color={colors.primary}
                          borderColor={colors.white}
                          onClick={closeModal}
                          text="Cancel"
                          disabled={false}
                        />
                      </BtnContainer>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </Content>
        </Container>
      )}
    </Modal>
  );
}

export default CreateInternalUserModal;
