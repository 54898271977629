
interface IProps {
    title: string,
    titleClass?: string,
    active?: boolean,
    handleClick?: () => void
}

const LabelTwo = ({ title, titleClass, active= false, handleClick }: IProps) => {

    return (
        <span className={`
        tw-inline-block tw-text-xs tw-border tw-border-gray-300 tw-p-2 tw-px-4 tw-uppercase tw-mr-2 tw-bg-white tw-cursor-pointer tw-tracking-wider tw-transition-all tw-ease-in-out tw-duration-500 hover:tw-bg-isPrimary
        ${active && 'tw-bg-[#162082] tw-text-white'}
        `}
        style={{background: active ? '#162082' : '#ffffff'}}
            onClick={handleClick}
        >{title}</span>
    )
}

export default LabelTwo