type STATUS_TYPE = 'success' | 'pending' | 'failed'

interface StatusLabelProps {
    status: STATUS_TYPE,
    title: string
}


export default function StatusLabel({ status, title }: StatusLabelProps) {
    return (
        <span className={`
            tw-text-[.65rem] tw-p-1.5 tw-px-6 tw-rounded-sm
            ${status === 'success' && 'tw-bg-green-200 tw-text-green-800'}
            ${status === 'pending' && 'tw-bg-blue-100 tw-text-blue-800'}
            ${status === 'failed' && 'tw-bg-red-100 tw-text-red-800'}
        `}
        >{title}</span>
    )
}
